import { useEffect, useState } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useMoveCom = (
  checked,
  remarks,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl,
  selectedSegment
) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectedCardTypes = Object.keys(checked).filter((key) => checked[key]);
  console.log(selectedCardTypes);
  //Redux

  const allQAList = useSelector((state) => state.qa.qaList);
  const currentMoveToId = useSelector((state) => state.qa.moveToId);
  const Axios = useAxiosPrivate();
  const comMove = async (type) => {
    let available_cards = [];
    if (Array.isArray(selectedCardTypes)) {
      // If selectedCardTypes is already an array, assign it to available_cards
      available_cards = selectedCardTypes;
    } else if (selectedCardTypes !== undefined && selectedCardTypes !== null) {
      // If selectedCardTypes is not an array, convert it to an array
      available_cards = [selectedCardTypes];
    }

    let param = {
      available_cards,
      f_cards_available: available_cards,
      url_id: allQAList?.[0]?.url_id,
      remarks: remarks,
      task_id: allQAList?.[0]?.task_id,
      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
      segment: selectedSegment,
    };
    console.log("param", param);

    const response = await Axios.post("/move-to-com", param);
    const data = await response.data;
    if (data.code === 200) {
      setIsLoading(false);
      toast.success("Moved Successfully", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return { comMove, isLoading, setIsLoading };
};

export default useMoveCom;
