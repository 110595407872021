import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getfNWList } from "../slice/QASlice";

const useFNWList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const allfNWList = useSelector((state) => state.qa.fNWList);
  const getAllFNWList = async () => {
    let param = {
      task_id: current_task_id,
      url_id: allfNWList?.length === 0 ? null : allfNWList?.[0]?.url_id,
    };
    const response = await Axios.post("/get-fNW-list", param);
    const data = await response.data;
    dispatch(getfNWList(data.fNWDetailsList));
  };
  useEffect(() => {
    getAllFNWList();
  }, [current_task_id]);
  return getAllFNWList;
};

export default useFNWList;
