import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterSelectedRemark,
  getSearchUrlId,
  getSelectedCardData,
  setshowQaData,
} from "../../../slice/QASlice";
import { showQaData } from "../../../slice/QASlice";
import useQAAssignedUpdate from "../../../hooks/useQAAssignedUpdate";
import useQAAccessStatusList from "../../../hooks/useQAAccessStatusList";
import useQAList from "../../../hooks/useQAList";
import Timer from "../../Timer";
import useSearchList from "../../../hooks/useSearchList";
import useFilterRemarksList from "../../../hooks/useFilterRemarksList";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useSplitUrl from "../../../hooks/useSplitUrl";

const QAL2Header = ({ timeLeft, queryURL, setQueryURL }) => {
  const dispatch = useDispatch();
  const showData = useSelector(showQaData);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [totalUrlQA, setTotalUrlQA] = useState(0);
  const currentStatus = useSelector((state) => state.qa.showQaData);

  //Redux
  const qaAccessStatusList = useSelector(
    (state) => state?.status?.qaAccessStatusList
  );
  const selectedStatusId = useSelector((state) => state?.qa.selectedCardData);
  const allQAList = useSelector((state) => state.qa.qaList);
  const filterCount = useSelector((state) => state.qa.filterCount);
  const current_task_id = localStorage.getItem("task_id");
  //Api calls
  const qaAssignedUpdate = useQAAssignedUpdate();
  useQAAccessStatusList();
  const getAllQAList = useQAList(selectedStatusId);

  // useEffect(() => {
  //   getAllQAList();
  // }, [selectedCardData?.status_id]);

  const handleCardClick = (index, statusId, statusCount) => {
    console.log(statusId, "statusIdkfajfjdsaf");
    setSelectedCardIndex(index);

    let statusName = "";
    for (let i = 0; i < qaAccessStatusList?.length; i++) {
      if (statusId === qaAccessStatusList[i].status_id) {
        statusName = qaAccessStatusList[i]?.status_name;
      }
    }
    setSelectedCardData((prev) => ({
      ...prev,
      status_id: statusId,
      status_name: statusName,
      task_id: current_task_id,
    }));

    dispatch(getSelectedCardData(statusId));
  };

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < qaAccessStatusList?.length; i++) {
      count += parseInt(qaAccessStatusList?.[i]?.status_count);
    }
    setTotalUrlQA(count);
  }, [qaAccessStatusList]);

  const getAllSearchList = useSearchList();
  const handleInput = (e) => {
    console.log(e.target.value,"handleInput")
    setQueryURL(e.target.value);
  };
  const handleSearch = () => {
    getAllSearchList(queryURL);
    dispatch(getSearchUrlId(parseInt(queryURL)));
  };
  useFilterRemarksList();
  const remarksList = useSelector((state) => state.qa.filterRemarksList);

  const handleRemarksDropdownChange = (e) => {
    console.log(e.target.value);

    dispatch(getFilterSelectedRemark(e.target.value));
    getAllQAList(e.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const getSplitUrl=useSplitUrl()
  const handleSplitUrl=async()=>{
    setOpen(true);
  }

  const handleConfirmSplitUrl = async() => {
    await getSplitUrl(queryURL)
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // const showData = useSelector(showQaData);
  return (
    <Container
      maxWidth="2xl"
      sx={{ marginTop: "0.8rem", paddingBottom: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", lg: "center" },
            alignItems: "center",
            gap: "1rem", // Adjust the gap as needed
          }}
        >
          <Stack
            direction={{ xs: "row", sm: "column" }}
            sx={{
              justifyContent: { xs: "flex-start", sm: "center" },
              alignItems: { xs: "center", sm: "flex-start" },
              gap: { xs: "0.5rem", sm: "0rem" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                color: "#000",
                fontSize: "1.5rem",
                justifyContent: "center",
              }}
            >
              F L2 QA
            </Typography>
            <Typography
              sx={{
                color: "#6B6A6A",
                fontSize: "1rem",
                fontWeight: "400",
                justifyContent: "center",
              }}
            >
              Total URLs in QA: {totalUrlQA}
            </Typography>
          </Stack>
          {currentStatus === true && allQAList?.length > 0 && (
            <Timer timeLeft={timeLeft} />
          )}
          <input
            // type="number"
            style={{ height: "2rem" }}
            onChange={handleInput}
            value={queryURL}
            placeholder="S.No."
          />
          <button
            onClick={handleSearch}
            style={{ width: "5rem", height: "2rem", cursor: "pointer" }}
          >
            Search
          </button>
        </Box>

        <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "0.5rem", sm: "0.5rem", md: "1.5rem" },
            alignItems: { xs: "flex-start", sm: "flex-start", lg: "center" },
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              lg: "center",
            },
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Button
                sx={{
                  background: "#4287f5",
                  color: "white",
                  padding: "0.5rem",
                  "&:hover": { background: "#4287f5" },
                }}
                onClick={handleSplitUrl}
              >
                Split this Url
              </Button>
              <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Split URL</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    This will create a new URL in S1 bucket, If you want to
                    split this URL Click confirm button.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirmSplitUrl} color="primary">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>

              <select
                id="remarks"
                name="remarks"
                style={{
                  height: "2.5rem",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onChange={handleRemarksDropdownChange}
                // value={selectedSegment}
              >
                <option disabled selected id="select">
                  Select Segment
                </option>
                {remarksList?.map((remark) => (
                  <option id={remark?.remark_id} value={remark?.remark_name}>
                    {remark?.remark_name}
                  </option>
                ))}
              </select>
              <Typography
                sx={{
                  background: "red",
                  borderRadius: "50%",
                  padding: "0.5rem",
                  color: "white",
                }}
              >
                {filterCount}
              </Typography>
            </Stack>

            <Stack sx={{ flexDirection: "row", gap: "0.6rem" }}>
              {qaAccessStatusList.map((qaStatus, index) => {
                return (
                  qaStatus.qa_status_result == "Found" && (
                    <Stack
                      sx={{
                        border: "0.013rem solid #006FCF",
                        background: "#F0F6FC",
                        width: "6rem",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.3rem",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCardClick(
                          index,
                          qaStatus?.status_id,
                          qaStatus?.status_count
                        )
                      }
                    >
                      <Typography
                        sx={{
                          color:
                            selectedCardIndex === index ? "#006FCF" : "#A2A2A2",
                          fontSize: "0.8rem",
                          fontWeight: "700",
                        }}
                      >
                        {qaStatus?.status_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          fontWeight: "700",
                        }}
                      >
                        {qaStatus?.status_count}
                      </Typography>
                      {selectedCardIndex === index && (
                        <CheckCircleIcon
                          fontSize="small"
                          sx={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            borderRadius: "50%",
                            color: "#329519",
                          }}
                        />
                      )}
                    </Stack>
                  )
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default QAL2Header;
