import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDuplicateDetails, getQAList } from "../slice/QASlice";

const useDuplicateDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const allDuplicateList = useSelector((state) => state.qa.duplicateDetails);
  
  const getAllDuplicateDetails = async (dId) => {
    let param = {
      task_id: current_task_id,
      url_id:
        allDuplicateList?.length === 0 ? null : allDuplicateList?.[0]?.url_id,
      d_id: dId,
    };

    console.log(param, "duplicateStatusList");
    const response = await Axios.post("/duplicate-status-list", param);
    const data = await response.data;
    dispatch(getDuplicateDetails(data.duplicateStatusList));
  };
  // useEffect(() => {
  //   getAllDuplicateDetails();
  // }, [current_task_id,allDuplicateList?.[0]?.url_id,dId]);
  return getAllDuplicateDetails;
};

export default useDuplicateDetails;
