import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useQAAssignedUpdate = () => {
  //Redux
  const allQAList = useSelector((state) => state.qa.qaList);
  console.log(allQAList[0]?.card_available);
  const Axios = useAxiosPrivate();
  const qaAssignedUpdate = async () => {
    let param = { 
      url_id: allQAList[0]?.url_id,
      task_id:allQAList[0]?.task_id,
    };
    const response = await Axios.post("/url-status-update", param);
    const data = await response.data;
    // if (data.code === 200) {
    //   toast.success(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    //   toast.error(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  return qaAssignedUpdate;
};

export default useQAAssignedUpdate;
