import React, { useState, useEffect } from "react";
import TimerIcon from "@mui/icons-material/Timer";
import { Stack } from "@mui/system";

const Timer = ({ timeLeft }) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const timerStyle = {
    fontSize: "2em",
    fontWeight: "bold",
    color: timeLeft < 30 ? "red" : "black",
    animation:
      timeLeft < 30 ? "blink-animation 1s steps(5, start) infinite" : "none",
  };

  return (
    <div>
      <style>
        {`
          @keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
        `}
      </style>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "Center",
          gap: "0.8rem",
        }}
      >
        <TimerIcon />
        <div style={timerStyle}>{formatTime(timeLeft)}</div>
      </Stack>
    </div>
  );
};

export default Timer;
