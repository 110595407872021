import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getL2S1List, getQAList } from "../slice/QASlice";

const useL2S1List = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const allL2S1List = useSelector((state) => state.qa.l2S1List);
  const getAllL2S1List = async () => {
    let param = {
      task_id: current_task_id,
      url_id: allL2S1List?.length === 0 ? null : allL2S1List?.[0]?.url_id,
    };
    const response = await Axios.post("/get-l2S1-list", param);
    const data = await response.data;
    dispatch(getL2S1List(data.l2S1DetailsList));
  };
  useEffect(() => {
    getAllL2S1List();
  }, [current_task_id]);
  return getAllL2S1List;
};

export default useL2S1List;
