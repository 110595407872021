import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import tableData from "./tableData.json";
import PopChart from "../HomePage/PopChart";
import useUrlPopResultStages from "../../hooks/useUrlPopResultStages";
import useReleaseTaskStatus from "../../hooks/useReleaseTaskStatus"
import useUrlCardPercentage from "../../hooks/useUrlCardPercentage";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTaskDetailsList from "../../hooks/useTaskDetailsList";
const { columns, dataRow } = tableData;

const GraphTable = () => {
  const [taskName, setTaskName] = useState("");

  //Redux
  const taskList = useSelector((state) => state.task.taskDetailsList);
  const popResultStages = useSelector((state) => state.task.urlPopResultStages);
  const userRole = useSelector((state) => state?.auth?.userData?.role_code);

  //Api call
  const getAllURLPopResultStages = useUrlPopResultStages();
  // const getAllURLCardPercentage = useUrlCardPercentage();

  useEffect(() => {
    setTaskName(taskList?.[0]?.task_name);
    getAllURLPopResultStages(taskList?.[0]?.task_id);
    // getAllURLCardPercentage(taskList?.[0]?.task_id);
  }, [taskList]);

  const handleChange = (e) => {
    let taskId = 0;
    for (let i = 0; i < taskList.length; i++) {
      if (taskList[i].task_name === e.target.value) {
        taskId = taskList[i].task_id;
        break;
      }
    }
    setTaskName(e.target.value);
    getAllURLPopResultStages(taskId);
    // getAllURLCardPercentage(taskId);
  };

 
  return (
    <>
    <ToastContainer/>
    <Box
      sx={{
        backgroundColor: "#fff",
        marginTop: "30px",
        marginLeft: { xs: "16px", md: "32px" },
        marginRight: { xs: "16px", md: "32px" },
        padding: "24px",
      }}
    >
     
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.8rem",
          }}
        >
          <FormControl sx={{ width: 180 }} size="small">
            <InputLabel id="demo-simple-select-label">Task</InputLabel>

            <Select
              sx={{
                ".MuiSvgIcon-root ": {
                  fill: "#006FCF !important",
                },
                borderRadius: "0",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={taskName}
              // label="Task"
              onChange={handleChange}
            >
               {taskList?.map((task) => (
              <MenuItem key={task.task_id} value={task.task_name}>
                {task?.task_name}
              </MenuItem>
               ))}
            </Select>
          </FormControl>
          <Typography
            sx={{ fontWeight: "400", fontSize: "16px", marginLeft: "24px" }}
          >
            Status:{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "8px 18px",
                backgroundColor: "#DBF6E1",
                color: "#0AB32F",
                borderRadius: "32px",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {/* {task?.task_status} */}NEW
            </span>
          </Typography>
         
        </Stack>
     
      <h3
        style={{
          marginBottom: "10px",
          fonSize: "16px",
          fontWeight: "400",
          lineHeight: "36px",
        }}
      >
        POP Results at Stages
      </h3>
      <TableContainer elevation={0} component={Paper}>
        <Table
          sx={{ minWidth: 650, shadow: "none" }}
          aria-label="responsive table"
        >
          <TableHead
            sx={{
              color: "#9D9D9D",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              position: "sticky",
              top: 0,
              backgroundColor: "#E8E8E8", // Set background color as needed
              zIndex: 1,
            }}
          >
            <TableRow sx={{ border: "1px solid #E8E8E8" }}>
              {columns.map((column) => (
                <TableCell sx={{ textAlign: "center" }} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {popResultStages?.map((column) => (
            <TableBody
              sx={{ border: "1px solid #E8E8E8" }}
              key={column.task_id}
            >
              <TableRow>
                <>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.total_urls}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.released_count}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.fcom_count}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.alt_url ?? "N/A"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.l1_count}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.l2_count}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.vpn_count}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {column?.ooh_count}
                  </TableCell>
                </>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      {/* <h3
        style={{
          marginBottom: "10px",
          fonSize: "16px",
          fontWeight: "400",
          lineHeight: "36px",
        }}
      >
        POP Results at Stages
      </h3> */}
      {/* GRAPH COMPONENT IMPORTED */}
      {/* <PopChart /> */}
    </Box>
    </>
  );
};

export default GraphTable;
