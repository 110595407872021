import React, {useState} from "react";
import { Scatter } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import data from "./graphData.json"
Chart.register(...registerables);
// Sample data for the scatter chart

const options = {
  maintainAspectRatio: false,
  aspectRatio: 1,
  scales: {
    x: {
      beginAtZero: true,
      display: false,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      type: "linear",
      position: "left",
      title: {
        display: true,
        text: "",
      },
      grid: {
        display: false,
      },
      color: '#fff', // Darker color (adjust the RGBA values as needed)
      borderWidth: 4, // Thicker line
    },
  },
  plugins: {
    legend: {
        display: false,
      },
    tooltip: {
      enabled: true,
      intersect: false, // Ensure tooltips are always visible
      position: "average",
      callbacks: {
        label: (context) => {
          const dataPoint =
            data.datasets[context.datasetIndex].data[context.dataIndex];
          return `${dataPoint.label}: (${dataPoint.y})`;
        },
      },
    },
  },
  elements: {
    point: {
      radius: 4.5, // Set the desired size for the data points
    },
  },
  interaction: {
    intersect: false, // Ensure tooltips are always visible
  },
};

const ChartAnalysis = () => {
    const [task, setTask] = useState('');
  
    const handleChange = (e) => {
      setTask(e.target.value);
    };
  
    return (
      <div style={{ width: '140px', height: '320px', borderBottom: '1px solid BLACK', marginBottom:"80px" }}>
        <Scatter data={data} options={options} />
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel>Task</InputLabel>
          <Select
            value={task}
            label="Task"
            onChange={handleChange}
            sx={{
              '.MuiSvgIcon-root ': {
                fill: "#006FCF !important",
              },
              borderRadius:"0"
            }}
          >
            <MenuItem value="Canada">Canada</MenuItem>
            <MenuItem value="China">China</MenuItem>
            <MenuItem value="UK">UK</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

export default ChartAnalysis;
