import { useEffect, useState } from "react";
import { getTaskDetailsList, getURLDetails } from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate"; // Remove this if not used elsewhere
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "../utility/baseURLs";
const useURLDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const role_id = useSelector((state) => state.auth.userData.role_id);
  const accessToken = useSelector((state) => state.auth.accessToken); // Assuming you have accessToken in your state

  let rowData = location?.state?.rowData;
  const dispatch = useDispatch();

  const getAllURLDetails = async () => {
    setIsLoading(true);
    let param = {
      task_id: rowData?.task_id,
    };

    try {
      const response = await fetch(`${baseUrl}/url-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, 
        },
        body: JSON.stringify(param),
      });

      const data = await response.json();
      console.log(data, "getAllURLDetails");
      if (data?.code === 200) {
        dispatch(getURLDetails(data.urlDetailsList));
      }
    } catch (error) {
      console.error("Failed to fetch URL details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllURLDetails();
  }, []);

  return { isLoading, setIsLoading, getAllURLDetails };
};

export default useURLDetails;








// import { useEffect, useState } from "react";
// import { getTaskDetailsList, getURLDetails } from "../slice/taskSlice";
// import useAxiosPrivate from "../utility/useAxiosPrivate";
// import { useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// const useURLDetails = () => {
//   const [isLoading, SetIsLoading] = useState(false);
//   const location = useLocation();
//   const role_id = useSelector((state) => state.auth.userData.role_id);

//   let rowData = location?.state?.rowData;
//   const Axios = useAxiosPrivate();
//   const dispatch = useDispatch();

//   const getAllURLDetails = async () => {
//     SetIsLoading(true);
//     let param = {
//       task_id: rowData?.task_id,
//     };
//     const response = await Axios.post("/url-details", param);
//     const data = await response.data;
//     if (data?.code === 200) {
//       SetIsLoading(false);
//       dispatch(getURLDetails(data.urlDetailsList));
//     }
//   };

//   useEffect(() => {
//     getAllURLDetails();
//   }, []);
//   return { isLoading, SetIsLoading, getAllURLDetails };
// };

// export default useURLDetails;