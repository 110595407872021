import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getQAList, getQAUrlLastStatusId } from "../slice/QASlice";

const useUrlLastStatusId = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const allQAList = useSelector((state) => state.qa.qaList);
  const getUrlLastStatusId = async () => {
    let param = { url_id: allQAList[0]?.url_id };
    const response = await Axios.post("/qa-url-last-status-id", param);
    const data = await response.data;
    dispatch(getQAUrlLastStatusId(data?.UrlLastStatusId));
  };
  return getUrlLastStatusId;
};

export default useUrlLastStatusId;
