import "./style/QACorousel.scss";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import ScrollContainer from "react-indiana-drag-scroll";

export default function Test3(imageUrl) {
  const [zoomamount, setZoomamount] = useState(1);
  function zoom(el) {
    if (el.type === "in") {
      setZoomamount((prev) => prev + 0.1);
    } else {
      if (zoomamount > 1) {
        setZoomamount((prev) => prev - 0.1);
      }
    }
  }
  return (
    <div
      className="Example3"
      style={{ color: "#494949", position: "relative" }}
    >
      <>
        <div
          style={{
            position: "absolute",
            bottom: "40px",
            left: "95%",
            transform: "translateX(-50%)",
            zIndex: "100",
          }}
        >
          <ZoomOutIcon
            style={{
              fontSize: "2.5rem",
              cursor: "pointer",
              color: "gray",
              marginRight: "10px",
            }}
            onClick={() => zoom({ type: "out" })}
          />
          <ZoomInIcon
            style={{ fontSize: "2.5rem", cursor: "pointer", color: "gray" }}
            onClick={() => zoom({ type: "in" })}
          />
        </div>

        <div
          className="overflowimgcontainer"
          style={{ height: "75vh", width: "100%", overflow: "scroll" }}
        >
          <ScrollContainer
            className="grabbercontainer"
            style={{ cursor: "pointer", width: "100%", height: "100%" }}
            hideScrollbars={true}
          >
            <img
              style={{
                width: 100 * zoomamount + "%",
                height: 100 * zoomamount + "%",
                position: "relative",
                objectFit:"contain"
              }}
              src={imageUrl?.qaData}
              alt=""
            />
          </ScrollContainer>
        </div>
      </>
    </div>
  );
}
