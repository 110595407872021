// ImageViewer.js
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import "./style/QACorousel.scss";
import Carousel from "react-material-ui-carousel";

function ImageViewer({ imageUrl, open, onClose }) {
  console.log(imageUrl)
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 10px" }}>View Screenshot</DialogTitle>
      <DialogContent sx={{ padding: "10px 10px" }}>
        {/* <img
          src={imageUrl}
          alt="Screenshot"
          style={{ width: '100%', height: 'auto', maxHeight: '70vh' }}
        />       */}

        <div className="Example3" style={{ color: "#494949" }}>
          <Carousel
            animation="fade"
            navButtonsAlwaysVisible
            autoPlay={false}
            style={{ width: "100%", height: "100%" }}
          >
            
              <div
                // key={`test3-item-${i}`}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={imageUrl}
                  // alt={`Image ${i + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            
          </Carousel>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageViewer;
