import React from "react";
import { AppBar, Toolbar, makeStyles } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Logo from "../../assests/echnoLogo.png";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Element, scroller } from "react-scroll";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../slice/LoginSlice";
import { Typography } from "@mui/material";
import { getSelectedCardData } from "../../slice/QASlice";

const useStyles = makeStyles(() => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "-webkit-fill-available", // Corrected typo here
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)",
  },
}));

function Navbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userData= useSelector((state)=>state.auth.userData)
  const handleLogOut = () => {
    dispatch(getSelectedCardData(null));
    dispatch(logout());
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("task_id");
    localStorage.removeItem("user_id");
    navigate("/login");
  };
 const changeloaction = () => {
  navigate("/");
  window.location.reload();
 }
  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Element name="firstContainer">
        <Toolbar className={classes.toolbar}>
          <Link  
          // to="/"
          onClick={changeloaction}
          >
            <Box component="img" alt="Logo" src={Logo} width="2.5rem" />
          </Link>
          <Stack
            sx={{
              flexDirection: "row",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Typography>v 0.0.8</Typography> */}
            <Box sx={{display:"flex",flexDirection:"row",alignItems:"center",gap:"0.5rem"}}>
            <AccountCircleOutlinedIcon
              fontSize="large"
              sx={{ color: "#006FCF" }}
            />
            <Typography sx={{fontSize:"large", color:"#4A4A4A"}}>
              {userData?.full_name}
            </Typography>
            </Box>
            
            {isAuthenticated ? (
              <LogoutIcon
                titleAccess="Logout"
                onClick={handleLogOut}
                fontSize="large"
                sx={{ color: "#006FCF", cursor: "pointer" }}
              />
            ) : (
              <div> </div>
            )}
          </Stack>
        </Toolbar>
      </Element>
    </AppBar>
  );
}

export default Navbar;
