import React, { useEffect, useState } from "react";
import QAForm from "./QAL2Form";
// import Navbar from "../../Navbar/Navbar";
import QAL2Header from "./QAL2Header";
import useQAList from "../../../hooks/useQAList";
import { useSelector } from "react-redux";

const QAL2Page = () => {
  const [queryURL, setQueryURL] = useState(null);
  const allQAList = useSelector((state) => state.qa.qaList);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (allQAList && allQAList.length > 0) {
      const initialTimeLeft =
        allQAList[0].f_cards_available?.length > 0 ? 120 : 600;
      setTimeLeft(initialTimeLeft);
    }
  }, [allQAList]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const updateTime = (newTime) => {
    setTimeLeft(newTime);
  };


  return (
    <>
      <QAL2Header timeLeft={timeLeft} queryURL={queryURL} setQueryURL={setQueryURL}/>
      <QAForm updateTime={updateTime} timeLeft={timeLeft} setQueryURL={setQueryURL}/>
    </>
  );
};

export default QAL2Page;
