export const pageTypeList = [
    "Home Page",
    "Payment Page",
    "Checkout Page",
    "Cart Page",
    "Catalog Page",
    "Ticket Page",
    "Basket Page",
    "Booking Page",
    "Gift Page",
    "Buy Page",
    "Terms & Conditions", 
    "Privacy Policy", 
    "FAQ", 
    "Donate Page"
  ];