import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useExecutorDashboardReport from "../../hooks/useExecutorDashboardReport";
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ExecutorAdminTable from './ExecutorAdminTable';
import { Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TailSpin } from 'react-loader-spinner'

const theme = createTheme({
    components: {
      MuiPickersToolbar: {
        styleOverrides: {
          toolbarBtn: {
            color: "#006FCF",
          },
        },
      },
    },
  });

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ExecutorTab() {
const navigate=useNavigate()
  
  const [loader,setLoader]=useState(false)
  const getExecutorDashboardData = useExecutorDashboardReport(setLoader);
  const executorReport = useSelector(
    (store) => store.task.executorDashboardReport
  );
  const [executorDetails,setExecutorDetails]=useState(null)
  const [selectedExecutorDetails,setSelectedExecutorDetails]=useState(null)
  const [value, setValue] = useState(0);
  const [rowData, setRowData] = useState(null);
  const currentDate = dayjs();
  const date = currentDate.subtract(6, 'day');
  const [fromDate,setFromDate]=useState(date)
  const [toDate,setToDate]=useState(currentDate)

  const handleChange = async(event, newValue, user_id) => {
    setValue(newValue);
    setFromDate(date)
    setToDate(currentDate)
    const executorReport = await getExecutorDashboardData(date, currentDate);
    if (executorReport) {
      const selectedUserDetails = executorReport?.find(
        (executor) => executor?.user_id === user_id
      );
      let taskNames;
      taskNames = Object.keys(selectedUserDetails?.daily_data?.[0]).filter(
        (key) => key !== "date"
      );

      const rows = taskNames?.map((task) => {
        return task;
      });
      setRowData(rows);
      setSelectedExecutorDetails(selectedUserDetails);
    }
  };

  const getExecutorReport = async () => {
    const executorReport = await getExecutorDashboardData(fromDate,toDate);
    if (executorReport) {
      const executorDetails = executorReport?.map((executor) => {
        return { user_id: executor?.user_id, full_name: executor?.full_name };
      });
      const selectedUserDetails = executorReport?.find(
        (executor) => executor?.user_id === executorDetails[value]?.user_id
      );
      let taskNames;
      taskNames = Object.keys(selectedUserDetails?.daily_data?.[0]).filter(
        (key) => key !== "date"
      );

      const rows = taskNames?.map((task) => {
        return task;
      });

      setRowData(rows);
      setSelectedExecutorDetails(selectedUserDetails);
      setExecutorDetails(executorDetails);
    }
  };

  useEffect(() => {
    getExecutorReport();
  }, []);

  const handleBack=()=>{
    navigate("/")
  }

 
  const handleDateChange = async (date, name) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    let executorReport;
    if (name === "from") {
      setFromDate(date);
      executorReport = await getExecutorDashboardData(
        formattedDate,
        dayjs(toDate).format("YYYY-MM-DD")
      );
      if (executorReport) {
        const selectedUserDetails = executorReport?.find(
          (executor) => executor?.user_id === executorDetails[value]?.user_id
        );
        let taskNames;
        taskNames = Object.keys(selectedUserDetails?.daily_data?.[0]).filter(
          (key) => key !== "date"
        );

        const rows = taskNames?.map((task) => {
          return task;
        });

        setRowData(rows);
        setSelectedExecutorDetails(selectedUserDetails);
        setExecutorDetails(executorDetails);
      }
    } else {
      setToDate(date);
      executorReport = await getExecutorDashboardData(
        dayjs(fromDate).format("YYYY-MM-DD"),
        formattedDate
      );
      if (executorReport) {
        const selectedUserDetails = executorReport?.find(
          (executor) => executor?.user_id === executorDetails[value]?.user_id
        );
        let taskNames;
        taskNames = Object.keys(selectedUserDetails?.daily_data?.[0]).filter(
          (key) => key !== "date"
        );

        const rows = taskNames?.map((task) => {
          return task;
        });

        setRowData(rows);
        setSelectedExecutorDetails(selectedUserDetails);
        setExecutorDetails(executorDetails);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <KeyboardBackspaceIcon
            onClick={handleBack}
            sx={{ cursor: "pointer", fontSize: "30px" }}
          />
          <Typography
            style={{
              marginLeft: "10px",
              fontSize: "24px",
              fontWeight: "400",
              lineHeight: "36px",
            }}
          >
            Executor Report
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.8rem",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            {" "}
            <Typography sx={{ alignItems: "center", alignSelf: "center" }}>
              {" "}
              From:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ maxWidth: "150px" }}
                slotProps={{ textField: { size: "small" } }}
                value={fromDate}
                onChange={(e) => handleDateChange(e, "from")}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            {" "}
            <Typography sx={{ alignItems: "center", alignSelf: "center" }}>
              {" "}
              To:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <DatePicker
                  sx={{ maxWidth: "150px" }}
                  slotProps={{ textField: { size: "small" } }}
                  value={toDate}
                  onChange={(e) => handleDateChange(e, "to")}
                />
              </ThemeProvider>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(event, newValue) =>
              handleChange(event, newValue, executorDetails[newValue]?.user_id)
            }
            aria-label="basic tabs example"
          >
            {executorDetails?.map((details, index) => (
              <Tab label={details?.full_name} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {loader ? (
          <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh', // Full viewport height
          }}
        >
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        
        ) : (
          executorDetails?.map((executor, index) => (
            <CustomTabPanel value={value} index={index}>
              <ExecutorAdminTable
                rowData={rowData}
                executorReport={selectedExecutorDetails}
              />
            </CustomTabPanel>
          ))
        )}
      </Box>
    </Box>
  );
}