import React from "react";
import { Container, Box, Typography, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import useQAAccessStatusList from "../../hooks/useQAAccessStatusList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useRELCount from "../../hooks/useRELCount";
import { getFilterSelectedRemark, getSearchUrlId } from "../../slice/QASlice";
import useFilterRemarksList from "../../hooks/useFilterRemarksList";
import useRelList from "../../hooks/useRelList";
import useSearchList from "../../hooks/useSearchList";
import useSplitUrl from "../../hooks/useSplitUrl";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Button } from '@mui/material';

const QAL2Header = ({ queryURL, setQueryURL }) => {
  const dispatch = useDispatch();

  //Redux
  const currentCount = useSelector((state) => state.qa.relList?.filterCount);
  const userRole = useSelector((state) => state?.auth?.userData?.role_code);
  const relCount = useSelector((state) => state.qa.relCount);
  const remarksList = useSelector((state) => state.qa.filterRemarksList);

  //Api calls
  useRELCount();
  useQAAccessStatusList();
  useFilterRemarksList();
  const getAllRelList = useRelList();
  const getAllSearchList = useSearchList();

  const handleRemarksDropdownChange = (e) => {
    dispatch(getFilterSelectedRemark(e.target.value));
    getAllRelList(e.target.value);
  };

  const handleInput = (e) => {
    setQueryURL(e.target.value);
  };
  const handleSearch = () => {
    getAllSearchList(queryURL);
    dispatch(getSearchUrlId(parseInt(queryURL)));
  };

  const [open, setOpen] = React.useState(false);
  const getSplitUrl=useSplitUrl()
  const handleSplitUrl=async()=>{
    setOpen(true);
  }

  const handleConfirmSplitUrl = async() => {
    await getSplitUrl(queryURL)
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth="2xl"
      sx={{ marginTop: "0.8rem", paddingBottom: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          gap: "1rem",
        }}
      >
        <Stack
          direction={{ xs: "row", sm: "column" }}
          sx={{
            justifyContent: { xs: "flex-start", sm: "center" },
            alignItems: { xs: "center", sm: "flex-start" },
            gap: { xs: "0.5rem", sm: "0rem" },
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <Stack sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#000",
                  fontSize: "1.5rem",
                  justifyContent: "center",
                }}
              >
                F-REL
              </Typography>
              <Typography
                sx={{
                  color: "#6B6A6A",
                  fontSize: "1rem",
                  fontWeight: "400",
                  justifyContent: "center",
                }}
              >
                Total URLs in F-REL: {relCount}
              </Typography>
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                alignItems: "center",
              }}
            >
              <input
                type="number"
                style={{ height: "2rem", width: "12rem" }}
                onChange={handleInput}
                value={queryURL}
                placeholder="S.No."
              />
              <button
                onClick={handleSearch}
                style={{ width: "6rem", height: "2rem", cursor: "pointer" }}
              >
                <Typography sx={{ fontSize: "1rem" }}>Search</Typography>
              </button>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "0.5rem", sm: "0.5rem", md: "1.5rem" },
              alignItems: { xs: "flex-start", sm: "flex-start", lg: "center" },
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                lg: "center",
              },
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // gap: "0.2rem",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0.8rem",
                  }}
                >
                  <Button
                    sx={{
                      background: "#4287f5",
                      color: "white",
                      padding: "0.5rem",
                      "&:hover": { background: "#4287f5" },
                    }}
                    onClick={handleSplitUrl}
                  >
                    Split this Url
                  </Button>
                  <Dialog open={open} onClose={handleCloseDialog}>
                    <DialogTitle>Confirm Split URL</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This will create a new URL in S1 bucket, If you want to
                        split this URL Click confirm button.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmSplitUrl} color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <select
                    id="remarks"
                    name="remarks"
                    style={{
                      height: "3.2rem",
                      width: "10rem",
                      cursor: "pointer",
                      marginTop: "-0.4rem",
                    }}
                    onChange={handleRemarksDropdownChange}
                    // value={selectedSegment}
                  >
                    <option disabled selected id="select">
                      Select Segment
                    </option>
                    {remarksList?.map((remark) => (
                      <option
                        id={remark?.remark_id}
                        value={remark?.remark_name}
                      >
                        {remark?.remark_name}
                      </option>
                    ))}
                  </select>
                  <Typography
                    sx={{
                      background: "red",
                      borderRadius: "50%",
                      padding: "0.6rem",
                      height: "0.5rem",
                      color: "white",
                    }}
                  >
                    <Typography sx={{ marginTop: "-0.4rem" }}>
                      {currentCount ?? 0}
                    </Typography>
                  </Typography>
                </Stack>
                {userRole === "customer" && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      paddingLeft: "2rem",
                      paddingRight: "2rem",
                    }}
                  >
                    <Card
                      sx={{
                        border: "0.2px solid #585858",
                        // mb: { xs: 2, lg: 0 },
                        borderRadius: "0px",
                        position: "relative",
                        overflow: "visible",
                        marginBottom: "10px !important",
                        width: "125px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      elevation={0}
                    >
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          borderRadius: "50%",
                          color: "#329519",
                        }}
                      />

                      <CardContent
                        sx={{
                          flexDirection: "column",
                          display: "flex",
                          padding: "10px 32px",
                          alignItems: "center",
                          gap: "4px",
                          "&:last-child": {
                            paddingBottom: "10px",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#A2A2A2",
                            textWrap: "nowrap",
                          }}
                          variant="p"
                        >
                          f-REL
                        </Typography>
                        {/* <Typography
                          sx={{ fontSize: "14px", fontWeight: "700" }}
                          variant="p"
                        >
                          {currentCount > relCount ? 0 : currentCount} /{" "}
                          {relCount}
                        </Typography> */}
                      </CardContent>
                    </Card>
                  </Stack>
                )}
                {/* <Stack
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: { xs: "0.5rem", sm: "0.5rem", md: "1.5rem" },
                  alignItems: {
                    xs: "flex-start",
                    sm: "flex-start",
                    lg: "center",
                  },
                  justifyContent: {
                    xs: "flex-start",
                    sm: "flex-start",
                    lg: "center",
                  },
                }}
              >
               
              </Stack> */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default QAL2Header;
