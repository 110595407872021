import React, { useEffect } from "react";
import QAHeader from "./QAHeader";
import QAForm from "./QAForm";
import { useSelector } from "react-redux";
import QAAltForm from "./QAAltForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Stack } from "@mui/material";
// import Navbar from "../../Navbar/Navbar";

const QAL1Page = () => {
  const selectedCard = useSelector((state) => state.qa.selectedQAAltCardData);
  return (
    <>
      <ToastContainer />
      <QAHeader />
      {selectedCard === 0 ? <QAForm /> : <QAAltForm />}
    </>
  );
};

export default QAL1Page;
