import { useEffect, useState } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useQAApprove = (
  checked,
  remarks,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl
) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectedCardTypes = Object.keys(checked).filter((key) => checked[key]);
  console.log(selectedCardTypes);
  //Redux
  const allRelList = useSelector((state) => state.qa.relList);
  const allQAList = useSelector((state) => state.qa.qaList);
  const allCOMList = useSelector((state) => state.qa.comList);
  const allL2S1List = useSelector((state) => state.qa.l2S1List);
  const allL2S1ListTemp = useSelector((state) => state.qa.l2S1ListTemp);
  const allL2S2List = useSelector((state) => state.qa.l2S2List);
  const allfDWHList = useSelector((state) => state.qa.fDWHList);
  const allfNWList = useSelector((state) => state.qa.fNWList);
  const remarkSelectedStatus=useSelector((state)=>state.qa.currentRemarkStatus)
  const allLevel1QAList = useSelector((state) => state.qa.level1qaList);
  const allLevel1QAAltList = useSelector((state) => state.qa.level1qaaltList);
  const allDuplicateList = useSelector((state) => state.qa.duplicateDetails);
  const currentMoveToId = useSelector((state) => state.qa.moveToId);
  console.log(allQAList?.[0]?.card_available);
  const Axios = useAxiosPrivate();
  const qaApprove = async (type) => {
    let available_cards = [];
    if (Array.isArray(selectedCardTypes)) {
      // If selectedCardTypes is already an array, assign it to available_cards
      available_cards = selectedCardTypes;
    } else if (selectedCardTypes !== undefined && selectedCardTypes !== null) {
      // If selectedCardTypes is not an array, convert it to an array
      available_cards = [selectedCardTypes];
    }

    let param = {
      available_cards,
      f_cards_available: available_cards,
      // user_id:
      //   type === 2
      //     ? allQAList?.[0]?.user_id
      //     : type === 3
      //     ? allLevel1QAAltList?.[0]?.user_id
      //     : type === 4
      //     ? allRelList?.[0]?.user_id
      //     : type === 5
      //     ? allCOMList?.[0]?.user_id
      //     : type === 6
      //     ? allL2S1List?.[0]?.user_id
      //     : type === 7
      //     ? allL2S2List?.[0]?.user_id
      //     : allLevel1QAList?.[0]?.user_id,
      url_id:
        type === 2
          ? allQAList?.[0]?.url_id
          : type === 3
          ? allLevel1QAAltList?.[0]?.url_id
          : type === 4
          ? allRelList?.[0]?.url_id
          : type === 5
          ? allCOMList?.[0]?.url_id
          : type === 6
          ? allL2S1List?.[0]?.url_id
          : type === 7
          ? allL2S1List?.[0]?.url_id
          : type === 8
          ? allL2S2List?.[0]?.url_id
          : type === 9
          ? allL2S1ListTemp?.[0]?.url_id
          : type === 10
          ? allL2S1ListTemp?.[0]?.url_id
          : type === 11
          ? allL2S2List?.[0]?.url_id
          : type === 12
          ? allfDWHList?.[0]?.url_id
          : type === 13
          ? allfNWList?.[0]?.url_id
          : type === 14
          ? allfNWList?.[0]?.url_id
          : type === 15
          ? allfDWHList?.[0]?.url_id
          : type === 16
          ? allDuplicateList?.[0]?.url_id
          : allLevel1QAList[0]?.url_id,

      status_id:
        type === 3
          ? 2
          : type === 4
          ? 21
          : type === 5
          ? 21
          : type === 6
          ? 10
          : type === 7
          ? remarkSelectedStatus===true ? currentMoveToId : 3
          : type === 8
          ? 10
          : type === 9
          ? remarkSelectedStatus===true ? currentMoveToId : 3
          : type === 10
          ? 10
          : type === 11
          ? remarkSelectedStatus===true ? currentMoveToId : 4
          : type === 12
          ? remarkSelectedStatus===true ? currentMoveToId : 7
          : type === 13
          ? remarkSelectedStatus===true ? currentMoveToId : 19
          : type === 14
          ? 10
          : type === 15
          ? 10
          : type === 16
          ? 10
          : 20,
      remarks: remarks,
      all_url_status:
        type === 6
          ? "level2-qa-true"
          : type === 8
          ? "level2-qa-true"
          : type === 10
          ? "level2-qa-true"
          : type === 14
          ? "level2-qa-true"
          : type === 15
          ? "level2-qa-true"
          : "normal-true",
      task_id:
        type === 2
          ? allQAList?.[0]?.task_id
          : type === 3
          ? allLevel1QAAltList?.[0]?.task_id
          : type === 4
          ? allRelList?.[0]?.task_id
          : type === 5
          ? allCOMList?.[0]?.task_id
          : type === 6
          ? allL2S1List?.[0]?.task_id
          : type === 7
          ? allL2S1List?.[0]?.task_id
          : type === 8
          ? allL2S2List?.[0]?.task_id
          : type === 9
          ? allL2S1ListTemp?.[0]?.task_id
          : type === 10
          ? allL2S1ListTemp?.[0]?.task_id
          : type === 11
          ? allL2S2List?.[0]?.task_id
          : type === 12
          ? allfDWHList?.[0]?.task_id
          : type === 13
          ? allfNWList?.[0]?.task_id
          : type === 14
          ? allfNWList?.[0]?.task_id
          : type === 15
          ? allfDWHList?.[0]?.task_id
          : type === 16
          ? allDuplicateList?.[0]?.task_id
          : allLevel1QAList?.[0]?.task_id,

      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
    };
    console.log("param", param);

    const response = await Axios.post("/url-status-add", param);
    const data = await response.data;
    if (data.code === 200) {
      setIsLoading(false);
      toast.success(
        type === 4
          ? "Updated Successfully"
          : type === 6
          ? "Moved To QA"
          : type === 7
          ? "Moved Successfully"
          : "Approved Successfully",
        {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      toast.error(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return { qaApprove, isLoading, setIsLoading };
};

export default useQAApprove;
