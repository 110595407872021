import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getQAList, getRefreshImage } from "../slice/QASlice";

const useRefreshImage = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_url_id=localStorage.getItem('url_id')
  const getAllRefreshImage = async () => {
    let param = {
      url_id: current_url_id,
    };
    const response = await Axios.post("/get-refresh-image", param);
    const data = await response.data;
    dispatch(getRefreshImage(data.refreshImage));
  };
  return getAllRefreshImage;
};

export default useRefreshImage;
