import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  FormControl,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Modal, Backdrop, Fade, styled } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedMoveToId,
  getSelectedRemarkStatus,
  showQaData,
} from "../../slice/QASlice";
import ClearIcon from "@mui/icons-material/Clear";
import useQAApprove from "../../hooks/useQAApprove";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useQANotAssignedUpdate from "../../hooks/useQANotAssignedUpdate";
import useQAStatusUpdate from "../../hooks/useQAStatusUpdate";
import useUrlLastStatusId from "../../hooks/useUrlLastStatusId";
import useQAReject from "../../hooks/useQAReject";
import QACorousel from "./QACorousel";
import useQAAccessStatusList from "../../hooks/useQAAccessStatusList";
import LaunchIcon from "@mui/icons-material/Launch";
import EditIcon from "@mui/icons-material/Edit";
import Input from "@mui/material/Input";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useQAImageUpload from "../../hooks/useImageUpload";
import useQAImageUpdate from "../../hooks/useQAImageUpdate";
import useL2S1List from "../../hooks/useL2S1List";
import useL2StatusUpdate from "../../hooks/useL2StatusUpdate";
import SearchIcon from "@mui/icons-material/Search";
import useRemarksList from "../../hooks/useRemarksList";
import useRefreshImage from "../../hooks/useRefreshImage";
import RefreshIcon from "@mui/icons-material/Refresh";
import Timer from "../Timer";
import noImage from "../../assests/noImage.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMoveQA from "../../hooks/useMoveQA";
import useMoveNext from "../../hooks/useMoveNext";
import { pageTypeList } from "../../utility/constant";

const QAForm = ({ updateTime, timeLeft, setQueryURL }) => {
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isEditAlternate, setIsEditAlternate] = useState(false);
  const [isEditPage, setIsEditPage] = useState(false);
  const [alternate, setAlternate] = useState(null);
  const [pageType, setPageType] = useState(null);
  const [ecommerce, setEcommerce] = useState(null);
  const [fillForm, setFillForm] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [remarks, setRemarks] = useState(null);
  const [open, setOpen] = useState(false);
  const [cardAvailableArray, setCardAvailableArray] = useState(null);
  const [checked, setChecked] = useState({
    amex: false,
    mastercard: false,
    visa: false,
    paypal: false,
    wallet: false,
    discover: false,
  });
  const [pageUrl, setPageUrl] = useState(null);
  const [isEditPageUrl, setIsEditPageUrl] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const handleEditPageUrl = () => {
    setIsEditPageUrl(true);
  };
  const handleChangePageUrl = (e) => {
    setPageUrl(e.target.value);
  };

  // Initial value for the dropdown
  const initialValue = "";

  // State to track the current value of the dropdown
  const [value, setValue] = useState(initialValue);

  // State to track if the dropdown has been changed
  const [isChanged, setIsChanged] = useState(false);

  //Redux
  const allL2S1List = useSelector((state) => state.qa.l2S1List);
  const qaFormData = useSelector((state) => state.qa.qaFormData);
  const remarksList = useSelector((state) => state.qa.remarksList);

  //Api calls
  const { qaReject, isRejectLoading, setIsRejectLoading } = useQAReject(
    checked,
    remarks,
    pageType,
    alternate,
    ecommerce,
    pageUrl
  );
  const { qaMove, isLoading, setIsLoading } = useMoveQA(
    checked,
    remarks,
    pageType,
    alternate,
    ecommerce,
    pageUrl,
    selectedSegment,
    fillForm
  );

  const moveNext = useMoveNext(
    checked,
    remarks,
    pageType,
    alternate,
    ecommerce,
    pageUrl,
    selectedSegment,
    fillForm
  );

  const getAllL2S1List = useL2S1List();
  const getAllQAAccessStatusList = useQAAccessStatusList();
  const getUrlLastStatusId = useUrlLastStatusId();
  const l2StatusUpdate = useL2StatusUpdate();
  const getQAImageUpload = useQAImageUpload();
  useRemarksList();

  useEffect(() => {
    localStorage.setItem("url_status_id", allL2S1List?.[0]?.url_status_id);
    localStorage.setItem("url_id", allL2S1List?.[0]?.url_id);
    localStorage.setItem("sno", allL2S1List?.[0]?.sno);
  }, [allL2S1List]);

  useEffect(() => {
    setSelectedImageUrl(allL2S1List?.[0]?.img_url);
  }, [allL2S1List]);

  useEffect(() => {
    setSelectedImageUrl(qaFormData?.full_path);
  }, [qaFormData]);

  useEffect(() => {
    setChecked({
      amex: allL2S1List?.[0]?.f_cards_available?.includes("amex"),
      mastercard: allL2S1List?.[0]?.f_cards_available?.includes("mastercard"),
      visa: allL2S1List?.[0]?.f_cards_available?.includes("visa"),
      paypal: allL2S1List?.[0]?.f_cards_available?.includes("paypal"),
      wallet: allL2S1List?.[0]?.f_cards_available?.includes("wallet"),
      discover: allL2S1List?.[0]?.f_cards_available?.includes("discover"),
    });
  }, [allL2S1List]);

  useEffect(() => {
    if (allL2S1List?.[0]?.card_available) {
      setCardAvailableArray(allL2S1List[0].card_available);
    }
  }, [allL2S1List]);

  useEffect(() => {
    getUrlLastStatusId();
  }, [allL2S1List?.[0]?.url_id]);

  useEffect(() => {
    if (cardAvailableArray) {
      setChecked({
        amex: cardAvailableArray.includes("amex"),
        mastercard: cardAvailableArray.includes("mastercard"),
        visa: cardAvailableArray.includes("visa"),
        paypal: cardAvailableArray.includes("paypal"),
        wallet: cardAvailableArray.includes("wallet"),
        discover: cardAvailableArray.includes("discover"),
      });
    }
  }, [cardAvailableArray]);

  useEffect(() => {
    setAlternate(allL2S1List?.[0]?.alternate_url ?? "");
  }, [allL2S1List]);

  useEffect(() => {
    setPageUrl(allL2S1List?.[0]?.page_url ?? "");
  }, [allL2S1List]);

  useEffect(() => {
    setRemarks(allL2S1List?.[0]?.remarks ?? "");
  }, [allL2S1List]);

  useEffect(() => {
    setSelectedSegment(allL2S1List?.[0]?.segment ?? "");
  }, [allL2S1List]);

  useEffect(() => {
    setPageType(allL2S1List?.[0]?.page_type ?? "");
  }, [allL2S1List]);

  useEffect(() => {
    if (allL2S1List && allL2S1List?.[0]) {
      setEcommerce(allL2S1List?.[0]?.ecommerce);
    }
  }, [allL2S1List]);

  useEffect(() => {
    if (allL2S1List && allL2S1List?.[0]) {
      setFillForm(allL2S1List?.[0]?.without_fill_form);
    }
  }, [allL2S1List]);

  const handleDiscard = () => {
    setIsEdit(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleURLClick = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    const newWindow=window.open(url, "_blank");
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Handle blocked pop-ups or failed window opening
      alert("Unable to open the URL in a new tab. Please disable your pop-up blocker or check the URL.");
    }
  };

  const handleStartAgain = () => {
    getAllL2S1List();
  };

  const searchGoogle = (value) => {
    const query = value; // Replace with your search query
    const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(
      query
    )}`;
    window.open(googleSearchUrl, "_blank");
  };

  const [selectedRemarkId, setSelectedRemarkId] = useState(null);
  const [moveToID, setMoveToID] = useState(null);
  const dispatch = useDispatch();

  const handleRemarksDropdownChange = (e) => {
    const text = e.target.value;
    const result = text?.split("]")[1]?.trim();
    const newResult = result === undefined ? e.target.value : result;
    const resultSegment = text?.split("[")[1]?.split("]")[0]?.trim();
    const newResultSegment = resultSegment ? resultSegment : "";
    setSelectedSegment(newResultSegment);
    dispatch(getSelectedRemarkStatus(true));
    setValue(e.target.value);
    let remarksId = 0;
    let moveToId = 0;
    for (let i = 0; i < remarksList?.length; i++) {
      if (remarksList[i]?.remark_name === e.target.value) {
        remarksId = remarksList[i]?.remark_id;
        moveToId = remarksList[i]?.move_to;
        break;
      }
    }

    if (e.target.value !== initialValue) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
    dispatch(getSelectedMoveToId(moveToId));
    setSelectedRemarkId(remarksId);
    setRemarks(newResult);
  };

  // const [previousResult, setPreviousResult] = useState(null);
  // const handleRemarksDropdownChange = (e) => {
  //   const text = e.target.value;
  //   const result = text?.split("]")[1]?.trim();
  //   const newResult = result === undefined ? e.target.value : result;
  //   const resultSegment = text?.split("[")[1]?.split("]")[0]?.trim();

  //   setSelectedSegment(resultSegment);

  //   setRemarks((prevRemarks) => {
  //     // Initialize newRemarks by removing the previous result if it exists
  //     let newRemarks = prevRemarks;

  //     if (previousResult && prevRemarks) {
  //       const regex = new RegExp(`(^|,\\s*)${previousResult}(,\\s*|$)`);
  //       newRemarks = prevRemarks.replace(regex, "").trim();
  //     }

  //     // If newRemarks is empty, just return the new result
  //     if (!newRemarks) {
  //       setPreviousResult(newResult);
  //       return newResult;
  //     }

  //     // If new result is not empty and not already part of remarks, add it
  //     if (newResult && !newRemarks.includes(newResult)) {
  //       newRemarks = `${newResult}, ${newRemarks}`.trim();
  //     }

  //     setPreviousResult(newResult);

  //     // Ensure no trailing or leading commas
  //     return newRemarks.replace(/^,|,$/g, "").trim();
  //   });
  // };

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("url_id", allL2S1List?.[0]?.url_id);
    formData.append("task_id", allL2S1List?.[0]?.task_id);
    formData.append("url_status_id", allL2S1List?.[0]?.url_status_id);
    getQAImageUpload(formData);
    if (uploadedFile) {
      setSelectedFileName(uploadedFile?.name);
    }
  };

  const ModalContent = styled("div")({
    backgroundColor: "gray",
    boxShadow: 24,
    padding: 3,
  });

  function getStatusIcon(value) {
    return value ? (
      <CheckIcon style={{ color: "#0EA101" }} />
    ) : (
      <ClearIcon style={{ color: "#FF0000" }} />
    );
  }

  const handleQA = async (value) => {
    updateTime(120);
    setSelectedButton(value);
    try {
      setIsEditAlternate(false);
      setIsEditPage(false);
      setIsEditPageUrl(false);
      setIsLoading(true);
      setEcommerce(allL2S1List?.[0]?.ecommerce);
      setFillForm(allL2S1List?.[0]?.without_fill_form);
      setRemarks(allL2S1List?.[0]?.remarks ?? "");
      setPageType(allL2S1List?.[0]?.page_type);
      setQueryURL("");
      // Update the QA status
      await l2StatusUpdate(1);

      // Update the QA approval status
      await qaMove("l2S1");

      // Get the updated QA list
      await getAllL2S1List();
      await getAllQAAccessStatusList();
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const remarkSelectedStatus = useSelector(
    (state) => state.qa.currentRemarkStatus
  );
  const handleNext = async () => {
    try {
      if (remarks !== null) {
        updateTime(120);
        dispatch(getSelectedRemarkStatus(false));
        setIsEditAlternate(false);
        setIsEditPage(false);
        setIsEditPageUrl(false);
        // setIsLoading(true);
        setEcommerce(allL2S1List?.[0]?.ecommerce);
        setFillForm(allL2S1List?.[0]?.without_fill_form);
        setRemarks(allL2S1List?.[0]?.remarks ?? "");
        setPageType(allL2S1List?.[0]?.page_type);
        setQueryURL("");
        // Update the QA status
        await l2StatusUpdate(1);

        // Update the QA approval status
        await moveNext("l2S1");

        // Get the updated QA list
        await getAllL2S1List();
        await getAllQAAccessStatusList();
      }
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      await getAllL2S1List();
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleCheckbox = (e) => {
    const newValue = e.target.checked;
    setChecked((obj) => ({
      ...obj,
      [e.target.name]: newValue,
    }));
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleEditAlternate = () => {
    setIsEditAlternate(true);
  };

  const handleEditPage = () => {
    setIsEditPage(!isEditPage);
  };

  const handleChangeAlternate = (e) => {
    setAlternate(e.target.value);
  };

  const handleChangePageType = (e) => {
    setPageType(e.target.value);
  };

  const handleChangeEcommerce = (e) => {
    setEcommerce(e.target.checked);
  };

  const handleChangeFillForm = (e) => {
    setFillForm(e.target.checked);
  };

  const getAllRefreshImage = useRefreshImage();
  const refreshImage = useSelector((state) => state.qa.refreshImage);

  const handleRefreshImage = async () => {
    await getAllRefreshImage();
  };

  const copyTextToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Text Copied Successfully!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  useEffect(() => {
    setSelectedImageUrl(refreshImage?.[0]?.img_url);
  }, [refreshImage]);


  const handlePageTypeDropdownChange = (e) => {
    setPageType(e.target.value);
  };

  const handleSegmentChange = (e) => {
    setSelectedSegment(e.target.value);
  };

  return isLoading === true || isRejectLoading === true ? (
    <div>
      <Box
        sx={{ display: "flex", position: "absolute", top: "50%", left: "50%" }}
      >
        <CircularProgress />
      </Box>
    </div>
  ) : (
    <>
      <ToastContainer />
      <Container
        maxWidth="2xl"
        sx={{
          paddingTop: { xs: "2rem", sm: "1.2rem" },
          paddingBottom: "1.2rem",
          backgroundColor: "#F8F8F8",
        }}
      >
        {allL2S1List?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "20px", sm: "20px" },
              flexWrap: "wrap",
              // height:"100%"
            }}
          >
            {allL2S1List?.map((qaData) => (
              <>
                <Stack sx={{ flex: "3", position: "relative" }}>
                  {/* <Stack sx={{ position: "relative" }}> */}
                  {/* <img src={qaData?.screenshot_url} alt="amex logo" style={{ height: "100%" }} /> */}
                  <QACorousel
                    qaData={
                      selectedImageUrl === null || selectedImageUrl === ""
                        ? noImage
                        : selectedImageUrl
                    }
                  />
                  <ZoomOutMapIcon
                    sx={{
                      position: "absolute",
                      right: "15px",
                      top: "16px",
                      fontSize: "2rem",
                      cursor: "pointer",
                      color: "gray",
                      zIndex: "100",
                    }}
                    onClick={handleOpen}
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "center",
                      position: "absolute",
                      bottom: "20px",
                      left: 0,
                      right: 0,
                    }}
                  ></Stack>
                  {/* </Stack> */}

                  <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                      sx: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                    }}
                  >
                    <Fade in={open}>
                      <ModalContent>
                        <img
                          src={qaData?.img_url}
                          alt="Full Screen Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        />
                        <CloseIcon
                          sx={{
                            position: "absolute",
                            top: "0.6rem",
                            right: "0.6rem",
                            fontSize: "2.5rem",
                            cursor: "pointer",
                            color: "gray",
                          }}
                          onClick={handleClose}
                        />
                      </ModalContent>
                    </Fade>
                  </Modal>
                  <Input
                    accept="image/*"
                    id="file-upload-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.8rem",
                      }}
                    >
                      <label htmlFor="file-upload-input">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{ textWrap: "nowrap" }}
                          startIcon={<UploadFileIcon />}
                        >
                          {selectedFileName ? selectedFileName : "Update Image"}
                        </Button>
                        {/* {errors.file && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8rem",
                    marginTop: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  **File is required**
                </p>
              )} */}
                      </label>
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{ textWrap: "nowrap" }}
                        startIcon={<RefreshIcon />}
                        width="max-content"
                        onClick={handleRefreshImage}
                      >
                        Refresh
                      </Button>
                    </Stack>

                    <Timer timeLeft={timeLeft} />
                  </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center", height: "90%" }}>
                  <Card
                    sx={{ flex: "1", width: { xs: "max-content", sm: "100%" } }}
                  >
                    <CardContent
                      key={qaData?.url_id}
                      sx={{
                        lineHeight: "1.2rem",
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "row",
                          md: "column",
                        },
                        justifyContent: { xs: "", sm: "space-between" },
                      }}
                    >
                      <Stack sx={{ gap: "20px" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            fontSize: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            URL ID:
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.url_id ?? "N/A"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            S.No :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.sno ?? "N/A"}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{ color: "#989898", fontWeight: "700" }}
                            >
                              URL
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "400",
                                color: "#006FCF",
                                // display: "flex",
                                // flexDirection: "row",
                                // alignItems: "center",
                                gap: "0.2rem",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                                width: "410px",
                              }}
                            >
                              {qaData?.url === null || qaData?.url === ""
                                ? "N/A"
                                : qaData?.url}
                            </Typography>
                          </Stack>
                          <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            <LaunchIcon
                              onClick={() => handleURLClick(qaData?.url)}
                              sx={{ cursor: "pointer" }}
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "450px",
                          }}
                        >
                          <button
                            onClick={() => copyTextToClipboard(qaData?.name)}
                          >
                            {qaData?.name}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.address)}
                          >
                            {qaData?.address}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.area)}
                          >
                            {qaData?.area}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.town)}
                          >
                            {qaData?.town}
                          </button>
                          <button
                            onClick={() =>
                              copyTextToClipboard(qaData?.postal_code)
                            }
                          >
                            {qaData?.postal_code}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.country)}
                          >
                            {qaData?.country}
                          </button>
                        </Stack>
                        <Stack sx={{ fontSize: "0.9rem" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                              }}
                            >
                              Alternate URL
                              <SearchIcon
                                onClick={() =>
                                  searchGoogle(qaData?.full_details)
                                }
                                sx={{ color: "black", cursor: "pointer" }}
                              />
                            </Stack>
                          </Typography>

                          {isEditAlternate === false ? (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  color: "#006FCF",
                                  gap: "0.2rem",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  whiteSpace: "normal",
                                  width: "410px",
                                }}
                              >
                                {qaData?.alternate_url === null ||
                                qaData?.alternate_url === ""
                                  ? "N/A"
                                  : qaData?.alternate_url}
                              </Typography>
                              <Stack
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <LaunchIcon
                                  onClick={() =>
                                    handleURLClick(qaData?.alternate_url)
                                  }
                                  sx={{ cursor: "pointer" }}
                                />

                                <EditIcon
                                  sx={{ width: "1.2rem", cursor: "pointer" }}
                                  onClick={() => handleEditAlternate()}
                                />
                              </Stack>
                            </Stack>
                          ) : (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.6rem",
                              }}
                            >
                              <input
                                value={alternate}
                                style={{ height: "1.8rem" }}
                                onChange={(e) => handleChangeAlternate(e)}
                              />
                              {/* <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "0.6rem",

                                  justifyContent: "center",
                                }}
                              >
                                <Stack
                                  sx={{
                                    background: "#EA001B",
                                    width: "5.5rem",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      color: "#FFFFFF",
                                      textTransform: "none",
                                    }}
                                    onClick={() => handleCancelAlternate()}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                                <Stack
                                  sx={{
                                    background: "#006FCF",
                                    width: "5.5rem",
                                    textTransform: "none",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      color: "#FFFFFF",
                                      textTransform: "none",
                                    }}
                                    onClick={handleApprove}
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </Stack> */}
                            </Stack>
                          )}
                        </Stack>
                        <Stack sx={{ fontSize: "0.9rem" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            POPs URL
                          </Typography>

                          {isEditPageUrl === false ? (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  color: "#006FCF",
                                  // display: "flex",
                                  // flexDirection: "row",
                                  // alignItems: "center",
                                  gap: "0.2rem",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  whiteSpace: "normal",
                                  width: "410px",
                                }}
                              >
                                {qaData?.page_url === null ||
                                qaData?.page_url === ""
                                  ? "N/A"
                                  : qaData?.page_url}
                              </Typography>
                              <Stack
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <LaunchIcon
                                  onClick={() =>
                                    handleURLClick(qaData?.page_url)
                                  }
                                  sx={{ cursor: "pointer" }}
                                />

                                <EditIcon
                                  sx={{ width: "1.2rem", cursor: "pointer" }}
                                  onClick={() => handleEditPageUrl()}
                                />
                              </Stack>
                            </Stack>
                          ) : (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.6rem",
                              }}
                            >
                              <input
                                value={pageUrl}
                                style={{ height: "1.8rem" }}
                                onChange={(e) => handleChangePageUrl(e)}
                              />
                              {/* <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "0.6rem",

                                  justifyContent: "center",
                                }}
                              >
                                <Stack
                                  sx={{
                                    background: "#EA001B",
                                    width: "5.5rem",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      color: "#FFFFFF",
                                      textTransform: "none",
                                    }}
                                    onClick={() => handleCancelAlternate()}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                                <Stack
                                  sx={{
                                    background: "#006FCF",
                                    width: "5.5rem",
                                    textTransform: "none",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      color: "#FFFFFF",
                                      textTransform: "none",
                                    }}
                                    onClick={handleApprove}
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </Stack> */}
                            </Stack>
                          )}
                        </Stack>

                        <Stack
                          sx={{
                            fontSize: "0.9rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "-0.4rem",
                            gap: "0.8rem",
                          }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.4rem",
                            }}
                          >
                            <Typography
                              sx={{ color: "#989898", fontWeight: "700" }}
                            >
                              Page Type
                            </Typography>

                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                              }}
                            >
                              {!isEditPage ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontWeight: "400",
                                      color: "#006FCF",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: "0.2rem",
                                    }}
                                  >
                                    {qaData?.page_type ?? "N/A"}

                                    <EditIcon
                                      onClick={() => handleEditPage()}
                                      sx={{
                                        width: "1.2rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Typography>
                                </>
                              ) : (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <input
                                    value={pageType}
                                    style={{ height: "1.8rem" }}
                                    onChange={(e) => handleChangePageType(e)}
                                  />
                                  {/* <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <Stack
                                    sx={{
                                      background: "#EA001B",
                                      width: "4rem",
                                      height: "1.5rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        color: "#FFFFFF",
                                        textTransform: "none",
                                        textAlign: "center",
                                      }}
                                      onClick={() => handleCancelPage()}
                                    >
                                      Cancel
                                    </Button>
                                  </Stack>
                                  <Stack
                                    sx={{
                                      background: "#006FCF",
                                      width: "4rem",
                                      textTransform: "none",
                                      height: "1.5rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        color: "#FFFFFF",
                                        textTransform: "none",
                                      }}
                                      onClick={handleApprove}
                                    >
                                      Update
                                    </Button>
                                  </Stack>
                                </Stack> */}
                                </Stack>
                              )}
                              {isEditPage && (
                                <Stack sx={{ width: "6rem", width: "100%" }}>
                                  <select
                                    id="remarks"
                                    name="remarks"
                                    style={{
                                      height: "2rem",
                                      cursor: "pointer",
                                    }}
                                    onChange={handlePageTypeDropdownChange}
                                  >
                                    <option disabled selected id="select">
                                      Select PageType
                                    </option>
                                    {pageTypeList?.map((page, index) => (
                                      <option id={index} value={page}>
                                        {page}
                                      </option>
                                    ))}
                                  </select>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    checked={ecommerce}
                                    onChange={(e) => handleChangeEcommerce(e)}
                                    name="ecommerce"
                                  />
                                }
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Ecommerce
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    checked={fillForm}
                                    onChange={(e) => handleChangeFillForm(e)}
                                    name="without_fill_form"
                                  />
                                }
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Without Fill Form
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Stack sx={{ fontSize: "0.9rem", gap: "2px" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            Executioner's Remark
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "normal",
                              width: "410px",
                            }}
                          >
                            {qaData?.remarks ?? "N/A"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider
                        sx={{ backgroundColor: "#BBBBBB", marginY: "1rem" }}
                      />
                      <FormControl sx={{ display: "flex", gap: "16px" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.8rem",
                          }}
                        >
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                C_POPs Found
                              </Typography>
                              {!isEdit && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                ></Stack>
                              )}
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("amex")
                                )}
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes(
                                    "mastercard"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("visa")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes(
                                    "discover"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("paypal")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>
                           
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("wallet")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Wallets
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                AI_POPs Found
                              </Typography>
                              {!isEdit && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                ></Stack>
                              )}
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("amex")
                                )}
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes(
                                    "mastercard"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("visa")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes(
                                    "discover"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("paypal")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>
                            
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("wallet")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Wallets
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                F_POPs Found
                              </Typography>
                              {!isEdit && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                ></Stack>
                              )}
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "amex"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="amex"
                                  />
                                }
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "mastercard"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="mastercard"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "visa"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="visa"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "discover"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="discover"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "paypal"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="paypal"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>
                            
                            {/* <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "wallet"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="wallet"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Wallets
                              </Typography>
                            </Stack> */}
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          sx={{
                            // alignItems: "center",
                            background: "#FFF",
                            gap: "1rem",
                          }}
                        >
                          <Stack sx={{ width: "100%" }}>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.4rem",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TextField
                                size="small"
                                value={selectedSegment}
                                // onChange={handleSegmentChange}
                                multiline
                                placeholder="Segment"
                                sx={{
                                  width: "100%",
                                  borderRadius: "0",
                                  ".MuiInputBase-root": {
                                    borderRadius: "0",
                                  },
                                }}
                              ></TextField>
                              <select
                                id="remarks"
                                name="remarks"
                                style={{
                                  height: "2.5rem",
                                  width: "10rem",
                                  cursor: "pointer",
                                }}
                                onChange={handleRemarksDropdownChange}
                                // value={selectedSegment}
                              >
                                <option disabled selected id="select">
                                  Select Remarks
                                </option>
                                {remarksList?.map((remark) => (
                                  <option
                                    id={remark?.remark_id}
                                    value={remark?.remark_name}
                                  >
                                    {remark?.remark_name}
                                  </option>
                                ))}
                              </select>
                              {remarks === null && (
                                <p style={{ color: "red" }}>
                                  Please select Remark to move Next
                                </p>
                              )}
                            </Stack>
                          </Stack>
                          {/* <Stack sx={{ width: "8rem" }}>
                            <select
                              id="remarks"
                              name="remarks"
                              style={{ height: "2rem", cursor: "pointer" }}
                              onChange={handleRemarksDropdownChange}
                            >
                              <option disabled selected id="select">
                                Select Remarks
                              </option>
                              {remarksList?.map((remark) => (
                                <option
                                  id={remark?.remark_id}
                                  value={remark?.remark_name}
                                >
                                  {remark?.remark_name}
                                </option>
                              ))}
                            </select>
                          </Stack> */}
                          <TextField
                            value={remarks}
                            onChange={handleRemarksChange}
                            multiline
                            placeholder="Add a Remark"
                            sx={{
                              width: "100%",
                              borderRadius: "0",
                              ".MuiInputBase-root": {
                                borderRadius: "0",
                              },
                            }}
                          ></TextField>
                        </Stack>
                        {!isEdit ? (
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              gap: "0.6rem",
                            }}
                          >
                            <Stack
                              sx={{
                                background: "#006FCF",
                                width: "8.5rem",
                                textTransform: "none",
                              }}
                            >
                              <Button
                                sx={{
                                  color: "#FFFFFF",
                                  textTransform: "none",
                                  background: "#99641a",
                                  "&:hover": { backgroundColor: "#8a5a17" },
                                }}
                                onClick={() => handleNext()}
                              >
                                Next
                              </Button>
                            </Stack>
                            <Stack
                              sx={{
                                background: "#006FCF",
                                width: "8.5rem",
                                textTransform: "none",
                              }}
                            >
                              <Button
                                sx={{
                                  color: "#FFFFFF",
                                  textTransform: "none",
                                  background: "#5f822f",
                                  "&:hover": { backgroundColor: "#56752a" },
                                }}
                                onClick={() => handleQA("qa")}
                              >
                                Move QA
                              </Button>
                            </Stack>
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "0.6rem",
                            }}
                          >
                            <Stack
                              sx={{
                                background: "#FFFFFF",
                                width: "8.5rem",
                                border: "0.02rem solid #006FCF",
                                textTransform: "none",
                              }}
                            >
                              <Button
                                sx={{ color: "#006FCF", textTransform: "none" }}
                                onClick={handleDiscard}
                              >
                                Discard
                              </Button>
                            </Stack>
                            <Stack
                              sx={{
                                background: "#006FCF",
                                width: "8.5rem",
                                textTransform: "none",
                              }}
                            >
                              <Button
                                sx={{ color: "#FFFFFF", textTransform: "none" }}
                                onClick={handleUpdate}
                              >
                                Move QA
                              </Button>
                            </Stack>
                          </Stack>
                        )}
                      </FormControl>
                    </CardContent>
                  </Card>
                </Stack>
              </>
            ))}
          </Box>
        ) : (
          <>
            <Stack
              sx={{
                width: "100%",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#DADADA",
                fontSize: "40px",
                fontWeight: "700",
              }}
            >
              <Stack>
                <Typography sx={{ fontSize: "40px", fontWeight: "700" }}>
                  All Done.{" "}
                </Typography>
                {/* <Typography sx={{ fontSize: "40px", fontWeight: "700" }}>
                  Click Start to begin Again!
                </Typography> */}
              </Stack>

              {/* <Button
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "0px",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "6px 32px",
                  width: "140px",
                  marginTop: "0.5rem",
                }}
                onClick={handleStartAgain}
              >
                Start
              </Button> */}
            </Stack>
          </>
        )}
      </Container>
    </>
  );
};

export default QAForm;
