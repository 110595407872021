import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAccessStatusList } from "../slice/statusSlice";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const useAccessStatusList = () => {
  const [isAccessLoading,setIsAccessLoading]=useState(false)
  console.log(isAccessLoading,"0000000000")
  const Axios = useAxiosPrivate();
  const location = useLocation();
  const role_id = useSelector((state) => state.auth.userData.role_id);
  
  let rowData = location?.state?.rowData;
  const dispatch = useDispatch();

  const getAllAccessStatusList = async () => {
    let param = {
      task_id: rowData?.task_id,
    };
    const response = await Axios.post("/access-status-list",param);
    const data = await response.data;
    console.log(data,"accessStatusList777")
    if(data?.code===200){
      setIsAccessLoading(false)
      dispatch(getAccessStatusList(data.statusAccessList));
    }
  };

  // useEffect(() => {
    // getAllAccessStatusList();
  // }, []);

  return {getAllAccessStatusList,isAccessLoading,setIsAccessLoading}
};

export default useAccessStatusList;
