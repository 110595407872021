import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import HomePage from "./Components/HomePage/HomePage";
import QAPage from "./Components/QAPage/QAL1Page/QAL1Page";
import QAL2Page from "./Components/QAPage/QAL2Page/QAL2Page";
import TaskDetails from "./Components/TaskDetailsPage/TaskDetails";
import MissingRoute from "./utility/MissingRoute";
import Login from "./Components/Login/Login";
import UnAuthorized from "./Components/Unauthorized/Unauthorized";
import PersistLogin from "./hooks/PersistLogin";
import ProtectedRoute from "./utility/ProtectedRoute";
import QACorousel from "./Components/QAPage/QAL2Page/QACorousel";
import RELPage from "./Components/RELPage/RELPage";
import QACOMPage from "./Components/QACOMPage/QACOMPage";
import QARELPage from "./Components/QARELPage/QARELPage";
import QAL2S1Page from "./Components/QAL2S1Page/QAL2S1Page";
import QAL2S2Page from "./Components/QAL2S2Page/QAL2S2Page";
import QAL2S1TempPage from "./Components/QAL2S1TempPage/QAL2S1TempPage";
import QAfNWPage from "./Components/QAfNWPage/QAfNWPage";
import QAfDWHPage from "./Components/QAfDWHPage/QAfDWHPage";
import QADuplicatePage from "./Components/QADuplicatePage/QADuplicatePage";
import ExecutorTable from "./Components/HomePage/ExecutorAdminTable";
import ExecutorDashboardTable from "./Components/HomePage/ExecutorDashboardTable";
import ExecutorAdminTable from "./Components/HomePage/ExecutorAdminTable";
import ExecutorTab from "./Components/HomePage/ExecutorTab";

function App() {
  
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route element={<PersistLogin />}>
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<UnAuthorized />} />
          <Route path="*" element={<MissingRoute />} />

          <Route
            exact
            path="/"
            element={
              <ProtectedRoute
                allowedRoles={["admin", "customer", "executor"]}
              />
            }
          >
            <Route exact path="/" element={<HomePage />} />
          </Route>

          <Route
            exact
            path="/taskdetails"
            element={
              <ProtectedRoute
                allowedRoles={["admin", "customer", "executor"]}
              />
            }
          >
            <Route path="/taskdetails" element={<TaskDetails />} />
          </Route>

          <Route
            exact
            path="/qal1"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/qal1" element={<QAPage />} />
          </Route>

          <Route
            exact
            path="/qal2"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/qal2" element={<QAL2Page />} />
          </Route>

          <Route
            exact
            path="/qacom"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/qacom" element={<QACOMPage />} />
          </Route>

          <Route
            exact
            path="/qarel"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/qarel" element={<QARELPage />} />
          </Route>

          <Route
            exact
            path="/l2S1"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/l2S1" element={<QAL2S1Page />} />
          </Route>

          <Route
            exact
            path="/fNW"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/fNW" element={<QAfNWPage />} />
          </Route>

          <Route
            exact
            path="/fDWH"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/fDWH" element={<QAfDWHPage />} />
          </Route>

          <Route
            exact
            path="/l2S1temp"
            element={<ProtectedRoute allowedRoles={["admin", "executor2"]} />}
          >
            <Route path="/l2S1temp" element={<QAL2S1TempPage />} />
          </Route>

          <Route
            exact
            path="/l2S2"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/l2S2" element={<QAL2S2Page />} />
          </Route>

          <Route
            exact
            path="/dupPage"
            element={<ProtectedRoute allowedRoles={["admin", "executor"]} />}
          >
            <Route path="/dupPage" element={<QADuplicatePage />} />
          </Route>

          <Route
            exact
            path="/relPage"
            element={<ProtectedRoute allowedRoles={["customer"]} />}
          >
            <Route path="/relPage" element={<RELPage />} />
          </Route>

          <Route exact path="/corousel" element={<QACorousel />} />
          <Route exact path="/executor-tab" element={<ExecutorTab />} />
          <Route exact path="/executor-dashboard" element={<ExecutorDashboardTable />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
