import { useEffect } from "react";
import { getTaskCheetSheetDetails, getTaskDetailsList } from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
const useTaskCheetSheetDetails = () => {
  const current_task_id = localStorage.getItem("task_id");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllTaskCheetSheetDetails = async () => {
    let param = { task_id: current_task_id };
    const response = await Axios.post("/task-cheet-sheet-details", param);
    const data = await response.data;
    dispatch(getTaskCheetSheetDetails(data.taskCheatSheetDetail));
  };

  useEffect(() => {
    getAllTaskCheetSheetDetails();
  }, [current_task_id]);
  return getAllTaskCheetSheetDetails;
};

export default useTaskCheetSheetDetails;
