import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDuplicateList, getQAList } from "../slice/QASlice";

const useDuplicateList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const getAllDuplicateList = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/url-duplicate-list", param);
    const data = await response.data;
    dispatch(getDuplicateList(data.duplicatelist));
  };
  useEffect(() => {
    getAllDuplicateList();
  }, [current_task_id]);
  return getAllDuplicateList;
};

export default useDuplicateList;
