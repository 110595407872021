import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAccessStatusList } from "../slice/statusSlice";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTotalLevel1QAStatusCount, getTotalQAStatusCount } from "../slice/QASlice";

const useTotalLevel1QAStatusCount = () => {
  const Axios = useAxiosPrivate();
  const location = useLocation();
  const role_id = useSelector((state) => state.auth.userData.role_id);
  
  let rowData = location?.state?.rowData;
  console.log(rowData)
  const current_task_id = localStorage.getItem("task_id");
  const dispatch = useDispatch();

  const getAllTotalLevel1QAStatusCount = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/total-level1-qa-status-count",param);
    const data = await response.data;
    dispatch(getTotalLevel1QAStatusCount(data.totalQAStatusCount));
  };

  // useEffect(() => {
  //   getAllTotalLevel1QAStatusCount();
  // }, []);
  return getAllTotalLevel1QAStatusCount;
};

export default useTotalLevel1QAStatusCount;
