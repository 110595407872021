import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useMoveTofREL = (checked, remarks, type) => {
  const Axios = useAxiosPrivate();
  const current_task_id = localStorage.getItem("task_id");
  const moveTofREL = async () => {
    let param = {
      task_id: current_task_id,
    };

    const response = await Axios.post("/insert-to-frel-status", param);
    const data = await response.data;
    console.log(data, "akdjfdjsfldf");
    if (data.code === 200) {
      toast.success(data?.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data?.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return moveTofREL;
};

export default useMoveTofREL;
