import { useEffect } from "react";
import { getExcelSheetData } from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
const useExcelSheetData = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  let current_task_id=localStorage.getItem("task_id")
  const getAllExcelSheetData = async () => {
   let param={
    task_id:current_task_id
   }
    const response = await Axios.post("/get-excel-sheet-data",param);
    const data = await response.data;
    dispatch(getExcelSheetData(data.excelSheetData));
  };

  useEffect(() => {
    getAllExcelSheetData();
  }, []);
  return getAllExcelSheetData
};

export default useExcelSheetData;
