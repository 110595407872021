import "./style/QACorousel.scss";
import Carousel from "react-material-ui-carousel";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import ScrollContainer from 'react-indiana-drag-scroll'

export default function QACorousel2(imageUrl) {
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level is 100%

  const handleZoomIn = () => {
    setZoomLevel(Math.min(zoomLevel + 10, 4000)); // Increase zoom level by 10%, up to 200%
  };

  const handleZoomOut = () => {
    setZoomLevel(Math.max(zoomLevel - 10, 80)); // Decrease zoom level by 10%, down to 100%
  };


  const imagecontainer = useRef()
  const [zoomamount, setZoomamount]=useState(1)
  function zoom(el){
   if(el.type==='in'){
    setZoomamount(prev=>prev+ 0.1);
   }else {
    if(zoomamount>1){
      setZoomamount(prev=>prev- 0.1);
    }
   }
  }


  return (
    <div
      className="Example3"
      style={{ color: "#494949", position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "40px",
          left: "95%",
          transform: "translateX(-50%)",
          zIndex: "100",
        }}
      >
        <ZoomOutIcon
          style={{
            fontSize: "2.5rem",
            cursor: "pointer",
            color: "gray",
            marginRight: "10px",
          }}
          onClick={()=>zoom({type: 'out'})}
        />
        <ZoomInIcon
          style={{ fontSize: "2.5rem", cursor: "pointer", color: "gray" }}
          onClick={()=>zoom({type: 'in'})}
        />
      </div>
      {/* <Carousel
        animation="fade"
        navButtonsAlwaysVisible
        autoPlay={false}
        style={{ width: "100%", height: "100%" }}
      > */}
        <div
          // key={`test3-item-${i}`}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* <Draggable>
            <img
              src={imageUrl?.qaData}
              // alt={`Image ${i + 1}`}
              style={{ width: "100%", height: "100%" }}
            />
          </Draggable> */}
          <div
          className="overflowimgcontainer"
          style={{ height: "100vh", width: "100%", overflow: "scroll" }}
        >
          <ScrollContainer
            className="grabbercontainer"
            style={{ cursor: "pointer", width: "100%", height: "100%" }}
            hideScrollbars={true}
          >
            <img
              style={{
                width: 100 * zoomamount + "%",
                height: 100 * zoomamount + "%",
                //  objectFit:"cover",
                position: "relative",
              }}
              src={imageUrl?.qaData}
              alt=""
            />
          </ScrollContainer>
        </div>
      
        </div>
      {/* </Carousel> */}
    </div>
  );
}
