import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "./useLocalStorage";
import useRefreshToken from './useRefreshToken';

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const [persist] = useLocalStorage("persist", false);
    const store = useSelector((state) => state.auth);
  
    useEffect(() => {
      let isMounted = true;
       
      const verifyRefreshToken = async () => {
        try {
          await refresh();
        } catch (err) {
          console.error(err);
        } finally {
          isMounted && setIsLoading(false);
        }
      };
      // Avoids unwanted call to verifyRefreshToken
      if (!store?.token && persist) {
        verifyRefreshToken();
      } else {
        setIsLoading(false);
        // navigate('/Login')
      }  
      return () => (isMounted = false);
    }, []);
  
    useEffect(() => {
      console.log(`isLoading: ${isLoading}`);
      console.log(`aT: ${JSON.stringify(store?.accessToken)}`);
    }, [isLoading]);
  
    // console.log(!persist ? "<Outlet />11" : isLoading ? "<p>Loading...</p>" : "<Outlet />");
    // console.log(Location)
    return (
      <>{!persist ? <Outlet /> : isLoading ? <p>Loading...</p> : <Outlet />}</>
    );
  };

export default PersistLogin;