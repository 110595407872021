import axios from "axios";
import { baseUrl } from "../utility/baseURLs";

export const Authaxios = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
});

export const AxiosPrivate = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
});

export const Imageaxios = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "multipart/form-data",
  },
});
