import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useL2StatusUpdate = () => {
  //Redux
  const allL2List = useSelector((state) => state.qa.l2S1List);
  const allL2S2List = useSelector((state) => state.qa.l2S2List);
  const allL2ListTemp = useSelector((state) => state.qa.l2S1ListTemp);
  const allfDWHList = useSelector((state) => state.qa.fDWHList);
  const allfNWList = useSelector((state) => state.qa.fNWList);
  const allDuplicateList = useSelector((state) => state.qa.duplicateDetails);
  const Axios = useAxiosPrivate();
  const l2StatusUpdate = async (type) => {
    let param = {
      url_id:
        type === 1
          ? allL2List?.[0]?.url_id
          : type === 5
          ? allL2S2List?.[0]?.url_id
          : type === 6
          ? allfDWHList?.[0]?.url_id
          : type === 7
          ? allfNWList?.[0]?.url_id
          : type === 8
          ? allDuplicateList?.[0]?.url_id
          : allL2ListTemp?.[0]?.url_id,
      status_id:
        type === 1
          ? allL2List?.[0]?.status_id
          : type === 5
          ? allL2S2List?.[0]?.status_id
          : type === 6
          ? allfDWHList?.[0]?.status_id
          : type === 7
          ? allfNWList?.[0]?.status_id
          : type === 8
          ? allDuplicateList?.[0]?.status_id
          : allL2ListTemp?.[0]?.status_id,
    };
    const response = await Axios.post("/url-l2-status-update", param);
    const data = await response.data;
    // if (data.code === 200) {
    //   toast.success(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    //   toast.error(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  return l2StatusUpdate;
};

export default useL2StatusUpdate;
