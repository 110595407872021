import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
  name: "task",
  initialState: {
    taskDetailsList: null,
    URLDetails: null,
    URLDetailsStatus: null,
    currentTaskDetails: null,
    urlPopResultStages: null,
    urlCardPercentage: null,
    taskCheetSheetDetails:null,
    level1CheckData:null,
    excelSheetData:null,
    executorDashboardReport:null
  },
  reducers: {
    getTaskDetailsList: (state, action) => {
      state.taskDetailsList = action.payload;
    },
    getTaskCheetSheetDetails: (state, action) => {
      state.taskCheetSheetDetails = action.payload;
    },
    getURLDetails: (state, action) => {
      state.URLDetails = action.payload;
    },
    getExecutorDashboardReport: (state, action) => {
      state.executorDashboardReport = action.payload;
    },
    getURLDetailsStatus: (state, action) => {
      state.URLDetailsStatus = action.payload;
    },
    getCurrentTaskDetails: (state, action) => {
      state.currentTaskDetails = action.payload;
    },
    getURLPopResultStages: (state, action) => {
      state.urlPopResultStages = action.payload;
    },
    getURLCardPercentage: (state, action) => {
      state.urlCardPercentage = action.payload;
    },
    getLevel1CheckData: (state, action) => {
      state.level1CheckData = action.payload;
    },
    getExcelSheetData: (state, action) => {
      state.excelSheetData = action.payload;
    },
  },
});

export default taskSlice.reducer;
export const {
  getTaskDetailsList,
  getURLDetails,
  getURLDetailsStatus,
  getCurrentTaskDetails,
  getURLPopResultStages,
  getURLCardPercentage,
  getTaskCheetSheetDetails,
  getLevel1CheckData,
  getExcelSheetData,
  getExecutorDashboardReport
} = taskSlice.actions;
