import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUrlId, getSelectedCardData, setshowQaData } from "../../slice/QASlice";
import { showQaData } from "../../slice/QASlice";
import useQAAssignedUpdate from "../../hooks/useQAAssignedUpdate";
import useQAAccessStatusList from "../../hooks/useQAAccessStatusList";
import useQAList from "../../hooks/useQAList";
import { useLocation } from "react-router-dom";
import Timer from "../Timer";
import useSearchList from "../../hooks/useSearchList";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useSplitUrl from "../../hooks/useSplitUrl";

const QAL2Header = ({ timeLeft, queryURL, setQueryURL }) => {
  const dispatch = useDispatch();
  const showData = useSelector(showQaData);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [totalUrlQA, setTotalUrlQA] = useState(0);

  //Redux
  const qaAccessStatusList = useSelector(
    (state) => state?.status?.qaAccessStatusList
  );
  const allL2S1List = useSelector((state) => state.qa.l2S1List);
  const current_task_id = localStorage.getItem("task_id");
  //Api calls
  const qaAssignedUpdate = useQAAssignedUpdate();
  useQAAccessStatusList();

  // useEffect(() => {
  //   getAllQAList();
  // }, [selectedCardData?.status_id]);

  const handleCardClick = (index, statusId) => {
    console.log(statusId, "statusIdkfajfjdsaf");
    setSelectedCardIndex(index);

    let statusName = "";
    let taskId = 0;
    for (let i = 0; i < qaAccessStatusList?.length; i++) {
      if (statusId === qaAccessStatusList[i].status_id) {
        statusName = qaAccessStatusList[i]?.status_name;
      }
    }
    setSelectedCardData((prev) => ({
      ...prev,
      status_id: statusId,
      status_name: statusName,
      task_id: current_task_id,
    }));

    dispatch(getSelectedCardData(statusId));
  };

  const handleButtonClick = (value, buttonValue) => {
    if (buttonValue === "start") {
      dispatch(setshowQaData(value));
    } else if (buttonValue === "stop") {
      dispatch(setshowQaData(value));
    } else {
      dispatch(setshowQaData(value));
    }
  };

  const getAllSearchList = useSearchList();
  const handleInput = (e) => {
    setQueryURL(e.target.value);
  };
  const handleSearch = () => {
    getAllSearchList(queryURL);
    dispatch(getSearchUrlId(parseInt(queryURL)));
  };

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < qaAccessStatusList?.length; i++) {
      count += parseInt(qaAccessStatusList?.[i]?.status_count);
    }
    setTotalUrlQA(count);
  }, [qaAccessStatusList]);

  const [open, setOpen] = React.useState(false);
  const getSplitUrl=useSplitUrl()
  const handleSplitUrl=async()=>{
    setOpen(true);
  }

  const handleConfirmSplitUrl = async() => {
    await getSplitUrl(queryURL)
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <Container
      maxWidth="2xl"
      sx={{ marginTop: "0.8rem", paddingBottom: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
          gap: "1rem",
        }}
      >
        <Stack
          direction={{ xs: "row", sm: "column" }}
          sx={{
            justifyContent: { xs: "flex-start", sm: "center" },
            alignItems: { xs: "center", sm: "flex-start" },
            gap: { xs: "0.5rem", sm: "0rem" },
            width:"100%"
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.8rem",
              alignItems: "center",
              justifyContent:"space-between",
              width:"100%"
            }}
          >
            <Stack sx={{display:"flex",flexDirection:"row",alignItems:"center",gap:"0.8rem"}}>
            <Typography
              sx={{
                fontWeight: "700",
                color: "#000",
                fontSize: "1.5rem",
                justifyContent: "center",
              }}
            >
              F DWH
            </Typography>
            {allL2S1List?.length > 0 && <Timer timeLeft={timeLeft} />}
            <input
              // type="number"
              style={{ height: "2rem" }}
              onChange={handleInput}
              value={queryURL}
              placeholder="S.No."
            />
            <button
              onClick={handleSearch}
              style={{ width: "5rem", height: "2rem", cursor: "pointer" }}
            >
              Search
            </button>
            </Stack>
            <Button
          sx={{
            background: "#4287f5",
            color: "white",
            padding: "0.5rem",
            "&:hover": { background: "#4287f5" },
          }}
          onClick={handleSplitUrl}
        >
          Split this Url
        </Button>
        <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Split URL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a new URL in S1 bucket, If you want to split this URL Click confirm button.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSplitUrl} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
          </Stack>
          {/* <Typography
            sx={{
              color: "#6B6A6A",
              fontSize: "1rem",
              fontWeight: "400",
              justifyContent: "center",
            }}
          >
            Total URLs in F-REL: {totalUrlQA}
          </Typography> */}
        </Stack>

        {/* <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "0.5rem", sm: "0.5rem", md: "1.5rem" },
            alignItems: { xs: "flex-start", sm: "flex-start", lg: "center" },
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              lg: "center",
            },
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: "#A2A2A2",
                fontSize: "0.8rem",
                fontWeight: "500",
                marginBottom: "6px",
              }}
            >
              Page Found
            </Typography>

            <Stack sx={{ flexDirection: "row", gap: "0.6rem" }}>
              {qaAccessStatusList.map((qaStatus, index) => {
                return (
                  qaStatus.qa_status_result == "Found" && (
                    <Stack
                      sx={{
                        border: "0.013rem solid #006FCF",
                        background: "#F0F6FC",
                        width: "6rem",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.3rem",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCardClick(index, qaStatus?.status_id)
                      }
                    >
                      <Typography
                        sx={{
                          color:
                            selectedCardIndex === index ? "#006FCF" : "#A2A2A2",
                          fontSize: "0.8rem",
                          fontWeight: "700",
                        }}
                      >
                        {qaStatus?.status_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          fontWeight: "700",
                        }}
                      >
                        {qaStatus?.status_count}
                      </Typography>
                      {selectedCardIndex === index && (
                        <CheckCircleIcon
                          fontSize="small"
                          sx={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            borderRadius: "50%",
                            color: "#329519",
                          }}
                        />
                      )}
                    </Stack>
                  )
                );
              })}
            </Stack>
          </Stack>

          <Divider
            sx={{
              height: "100%",
              width: "0.5px",
              backgroundColor: "black",
              marginY: "1rem",
              alignItems: "center",
              alignSelf: "center",
            }}
          />

          <Stack>
            <Typography
              sx={{
                color: "#A2A2A2",
                fontSize: "0.8rem",
                fontWeight: "500",
                marginBottom: "6px",
              }}
            >
              Page Not Found
            </Typography>
            <Stack
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "1rem", sm: "0.6rem" },
                alignItems: { xs: "flex-start", sm: "center" },
              }}
            >
              <Stack sx={{ flexDirection: "row", gap: "0.8rem" }}>
                {qaAccessStatusList.map((qaStatus, index) => {
                  return (
                    qaStatus.qa_status_result == "Not Found" && (
                      <Stack
                        sx={{
                          border: "0.013rem solid #006FCF",
                          background: "#F0F6FC",
                          width: "7rem",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0.3rem",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleCardClick(index, qaStatus?.status_id)
                        }
                      >
                        <Typography
                          sx={{
                            color:
                              selectedCardIndex === index
                                ? "#006FCF"
                                : "#A2A2A2",
                            fontSize: "0.8rem",
                            fontWeight: "700",
                          }}
                        >
                          {qaStatus?.status_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "0.875rem",
                            textAlign: "center",
                            fontWeight: "700",
                          }}
                        >
                          {qaStatus?.status_count}
                        </Typography>
                        {selectedCardIndex === index && (
                          <CheckCircleIcon
                            fontSize="small"
                            sx={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                              borderRadius: "50%",
                              color: "#329519",
                            }}
                          />
                        )}
                      </Stack>
                    )
                  );
                })}
              </Stack>
              <Stack>
                <Stack
                  sx={{
                    width: "7rem",

                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: "2.5rem",
                    marginLeft: "18px",
                  }}
                >
                  {showData.showQaData ? (
                    <Button
                      onClick={() => handleButtonClick(false, "stop")}
                      variant="outlined"
                      color="error"
                      sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "6px 32px",
                        width: "120px",
                        borderRadius: "0px",
                      }}
                    >
                      Stop
                    </Button>
                  ) : qaAccessStatusList[selectedCardIndex]?.access ? (
                    <Button
                      onClick={() => handleButtonClick(true, "start")}
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "none",
                        backgroundColor: "#1976d2",
                        borderRadius: "0px",
                        "&:hover": { backgroundColor: "#1565c0" },
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "6px 32px",
                        width: "120px",
                      }}
                    >
                      Start
                    </Button>
                  ) : (
                    <Button
                      disabled="true"
                      onClick={() => handleButtonClick(true, "start")}
                      disableElevation
                      variant="contained"
                      sx={{
                        textTransform: "none",

                        borderRadius: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "6px 32px",
                        width: "120px",
                      }}
                    >
                      Start
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack> */}
      </Box>
    </Container>
  );
};

export default QAL2Header;
