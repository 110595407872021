import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const ProtectedRoute = ({ element, allowedRoles, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  // const userRole = useSelector((state) => state?.auth?.userData?.role_code);
  const userRole = localStorage.getItem("role_code");
  // console.log(userRole, "userRole");
  useEffect(() => {
    const delay = 2000; // Set the delay time in milliseconds

    // Simulate a delay with setTimeout
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    // Clear the timeout if the component unmounts or the Redux data updates
    return () => clearTimeout(timeoutId);
  }, [userRole]);

  // If still loading, you can show a loading indicator or a different component
  if (isLoading) {
    return <div><Box sx={{ display: 'flex', position:"absolute", top:"50%", left:"50%" }}>
    <CircularProgress />
  </Box></div>;
  }

  // Check if the user has any of the allowed roles
  const hasAccess = Array.isArray(allowedRoles)
    ? allowedRoles.includes(userRole)
    : userRole === allowedRoles;

  // If the user has access, render the component, otherwise navigate to unauthorized page
  return hasAccess ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;