import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getComList,
  getfDWHList,
  getfNWList,
  getL2S1List,
  getL2S2List,
  getQAList,
  getRELList,
  getrelList,
  getSearchList,
} from "../slice/QASlice";

const useSearchList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const current_status_id = useSelector((state) => state.qa.selectedCardData);
  const userData = useSelector((state) => state.auth.userData);
  // const roleId = useSelector((state) => state.auth.userData.role_id);
  const allRELList = useSelector((state) => state.qa.relList);
  const getAllSearchList = async (urlId) => {
    let param = {
      status_id: userData?.role_id === 2 ? 21 : parseInt(current_status_id),
      task_id: parseInt(current_task_id),
      // url_id: parseInt(urlId),
      is_active: "true",
      sno: urlId,
    };

    const response = await Axios.post("/url-search-id", param);
    const data = await response.data;
    // dispatch(getSearchList(data.urlDetails));
    if (userData?.role_id === 2) {
      dispatch(getRELList(data));
    } else if (current_status_id === 21) {
      dispatch(getrelList(data?.urlDetails));
    } else if (current_status_id === 3) {
      dispatch(getL2S1List(data?.urlDetails));
    } else if (current_status_id === 4) {
      dispatch(getL2S2List(data?.urlDetails));
    } else if (current_status_id === 10) {
      dispatch(getQAList(data?.urlDetails));
    } else if (current_status_id === 19) {
      dispatch(getfNWList(data?.urlDetails));
    } else if (current_status_id === 7) {
      dispatch(getfDWHList(data?.urlDetails));
    } else if (current_status_id === 20) {
      dispatch(getComList(data?.urlDetails));
    }
  };
  return getAllSearchList;
};

export default useSearchList;
