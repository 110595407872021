import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useSelector } from "react-redux";

Chart.register(...registerables);
// Sample data for the scatter chart



const ChartAnalysis = () => {
  const cardPercentage = useSelector((state) => state.task.urlCardPercentage);

  const data = {
    datasets: [
      {
        data: [{ x: cardPercentage?.[0]?.percentage, y: 0, label: "Visa" }],
        backgroundColor: "#012544",
      },
      {
        data: [{ x: cardPercentage?.[1]?.percentage, y: 0, label: "Amex" }],
        backgroundColor: "#D84CCA",
      },
      {
        data: [{ x: cardPercentage?.[2]?.percentage, y: 0, label: "Master Card" }],
        backgroundColor: "#218B18",
      },
      {
        data: [{ x: cardPercentage?.[3]?.percentage, y: 0, label: "Wallet" }],
        backgroundColor: "#EA001B",
      },
      {
        data: [{ x: cardPercentage?.[4]?.percentage, y: 0, label: "Paypal" }],
        backgroundColor: "#F79F1A",
      },
      {
        data: [{ x: cardPercentage?.[5]?.percentage , y: 0, label: "Discover" }],
        backgroundColor: "#006FCF",
      },
    ],
  };


  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        intersect: false, // Ensure tooltips are always visible
        position: "average",
        callbacks: {
          label: (context) => {
            const dataPoint =
              data.datasets[context.datasetIndex].data[context.dataIndex];
            return `${dataPoint.label}: (${dataPoint.x})`;
          },
        },
      },
    },
    interaction: {
      intersect: false, // Ensure tooltips are always visible
    },
  };
  return (
    <div style={{ width: "100%", height: "100px", marginBottom: "20px" }}>
      <Scatter data={data} options={options} />
    </div>
  );
};

export default ChartAnalysis;
