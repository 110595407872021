import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router'


const UnAuthorized = () => {
  const navigate = useNavigate()
  return (
  <Box sx={{alignItems:"center", display:"flex", flexDirection:"column", width:"100%"}}>
    <h3>UnAuthorized Access</h3>
    <h3>This Page is not Publically Available</h3>
    <Button variant="contained" onClick={()=>navigate('/')}>Return to Home</Button>
    </Box>
  )
}
export default UnAuthorized