import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getComList,
  getCurrentCount,
  getFilterCount,
  getrelList,
  getRELList,
} from "../slice/QASlice";
import { useEffect } from "react";

const useRELAdminExecutorList = () => {
  const current_task_id = localStorage.getItem("task_id");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const allRELList = useSelector((state) => state.qa.relDetails);
  const searchURLId = useSelector((state) => state.qa.searchUrlId);
  const getAllrelList = async (segment) => {
    console.log("getAllRelList called");

    let param = {
      task_id: current_task_id,
      url_id: allRELList?.length == 0 ? null : allRELList?.[0]?.url_id,
      // url_id: searchURLId === null ? allRELList?.[0]?.url_id : searchURLId,
      segment: segment ? segment : null,
    };
    const response = await Axios.post("/get-rel-Details", param);

    const data = await response.data;

    dispatch(getrelList(data?.relDetailsList));
    dispatch(getFilterCount(data?.filterCount))
  };

  // useEffect(() => {
  //   getAllrelList();
  // }, [current_task_id]);

  return getAllrelList;
};

export default useRELAdminExecutorList;
