import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getQAList } from "../slice/QASlice";
import { getLevel1CheckData } from "../slice/taskSlice";

const useLevel1Check = () => {
  const processId = useSelector((state) => state.task.level1CheckData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getLevel1Check = async () => {
    const response = await Axios.post("/check-level-1-process");
    const data = await response.data;
    dispatch(getLevel1CheckData(data.data));
  };
  useEffect(() => {
    getLevel1Check();
  },[]);
  return getLevel1Check;
};

export default useLevel1Check;
