import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getL2S1ListTemp } from "../slice/QASlice";

const useL2S1TempList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const allL2S1List = useSelector((state) => state.qa.l2S1ListTemp);
  const userDetails=useSelector((state)=>state.auth.userData)
  const getAllL2S1TempList = async () => {
    let param = {
      task_id: userDetails?.task_id,
      url_id: allL2S1List?.length === 0 ? null : allL2S1List?.[0]?.url_id,
    };

    console.log(param,"11111111111111111111")
    const response = await Axios.post("/get-l2S1-list-temp", param);
    const data = await response.data;
    dispatch(getL2S1ListTemp(data.l2S1DetailsListTemp));
  };
  useEffect(() => {
    getAllL2S1TempList();
  }, [userDetails?.task_id]);
  return getAllL2S1TempList;
};

export default useL2S1TempList;
