import React from 'react';
import { Dialog, CircularProgress,Typography } from '@mui/material';


const PopUpWithLoader = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '20px',display:"flex",flexDirection:"column",alignItems:"center" }}>
        <CircularProgress />
        <div style={{ marginTop: '20px' }}>
         <Typography sx={{fontSize:"30px"}}>Please Wait....</Typography>
          <Typography>Level 2 Software Starting....</Typography>
        </div>
       </div>
     </Dialog>
  );
};

export default PopUpWithLoader;
