import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ITEMS_PER_PAGE = 10;
export default function BasicPagination({
  currentPage,
  urlDetails,
  setCurrentPage,
}) {
  return (
    <Stack spacing={2}>
      <Pagination
        count={Math.ceil(urlDetails?.length / ITEMS_PER_PAGE)}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}
