import React, { useState } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedQAAltCardData,
  setshowQaData,
} from "../../../slice/QASlice";
import { showQaData } from "../../../slice/QASlice";
import useTotalLevel1QAStatusCount from "../../../hooks/useTotalLevel1QAStatusCount";
import useTotalLevel1QAAltStatusCount from "../../../hooks/useTotalLevel1QAAltStatusCount";
import useQAAltAccessStatusList from "../../../hooks/useQAAltAccessStatusList";
const QAHeader = () => {
  const dispatch = useDispatch();
  const showData = useSelector(showQaData);
  const getAllTotalLevel1QAStatusCount = useTotalLevel1QAStatusCount();
  const getAllTotalLevel1QAAltStatusCount = useTotalLevel1QAAltStatusCount();
  const totalLevel1QACount = useSelector(
    (state) => state.qa.totalLevel1QAStatusCount
  );
  const totalLevel1QAAltCount = useSelector(
    (state) => state.qa.totalLevel1QAAltStatusCount
  );
  const qaAltAccess = useSelector((state) => state.qa.qaAltAccessStatusList);
  const handleButtonClick = (value) => {
    // Dispatch the action to update the value in Redux
    dispatch(setshowQaData(value));
  };

  useQAAltAccessStatusList();

  const L1QAStatusList = [
    {
      status_id: 0,
      status_name: "Page Found",
      status_count: totalLevel1QACount?.[0]?.total_qa_status,
      access: true,
    },
    {
      status_id: 1,
      status_name: "f-L1-ALT-QA",
      status_count: totalLevel1QAAltCount?.[0]?.qal1altcount,
      access: qaAltAccess?.[0]?.access,
    },
  ];
  const [currentSelectedCard, setCurrentSelectedCard] = useState(false);
  const handleSelected = (value) => {
    setCurrentSelectedCard(value);
    dispatch(getSelectedQAAltCardData(value));
  };

  return (
    <Container
      maxWidth="2xl"
      sx={{ marginTop: "0.8rem", paddingBottom: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "0.8rem", sm: "0rem" },
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "column" }}
          sx={{
            justifyContent: "center",
            alignItems: { xs: "flex-start", sm: "flex-start" },
            gap: { xs: "0.1rem", sm: "0rem" },
            display: "flex",
            // flexDirection:"column"
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              color: "#000",
              fontSize: "24px",
              justifyContent: "center",
            }}
          >
            F L1 QA
          </Typography>
          <Typography
            sx={{
              color: "#6B6A6A",
              fontSize: "1rem",
              fontWeight: "400",
              justifyContent: "center",
            }}
          >
            Total URLs in QA:{" "}
            {parseInt(totalLevel1QACount?.[0]?.total_qa_status) +
              parseInt(totalLevel1QAAltCount?.[0]?.qal1altcount) ?? 0}
          </Typography>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.5rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {L1QAStatusList?.map((status, index) => (
            <Stack
              key={status?.status_id}
              sx={{
                border: "0.013rem solid #006FCF",
                background: "#F0F6FC",
                width: "6rem",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.3rem",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => handleSelected(index)}
            >
              <Typography
                sx={{ color: "#006FCF", fontSize: "0.8rem", fontWeight: "700" }}
              >
                {status.status_name}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "0.875rem",
                  textAlign: "center",
                  fontWeight: "700",
                }}
              >
                {status?.status_count}
              </Typography>
              {currentSelectedCard === index && (
                <CheckCircleIcon
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    borderRadius: "50%",
                    color: "#329519",
                  }}
                />
              )}
            </Stack>
          ))}

          <Stack
            sx={{
              //   padding: "8px 24px",
              alignItems: "center",
              justifyContent: "center",
              height: "2.5rem",
            }}
          >
            {showData.showQaData ? (
              <Button
                onClick={() => handleButtonClick(false)}
                variant="outlined"
                color="error"
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "6px 32px",
                  width: "120px",
                  borderRadius: "0px",
                }}
              >
                Stop
              </Button>
            ) : L1QAStatusList[currentSelectedCard]?.access ? (
              <Button
                onClick={() => handleButtonClick(true)}
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  backgroundColor: "#1976d2",
                  borderRadius: "0px",
                  "&:hover": { backgroundColor: "#1565c0" },
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "6px 32px",
                  width: "120px",
                }}
              >
                Start
              </Button>
            ) : (
              <Button
                disabled="true"
                onClick={() => handleButtonClick(true, "start")}
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",

                  borderRadius: "0px",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "6px 32px",
                  width: "120px",
                }}
              >
                Start
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default QAHeader;
