import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { baseUrl } from '../utility/baseURLs';
import {  useNavigate } from 'react-router';
import { loginSuccess, logout } from '../slice/LoginSlice';

const useRefreshToken = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const GETTOKEN = localStorage.getItem('refresh_token')
  const Axios = axios.create({
    baseURL: baseUrl,
    headers: {
      "Authorization": `Bearer ${GETTOKEN}`
    }
  })
  
  const refresh = async () => {
    try {
      const { data } = await Axios.get('/getNewAccessToken');
      // dispatch({ type: LOGINDATA, payload: data })
      dispatch(loginSuccess(data));
      return data?.access_token
    } catch (error) {
        console.log("error response", error)
        navigate('/Login')
    }
  }
  useEffect(() => {
    // Call the refresh function when the component mounts
    refresh();
  }, []);
  return refresh
}

export default useRefreshToken