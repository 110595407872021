import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useTaskCheetSheetDetails from "../../hooks/useTaskCheetSheetDetails";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import useURLDetails from "../../hooks/useURLDetails";

function ExportUrl() {
  //Redux
  const data = useSelector((state) => state?.task?.excelSheetData);
  //Api Calls
  const { isLoading, SetIsLoading, getAllURLDetails } = useURLDetails();

  const headers = [
    "SNo.",
    "Name",
    "Market Code",
    "Url",
    "Alternative URL",
    "URL Where Logo/POP Found",
    "Audit Date",
    "Type of Page",
    "Ecommerce Indicator",
    "Amex",
    "Mastercard",
    "Visa",
    "Discover",
    "Wallet Indicator",
    "Paypal (if Wallet Indicator = Y)",
    "Segment",
    "Comment",
  ];

  const formattedData = data?.map((item) => ({
    ...item,
  }));

  // const exportToExcel = () => {
  //   const wb = XLSX.utils.book_new();
  //   const wsData = [headers, ...formattedData.map(item => Object.values(item))];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   XLSX.writeFile(wb, "output.xlsx");
  // };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const batchSize = 500; // Adjust batch size as needed
    const totalBatches = Math.ceil(formattedData?.length / batchSize);

    // Initialize worksheet with headers
    const wsData = [headers];

    // Process data in batches
    for (let batchNum = 0; batchNum < totalBatches; batchNum++) {
      const start = batchNum * batchSize;
      const end = start + batchSize;
      const batchData = formattedData
        .slice(start, end)
        .map((item) => Object.values(item));
      wsData.push(...batchData);
    }

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "output.xlsx");
  };

  return (
    <div>
      <Button
        sx={{ textTransform: "none", borderRadius: "0" }}
        disableElevation
        variant="contained"
        onClick={exportToExcel}
      >
        Export
      </Button>
    </div>
  );
}

export default ExportUrl;
