import { createSlice } from "@reduxjs/toolkit";

const countryList = createSlice({
    name:'country',
    initialState:{
        showContryList: null,
    },
    reducers: {
        setshowCountryList: (state, action) =>{
            state.showContryList = action.payload.country_list
        },
    },
});

export const {setshowCountryList} = countryList.actions;
export const showCountry = (state) => state.country;

export default countryList.reducer;