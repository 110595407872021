import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useQANextUpdate = (
  type,
  remarks,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl,
  checked,
  imageUrl,
  selectedSegment
) => {
 
  //Redux
  const allQAList = useSelector((state) => state.qa.qaList);
  const allCOMList = useSelector((state) => state.qa.comList);
  const Axios = useAxiosPrivate();
  const selectedCardTypes = Object?.keys(checked)?.filter((key) => checked[key]);
  const qaNextUpdate = async () => {
    let available_cards = [];
    if (Array.isArray(selectedCardTypes)) {
      // If selectedCardTypes is already an array, assign it to available_cards
      available_cards = selectedCardTypes;
    } else if (selectedCardTypes !== undefined && selectedCardTypes !== null) {
      // If selectedCardTypes is not an array, convert it to an array
      available_cards = [selectedCardTypes];
    }
    let param = {
      url_status_id:
        type === 1
          ? allQAList?.[0]?.url_status_id
          : allCOMList?.[0]?.url_status_id,
      remarks: remarks,
      f_cards_available: available_cards,
      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
      img_url: imageUrl,
      level: 2,
      segment: selectedSegment,
    };
    const response = await Axios.post("/update-next", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return qaNextUpdate;
};

export default useQANextUpdate;
