import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  FormControl,
  Checkbox,
  TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Modal, Backdrop, Fade, styled } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QACorousel from "./QACorousel";
import LaunchIcon from "@mui/icons-material/Launch";
import useRELStatusUpdate from "../../hooks/useRELStatusUpdate";
import useRelList from "../../hooks/useRelList";
import useRemarksList from "../../hooks/useRemarksList";
const QAForm = ({ setQueryURL }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [open, setOpen] = useState(false);
  const [cardAvailableArray, setCardAvailableArray] = useState(null);
  const [ecommerce, setEcommerce] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState("");

  //Redux
  const relList = useSelector((state) => state.qa.relList?.relDetailsList);
  const currentCount = useSelector((state) => state.qa.currentCount);
  const relCount = useSelector((state) => state.qa.relCount);
  const segment = useSelector((state) => state.qa.filterSelectedRemark);
  const remarksList = useSelector((state) => state.qa.remarksList);

  //Api calls
  useRemarksList();
  const relStatusUpdate = useRELStatusUpdate(
    remarks,
    1,
    relList?.[0]?.page_type ?? "",
    relList?.[0]?.alternate_url ?? "",
    relList?.[0]?.ecommerce ?? false,
    relList?.[0]?.page_url ?? "",
    relList?.[0]?.f_cards_available ?? {},
    relList?.[0]?.image_url ?? "",
    selectedSegment
  );
  const getAllRelList = useRelList();

  useEffect(() => {
    getAllRelList(segment);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleURLClick = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    const newWindow=window.open(url, "_blank");
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Handle blocked pop-ups or failed window opening
      alert("Unable to open the URL in a new tab. Please disable your pop-up blocker or check the URL.");
    }
  };

  const ModalContent = styled("div")({
    backgroundColor: "gray",
    boxShadow: 24,
    padding: 3,
  });

  function getStatusIcon(value) {
    return value ? (
      <CheckIcon style={{ color: "#0EA101" }} />
    ) : (
      <ClearIcon style={{ color: "#FF0000" }} />
    );
  }

  const handleRemarksDropdownChange = (e) => {
    const text = e.target.value;
    const result = text?.split("]")[1]?.trim();
    const newResult = result === undefined ? e.target.value : result;
    const resultSegment = text?.split("[")[1]?.split("]")[0]?.trim();

    setSelectedSegment(resultSegment);
    setRemarks(newResult);
  };

  const handleNextClick = async () => {
    await getAllRelList(segment);
    setQueryURL("");
  };

  const handleUpdate = async () => {
    try {
      setQueryURL("");
      await relStatusUpdate();
      await getAllRelList(segment);
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  useEffect(() => {
    setRemarks(relList?.[0]?.remarks);
  }, [relList]);

  useEffect(() => {
    setSelectedSegment(relList?.[0]?.segment);
  }, [relList]);

  useEffect(() => {
    setEcommerce(relList?.[0]?.ecommerce);
  }, [relList]);

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  const handleStartAgain = () => {
    getAllRelList(segment);
  };

  return (
    <>
      <ToastContainer />
      <Container
        maxWidth="2xl"
        sx={{
          paddingTop: { xs: "2rem", sm: "1.2rem" },
          paddingBottom: "1.2rem",
          backgroundColor: "#F8F8F8",
        }}
      >
        {currentCount <= relCount ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "20px", sm: "20px" },
              flexWrap: "wrap",
              // height:"100%"
            }}
          >
            {relList?.map((qaData) => (
              <>
                <Stack sx={{ flex: "3", position: "relative" }}>
                  {/* <Stack sx={{ position: "relative" }}> */}
                  {/* <img src={qaData?.screenshot_url} alt="amex logo" style={{ height: "100%" }} /> */}
                  <QACorousel qaData={qaData?.img_url} />
                  <ZoomOutMapIcon
                    sx={{
                      position: "absolute",
                      right: "15px",
                      top: "16px",
                      fontSize: "2rem",
                      cursor: "pointer",
                      color: "gray",
                      zIndex: "100",
                    }}
                    onClick={handleOpen}
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "center",
                      position: "absolute",
                      bottom: "20px",
                      left: 0,
                      right: 0,
                    }}
                  ></Stack>
                  {/* </Stack> */}

                  <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                      sx: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                    }}
                  >
                    <Fade in={open}>
                      <ModalContent>
                        <img
                          src={qaData?.img_url}
                          alt="Full Screen Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        />
                        <CloseIcon
                          sx={{
                            position: "absolute",
                            top: "0.6rem",
                            right: "0.6rem",
                            fontSize: "2.5rem",
                            cursor: "pointer",
                            color: "gray",
                          }}
                          onClick={handleClose}
                        />
                      </ModalContent>
                    </Fade>
                  </Modal>
                </Stack>

                <Stack sx={{ alignItems: "center", height: "90%" }}>
                  <Card
                    sx={{ flex: "1", width: { xs: "max-content", sm: "100%" } }}
                  >
                    <CardContent
                      key={qaData?.url_id}
                      sx={{
                        lineHeight: "1.2rem",
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "row",
                          md: "column",
                        },
                        justifyContent: { xs: "", sm: "space-between" },
                      }}
                    >
                      <Stack sx={{ gap: "20px" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            fontSize: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            URL ID:
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.url_id ?? "N/A"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            S.No :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.sno ?? "N/A"}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{ color: "#989898", fontWeight: "700" }}
                            >
                              URL
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "400",
                                color: "#006FCF",
                                // display: "flex",
                                // flexDirection: "row",
                                // alignItems: "center",
                                gap: "0.2rem",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                                width: "380px",
                              }}
                            >
                              {qaData?.url === null || qaData?.url === ""
                                ? "N/A"
                                : qaData?.url}
                            </Typography>
                          </Stack>
                          <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            <LaunchIcon
                              onClick={() => handleURLClick(qaData?.url)}
                              sx={{ cursor: "pointer" }}
                            />
                          </Stack>
                        </Stack>
                        <Stack sx={{ fontSize: "0.9rem" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                              }}
                            >
                              Alternate URL
                            </Stack>
                          </Typography>

                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              // justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "400",
                                color: "#006FCF",
                                // display: "flex",
                                // flexDirection: "row",
                                // alignItems: "center",
                                gap: "0.2rem",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                                width: "380px",
                              }}
                            >
                              {qaData?.alternate_url === null ||
                              qaData?.alternate_url === ""
                                ? "N/A"
                                : qaData?.alternate_url}
                            </Typography>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            ></Stack>
                          </Stack>
                        </Stack>
                        <Stack sx={{ fontSize: "0.9rem" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            POPs URL
                          </Typography>

                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              // justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "400",
                                color: "#006FCF",
                                // display: "flex",
                                // flexDirection: "row",
                                // alignItems: "center",
                                gap: "0.2rem",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                                width: "380px",
                              }}
                            >
                              {qaData?.page_url === null ||
                              qaData?.page_url === ""
                                ? "N/A"
                                : qaData?.page_url}
                            </Typography>
                            <Stack
                              sx={{ display: "flex", flexDirection: "row" }}
                            ></Stack>
                          </Stack>
                        </Stack>

                        <Stack
                          sx={{
                            fontSize: "0.9rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography
                              sx={{ color: "#989898", fontWeight: "700" }}
                            >
                              Page Type
                            </Typography>

                            <Typography
                              sx={{
                                fontWeight: "400",
                                color: "#006FCF",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "0.2rem",
                              }}
                            >
                              {qaData?.page_type ?? "N/A"}
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {ecommerce ? (
                                <CheckIcon style={{ color: "#0EA101" }} />
                              ) : (
                                <ClearIcon style={{ color: "#FF0000" }} />
                              )}
                            </Stack>

                            <Typography sx={{ color: "#000000" }}>
                              Ecommerce
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack sx={{ fontSize: "0.9rem", gap: "2px" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            Executioner's Remark
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "normal",
                              width: "380px",
                            }}
                          >
                            {qaData?.remarks ?? "N/A"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider
                        sx={{ backgroundColor: "#BBBBBB", marginY: "1rem" }}
                      />
                      <FormControl sx={{ display: "flex", gap: "16px" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.8rem",
                          }}
                        >
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                F_POPs Found
                              </Typography>
                              {!isEdit && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                ></Stack>
                              )}
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.f_cards_available?.includes("amex")
                                )}
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.f_cards_available?.includes(
                                    "mastercard"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.f_cards_available?.includes("visa")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.f_cards_available?.includes(
                                    "discover"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.f_cards_available?.includes("paypal")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>

                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.f_cards_available?.includes("wallet")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Wallets
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          sx={{
                            // alignItems: "center",
                            background: "#FFF",
                            gap: "1rem",
                          }}
                        >
                          <Stack sx={{ width: "100%" }}>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.4rem",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TextField
                                size="small"
                                value={selectedSegment}
                                // onChange={handleSegmentChange}
                                multiline
                                placeholder="Segment"
                                sx={{
                                  width: "100%",
                                  borderRadius: "0",
                                  ".MuiInputBase-root": {
                                    borderRadius: "0",
                                  },
                                }}
                              ></TextField>
                              {/* <select
                                id="remarks"
                                name="remarks"
                                style={{
                                  height: "2.5rem",
                                  width: "10rem",
                                  cursor: "pointer",
                                }}
                                onChange={handleRemarksDropdownChange}
                                // value={selectedSegment}
                              >
                                <option disabled selected id="select">
                                  Select Remarks
                                </option>
                                {remarksList?.map((remark) => (
                                  <option
                                    id={remark?.remark_id}
                                    value={remark?.remark_name}
                                  >
                                    {remark?.remark_name}
                                  </option>
                                ))}
                              </select>
                              {remarks === null && (
                                <p style={{ color: "red" }}>
                                  Please select Remark to move Next
                                </p>
                              )} */}
                            </Stack>
                          </Stack>
                          <TextField
                            value={remarks}
                            onChange={handleRemarksChange}
                            multiline
                            placeholder="Add a Remark"
                            sx={{
                              width: "100%",
                              borderRadius: "0",
                              ".MuiInputBase-root": {
                                borderRadius: "0",
                              },
                            }}
                          ></TextField>
                        </Stack>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "0.6rem",
                          }}
                        >
                          <Stack
                            sx={{
                              background: "#006FCF",
                              width: "8.5rem",
                            }}
                          >
                            <Button
                              sx={{ color: "#FFFFFF", textTransform: "none" }}
                              onClick={handleUpdate}
                            >
                              Update
                            </Button>
                          </Stack>
                          <Stack
                            sx={{
                              background: "#805300",
                              width: "8.5rem",
                              textTransform: "none",
                            }}
                          >
                            <Button
                              sx={{
                                color: "#FFFFFF",
                                textTransform: "none",
                                background: "#99641a",
                                "&:hover": { backgroundColor: "#8a5a17" },
                              }}
                              onClick={handleNextClick}
                            >
                              Next
                            </Button>
                          </Stack>
                        </Stack>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Stack>
              </>
            ))}
          </Box>
        ) : (
          <>
            <Stack
              sx={{
                width: "100%",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#DADADA",
                fontSize: "40px",
                fontWeight: "700",
              }}
            >
              <Stack>
                <Typography sx={{ fontSize: "40px", fontWeight: "700" }}>
                  All Done.{" "}
                </Typography>
                <Typography sx={{ fontSize: "40px", fontWeight: "700" }}>
                  Click Start to begin Again!
                </Typography>
              </Stack>

              <Button
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "0px",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "6px 32px",
                  width: "140px",
                  marginTop: "0.5rem",
                }}
                onClick={handleStartAgain}
              >
                Start
              </Button>
            </Stack>
          </>
        )}
      </Container>
    </>
  );
};

export default QAForm;
