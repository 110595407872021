import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  FormControl,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Modal, Backdrop, Fade, styled } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useDispatch, useSelector } from "react-redux";
import { getQATimer } from "../../../slice/QASlice";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useQAList from "../../../hooks/useQAList";
import useQAStatusUpdate from "../../../hooks/useQAStatusUpdate";
import useUrlLastStatusId from "../../../hooks/useUrlLastStatusId";
import QACorousel from "./QACorousel";
import useQAAccessStatusList from "../../../hooks/useQAAccessStatusList";
import LaunchIcon from "@mui/icons-material/Launch";
import EditIcon from "@mui/icons-material/Edit";
import Input from "@mui/material/Input";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useQAImageUpload from "../../../hooks/useImageUpload";
import SearchIcon from "@mui/icons-material/Search";
import useRemarksList from "../../../hooks/useRemarksList";
import RefreshIcon from "@mui/icons-material/Refresh";
import useRefreshImage from "../../../hooks/useRefreshImage";
import Timer from "../../Timer";
import noImage from "../../../assests/noImage.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMoveCom from "../../../hooks/useMoveCom";
import useMovePrev from "../../../hooks/useMovePrev";
import useQACOMUpdate from "../../../hooks/useQACOMUpdate";
import { pageTypeList } from "../../../utility/constant";

const QAForm = ({ updateTime, timeLeft, setQueryURL }) => {
  const dispatch = useDispatch();
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isEditAlternate, setIsEditAlternate] = useState(false);
  const [isEditPageUrl, setIsEditPageUrl] = useState(false);
  const [isEditPage, setIsEditPage] = useState(false);
  const [alternate, setAlternate] = useState(null);
  const [pageUrl, setPageUrl] = useState(null);
  const [pageType, setPageType] = useState(null);
  const [ecommerce, setEcommerce] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [open, setOpen] = useState(false);
  const [cardAvailableArray, setCardAvailableArray] = useState(null);
  const [checked, setChecked] = useState({
    amex: false,
    mastercard: false,
    visa: false,
    paypal: false,
    wallet: false,
    discover: false,
  });
 

  // ************************ Redux ******************************
  const allQAList = useSelector((state) => state.qa.qaList);
  const selectedCardData = useSelector((state) => state.qa.selectedCardData);
  const qaFormData = useSelector((state) => state.qa.qaFormData);
  const remarksList = useSelector((state) => state.qa.remarksList);
  const refreshImage = useSelector((state) => state.qa.refreshImage);
  const segment = useSelector((state) => state.qa.filterSelectedRemark);
  // ************************ Redux ******************************

  // ********************************* Api calls ***************************
  useRemarksList();
  const { movePrev, isRejectLoading, setIsRejectLoading } = useMovePrev(
    checked,
    remarks,
    pageType,
    alternate,
    ecommerce,
    pageUrl,
    selectedSegment
  );
  const { comMove, isLoading, setIsLoading } = useMoveCom(
    checked,
    remarks,
    pageType,
    alternate,
    ecommerce,
    pageUrl,
    selectedSegment
  );
  const qaCOMUpdate = useQACOMUpdate(
    checked,
    remarks,
    pageType,
    alternate,
    ecommerce,
    pageUrl,
    selectedSegment
  );
  const getAllQAList = useQAList(selectedCardData);
  const getAllQAAccessStatusList = useQAAccessStatusList();
  const qaStatusUpdate = useQAStatusUpdate();
  const getUrlLastStatusId = useUrlLastStatusId();
  const getAllRefreshImage = useRefreshImage();
  const getQAImageUpload = useQAImageUpload();

  // ************************************ Api Calls *******************************

  // ******************************useEffect****************************
  useEffect(() => {
    localStorage.setItem("url_status_id", allQAList?.[0]?.url_status_id);
    localStorage.setItem("url_id", allQAList?.[0]?.url_id);
    localStorage.setItem("sno", allQAList?.[0]?.sno);
    setSelectedImageUrl(allQAList?.[0]?.img_url);
    if (allQAList?.[0]?.card_available) {
      setCardAvailableArray(allQAList[0].card_available);
    }
    setAlternate(allQAList?.[0]?.alternate_url ?? "");
    setPageUrl(allQAList?.[0]?.page_url ?? "");
    setRemarks(allQAList?.[0]?.remarks ?? "");
    setSelectedSegment(allQAList?.[0]?.segment ?? "");
    setPageType(allQAList?.[0]?.page_type ?? "");
  }, [allQAList]);

  useEffect(() => {
    if (allQAList && allQAList?.[0]) {
      setEcommerce(allQAList?.[0]?.ecommerce);
    }
  }, [allQAList]);

  useEffect(() => {
    getAllQAList(segment);
  }, [selectedCardData]);

  useEffect(() => {
    setSelectedImageUrl(qaFormData?.full_path);
  }, [qaFormData]);

  useEffect(() => {
    setChecked({
      amex: allQAList?.[0]?.f_cards_available?.includes("amex"),
      mastercard: allQAList?.[0]?.f_cards_available?.includes("mastercard"),
      visa: allQAList?.[0]?.f_cards_available?.includes("visa"),
      paypal: allQAList?.[0]?.f_cards_available?.includes("paypal"),
      wallet: allQAList?.[0]?.f_cards_available?.includes("wallet"),
      discover: allQAList?.[0]?.f_cards_available?.includes("discover"),
    });
  }, [allQAList]);

  useEffect(() => {
    getUrlLastStatusId();
  }, [allQAList[0]?.url_id]);

  useEffect(() => {
    if (cardAvailableArray) {
      setChecked({
        amex: cardAvailableArray.includes("amex"),
        mastercard: cardAvailableArray.includes("mastercard"),
        visa: cardAvailableArray.includes("visa"),
        paypal: cardAvailableArray.includes("paypal"),
        wallet: cardAvailableArray.includes("wallet"),
        discover: cardAvailableArray.includes("discover"),
      });
    }
  }, [cardAvailableArray]);

  useEffect(() => {
    setSelectedImageUrl(refreshImage?.[0]?.img_url);
  }, [refreshImage]);
  //  ********************************** useEffect  *****************************************

  const handleURLClick = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    const newWindow=window.open(url, "_blank");
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Handle blocked pop-ups or failed window opening
      alert("Unable to open the URL in a new tab. Please disable your pop-up blocker or check the URL.");
    }
  };

  const searchGoogle = (value) => {
    const query = value; // Replace with your search query
    const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(
      query
    )}`;
    window.open(googleSearchUrl, "_blank");
  };

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("url_id", allQAList?.[0]?.url_id);
    formData.append("task_id", allQAList?.[0]?.task_id);
    formData.append("url_status_id", allQAList?.[0]?.url_status_id);
    getQAImageUpload(formData);
    if (uploadedFile) {
      setSelectedFileName(uploadedFile?.name);
    }
  };

  function getStatusIcon(value) {
    return value ? (
      <CheckIcon style={{ color: "#0EA101" }} />
    ) : (
      <ClearIcon style={{ color: "#FF0000" }} />
    );
  }

  const handleSkip = async () => {
    try {
      updateTime(120);
      setIsEditAlternate(false);
      setIsEditPage(false);
      setIsEditPageUrl(false);
      // if (allQAList?.[0]?.ecommerce) {
      // setEcommerce(allQAList?.[0]?.ecommerce);
      // }
      setRemarks(allQAList?.[0]?.remarks ?? "");
      setPageType(allQAList?.[0]?.page_type);
      setQueryURL("");
      dispatch(getQATimer(120));
      // await qaNextUpdate();
      await qaCOMUpdate("fL2QA");
      await getAllQAList(segment);
      await getAllQAAccessStatusList();
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleApprove = async () => {
    try {
      updateTime(120);
      setIsEditAlternate(false);
      setIsEditPage(false);
      setIsEditPageUrl(false);
      setIsLoading(true);
      // if (allQAList?.[0]?.ecommerce) {
      // setEcommerce(allQAList?.[0]?.ecommerce);
      // }
      setRemarks(allQAList?.[0]?.remarks ?? "");
      setPageType(allQAList?.[0]?.page_type);
      setQueryURL("");
      // Update the QA status
      await qaStatusUpdate();

      // Update the QA approval status
      await comMove();

      // Get the updated QA list
      await getAllQAList(segment);
      await getAllQAAccessStatusList();
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleReject = async () => {
    updateTime(120);
    setIsEditAlternate(false);
    setIsEditPage(false);
    setIsEditPageUrl(false);
    setIsRejectLoading(true);
    setEcommerce(allQAList?.[0]?.ecommerce);
    setRemarks(allQAList?.[0]?.remarks ?? "");
    setPageType(allQAList?.[0]?.page_type);
    setQueryURL("");
    await getUrlLastStatusId();

    // Update the QA status
    await qaStatusUpdate();
    // Update the QA approval status
    await movePrev("QA");

    // Get the updated QA list
    await getAllQAList(segment);
    await getAllQAAccessStatusList();
  };

  const handleCheckbox = (e) => {
    const newValue = e.target.checked;
    setChecked((obj) => ({
      ...obj,
      [e.target.name]: newValue,
    }));
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleEditAlternate = () => {
    setIsEditAlternate(true);
  };

  const handleEditPageUrl = () => {
    setIsEditPageUrl(true);
  };

  const handleEditPage = () => {
    setIsEditPage(!isEditPage);
  };

  const handleChangeAlternate = (e) => {
    setAlternate(e.target.value);
  };

  const handleChangePageUrl = (e) => {
    setPageUrl(e.target.value);
  };

  const handleChangePageType = (e) => {
    setPageType(e.target.value);
  };

  const handleChangeEcommerce = (e) => {
    setEcommerce(e.target.checked);
  };

  const handleRemarksDropdownChange = (e) => {
    const text = e.target.value;
    const result = text?.split("]")[1]?.trim();
    const newResult = result === undefined ? e.target.value : result;
    const resultSegment = text?.split("[")[1]?.split("]")[0]?.trim();

    setSelectedSegment(resultSegment);
    setRemarks(newResult);
    // setRemarks((prevRemarks) => {
    //   if (prevRemarks?.includes(newResult)) {
    //     return allQAList?.[0]?.remarks;
    //   }
    //   let newRemarks = prevRemarks;

    //   // Remove the previous result if it exists in the remarks
    //   if (previousResult && prevRemarks) {
    //     const regex = new RegExp(`(^|,\\s*)${previousResult}(,\\s*|$)`);
    //     newRemarks = prevRemarks
    //       .replace(regex, "")
    //       .replace(/^,|,$/g, "")
    //       .trim();
    //   }

    //   // Add the new result if it's not already in the remarks
    //   if (newResult && !newRemarks.includes(newResult)) {
    //     if (newRemarks) {
    //       newRemarks = `${newResult}, ${newRemarks}`;
    //     } else {
    //       newRemarks = newResult;
    //     }
    //   }

    //   // Set the previous result to the new result for future comparison
    //   setPreviousResult(newResult);

    //   return newRemarks.trim();
    // });
  };

  const handleRefreshImage = async () => {
    await getAllRefreshImage();
  };

  const copyTextToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Text Copied Successfully!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handlePageTypeDropdownChange = (e) => {
    setPageType(e.target.value);
  };

  const ModalContent = styled("div")({
    backgroundColor: "gray",
    boxShadow: 24,
    padding: 3,
  });

  return isLoading === true || isRejectLoading === true ? (
    <div>
      <Box
        sx={{ display: "flex", position: "absolute", top: "50%", left: "50%" }}
      >
        <CircularProgress />
      </Box>
    </div>
  ) : (
    <>
      <ToastContainer />
      <Container
        maxWidth="2xl"
        sx={{
          paddingTop: { xs: "2rem", sm: "1.2rem" },
          paddingBottom: "1.2rem",
          backgroundColor: "#F8F8F8",
        }}
      >
        {allQAList.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "20px", sm: "20px" },
              flexWrap: "wrap",
              // height:"100%"
            }}
          >
            {allQAList?.map((qaData) => (
              <>
                <Stack sx={{ flex: "3", position: "relative" }}>
                  {/* <Stack sx={{ position: "relative" }}> */}
                  {/* <img src={qaData?.screenshot_url} alt="amex logo" style={{ height: "100%" }} /> */}
                  <QACorousel
                    qaData={
                      selectedImageUrl === null || selectedImageUrl === ""
                        ? noImage
                        : selectedImageUrl
                    }
                  />
                  <ZoomOutMapIcon
                    sx={{
                      position: "absolute",
                      right: "15px",
                      top: "16px",
                      fontSize: "2rem",
                      cursor: "pointer",
                      color: "gray",
                      zIndex: "100",
                    }}
                    onClick={() => setOpen(true)}
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "center",
                      position: "absolute",
                      bottom: "20px",
                      left: 0,
                      right: 0,
                    }}
                  ></Stack>
                  {/* </Stack> */}

                  <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                      sx: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                    }}
                  >
                    <Fade in={open}>
                      <ModalContent>
                        <img
                          src={qaData?.img_url}
                          alt="Full Screen Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        />
                        <CloseIcon
                          sx={{
                            position: "absolute",
                            top: "0.6rem",
                            right: "0.6rem",
                            fontSize: "2.5rem",
                            cursor: "pointer",
                            color: "gray",
                          }}
                          onClick={() => setOpen(false)}
                        />
                      </ModalContent>
                    </Fade>
                  </Modal>
                  <Input
                    accept="image/*"
                    id="file-upload-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.8rem",
                      }}
                    >
                      <label htmlFor="file-upload-input">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{ textWrap: "nowrap" }}
                          startIcon={<UploadFileIcon />}
                        >
                          {selectedFileName ? selectedFileName : "Update Image"}
                        </Button>
                        {/* {errors.file && (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.8rem",
                    marginTop: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  **File is required**
                </p>
              )} */}
                      </label>
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{ textWrap: "nowrap" }}
                        startIcon={<RefreshIcon />}
                        width="max-content"
                        onClick={handleRefreshImage}
                      >
                        Refresh
                      </Button>
                    </Stack>

                    <Timer timeLeft={timeLeft} />
                  </Stack>
                </Stack>

                <Stack sx={{ alignItems: "center", height: "90%" }}>
                  <Card
                    sx={{
                      flex: "1",
                      width: { xs: "max-content", sm: "100%" },
                    }}
                  >
                    <CardContent
                      key={qaData?.url_id}
                      sx={{
                        lineHeight: "1.2rem",
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "row",
                          md: "column",
                        },
                        justifyContent: { xs: "", sm: "space-between" },
                      }}
                    >
                      <Stack sx={{ gap: "20px" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            fontSize: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            URL ID:
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.url_id ?? "N/A"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            S.No :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.sno ?? "N/A"}
                          </Typography>
                        </Stack>

                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{ color: "#989898", fontWeight: "700" }}
                            >
                              URL
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "400",
                                color: "#006FCF",
                                // display: "flex",
                                // flexDirection: "row",
                                // alignItems: "center",
                                gap: "0.2rem",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                                width: "380px",
                              }}
                            >
                              {qaData?.url === null || qaData?.url === ""
                                ? "N/A"
                                : qaData?.url}
                            </Typography>
                          </Stack>
                          <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            <LaunchIcon
                              onClick={() => handleURLClick(qaData?.url)}
                              sx={{ cursor: "pointer" }}
                            />
                          </Stack>
                        </Stack>

                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "430px",
                          }}
                        >
                          <button
                            onClick={() => copyTextToClipboard(qaData?.name)}
                          >
                            {qaData?.name}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.address)}
                          >
                            {qaData?.address}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.area)}
                          >
                            {qaData?.area}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.town)}
                          >
                            {qaData?.town}
                          </button>
                          <button
                            onClick={() =>
                              copyTextToClipboard(qaData?.postal_code)
                            }
                          >
                            {qaData?.postal_code}
                          </button>
                          <button
                            onClick={() => copyTextToClipboard(qaData?.country)}
                          >
                            {qaData?.country}
                          </button>
                        </Stack>
                        <Stack sx={{ fontSize: "0.9rem" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                              }}
                            >
                              Alternate URL
                              <SearchIcon
                                onClick={() =>
                                  searchGoogle(qaData?.full_details)
                                }
                                sx={{ color: "black", cursor: "pointer" }}
                              />
                            </Stack>
                          </Typography>

                          {isEditAlternate === false ? (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  color: "#006FCF",
                                  // display: "flex",
                                  // flexDirection: "row",
                                  // alignItems: "center",
                                  gap: "0.2rem",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  whiteSpace: "normal",
                                  width: "380px",
                                }}
                              >
                                {qaData?.alternate_url === null ||
                                qaData?.alternate_url === ""
                                  ? "N/A"
                                  : qaData?.alternate_url}
                              </Typography>
                              <Stack
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <LaunchIcon
                                  onClick={() =>
                                    handleURLClick(qaData?.alternate_url)
                                  }
                                  sx={{ cursor: "pointer" }}
                                />

                                <EditIcon
                                  sx={{ width: "1.2rem", cursor: "pointer" }}
                                  onClick={() => handleEditAlternate()}
                                />
                              </Stack>
                            </Stack>
                          ) : (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.6rem",
                              }}
                            >
                              <input
                                value={alternate}
                                style={{ height: "1.8rem" }}
                                onChange={(e) => handleChangeAlternate(e)}
                              />
                            </Stack>
                          )}
                        </Stack>
                        <Stack sx={{ fontSize: "0.9rem" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            POPs URL
                          </Typography>

                          {isEditPageUrl === false ? (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  color: "#006FCF",
                                  // display: "flex",
                                  // flexDirection: "row",
                                  // alignItems: "center",
                                  gap: "0.2rem",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  whiteSpace: "normal",
                                  width: "380px",
                                }}
                              >
                                {qaData?.page_url === null ||
                                qaData?.page_url === ""
                                  ? "N/A"
                                  : qaData?.page_url}
                              </Typography>
                              <Stack
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <LaunchIcon
                                  onClick={() =>
                                    handleURLClick(qaData?.page_url)
                                  }
                                  sx={{ cursor: "pointer" }}
                                />

                                <EditIcon
                                  sx={{ width: "1.2rem", cursor: "pointer" }}
                                  onClick={() => handleEditPageUrl()}
                                />
                              </Stack>
                            </Stack>
                          ) : (
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.6rem",
                              }}
                            >
                              <input
                                value={pageUrl}
                                style={{ height: "1.8rem" }}
                                onChange={(e) => handleChangePageUrl(e)}
                              />
                            </Stack>
                          )}
                        </Stack>

                        <Stack
                          sx={{
                            fontSize: "0.9rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography
                              sx={{ color: "#989898", fontWeight: "700" }}
                            >
                              Page Type
                            </Typography>

                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                              }}
                            >
                              {!isEditPage ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontWeight: "400",
                                      color: "#006FCF",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: "0.2rem",
                                    }}
                                  >
                                    {qaData?.page_type ?? "N/A"}

                                    <EditIcon
                                      onClick={() => handleEditPage()}
                                      sx={{
                                        width: "1.2rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Typography>
                                </>
                              ) : (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <input
                                    value={pageType}
                                    style={{ height: "1.8rem" }}
                                    onChange={(e) => handleChangePageType(e)}
                                  />
                                </Stack>
                              )}
                              {isEditPage && (
                                <Stack sx={{ width: "6rem", width: "100%" }}>
                                  <select
                                    id="remarks"
                                    name="remarks"
                                    style={{
                                      height: "2rem",
                                      cursor: "pointer",
                                    }}
                                    onChange={handlePageTypeDropdownChange}
                                  >
                                    <option disabled selected id="select">
                                      Select PageType
                                    </option>
                                    {pageTypeList?.map((page, index) => (
                                      <option id={index} value={page}>
                                        {page}
                                      </option>
                                    ))}
                                  </select>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  checked={ecommerce}
                                  onChange={(e) => handleChangeEcommerce(e)}
                                  name="ecommerce"
                                />
                              }
                            </Stack>

                            <Typography sx={{ color: "#000000" }}>
                              Ecommerce
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack sx={{ fontSize: "0.9rem", gap: "2px" }}>
                          <Typography
                            sx={{ color: "#989898", fontWeight: "700" }}
                          >
                            Executioner's Remark
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "normal",
                              width: "380px",
                            }}
                          >
                            {qaData?.remarks ?? "N/A"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider
                        sx={{ backgroundColor: "#BBBBBB", marginY: "1rem" }}
                      />
                      <FormControl sx={{ display: "flex", gap: "16px" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.8rem",
                          }}
                        >
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                C_POPs Found
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("amex")
                                )}
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes(
                                    "mastercard"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("visa")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes(
                                    "discover"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("paypal")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>

                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.c_cards_available?.includes("wallet")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Wallets
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                AI_POPs Found
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("amex")
                                )}
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes(
                                    "mastercard"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("visa")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes(
                                    "discover"
                                  )
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("paypal")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>

                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {getStatusIcon(
                                  qaData?.ai_cards_available?.includes("wallet")
                                )}
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Wallets
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.9rem",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "700", color: "#989898" }}
                              >
                                F_POPs Found
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "amex"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="amex"
                                  />
                                }
                              </Stack>

                              <Typography sx={{ color: "#000000" }}>
                                Amex
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "mastercard"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="mastercard"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                MasterCard
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "visa"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="visa"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Visa
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "discover"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="discover"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Discover
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              <Stack sx={{ width: "2.2rem" }}>
                                {
                                  <Checkbox
                                    defaultChecked={qaData?.f_cards_available?.includes(
                                      "paypal"
                                    )}
                                    onChange={(e) => handleCheckbox(e)}
                                    name="paypal"
                                  />
                                }
                              </Stack>
                              <Typography sx={{ color: "#000000" }}>
                                Paypal
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          sx={{
                            // alignItems: "center",
                            background: "#FFF",
                            gap: "1rem",
                          }}
                        >
                          <Stack sx={{ width: "100%" }}>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.4rem",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TextField
                                size="small"
                                value={selectedSegment}
                                // onChange={handleSegmentChange}
                                multiline
                                placeholder="Segment"
                                sx={{
                                  width: "100%",
                                  borderRadius: "0",
                                  ".MuiInputBase-root": {
                                    borderRadius: "0",
                                  },
                                }}
                              ></TextField>
                              <select
                                id="remarks"
                                name="remarks"
                                style={{
                                  height: "2.5rem",
                                  width: "10rem",
                                  cursor: "pointer",
                                }}
                                onChange={handleRemarksDropdownChange}
                                // value={selectedSegment}
                              >
                                <option disabled selected id="select">
                                  Select Remarks
                                </option>
                                {remarksList?.map((remark) => (
                                  <option
                                    id={remark?.remark_id}
                                    value={remark?.remark_name}
                                  >
                                    {remark?.remark_name}
                                  </option>
                                ))}
                              </select>
                              {remarks === null && (
                                <p style={{ color: "red" }}>
                                  Please select Remark to move Next
                                </p>
                              )}
                            </Stack>
                          </Stack>
                          <TextField
                            value={remarks}
                            onChange={handleRemarksChange}
                            multiline
                            placeholder="Add a Remark"
                            sx={{
                              width: "100%",
                              borderRadius: "0",
                              ".MuiInputBase-root": {
                                borderRadius: "0",
                              },
                            }}
                          ></TextField>
                        </Stack>

                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "0.6rem",
                          }}
                        >
                          <Stack
                            sx={{
                              background: "#EA001B",
                              width: "8.5rem",
                            }}
                          >
                            <Button
                              sx={{
                                color: "#FFFFFF",
                                textTransform: "none",
                                background: "#9e3434",
                              }}
                              onClick={handleReject}
                            >
                              Reject
                            </Button>
                          </Stack>
                          <Stack
                            sx={{
                              background: "#006FCF",
                              width: "8.5rem",
                              textTransform: "none",
                            }}
                          >
                            <Button
                              sx={{
                                color: "#FFFFFF",
                                textTransform: "none",
                                background: "#99641a",
                                "&:hover": { backgroundColor: "#8a5a17" },
                              }}
                              onClick={handleSkip}
                            >
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography sx={{ fontSize: "0.6rem" }}>
                                  Update
                                </Typography>
                                <Typography sx={{ fontSize: "0.5rem" }}>
                                  Move to Next
                                </Typography>
                              </Stack>
                            </Button>
                          </Stack>
                          <Stack
                            sx={{
                              background: "#006FCF",
                              width: "8.5rem",
                              textTransform: "none",
                            }}
                          >
                            <Button
                              sx={{
                                color: "#FFFFFF",
                                textTransform: "none",
                                background: "#5f822f",
                                "&:hover": { backgroundColor: "#56752a" },
                              }}
                              onClick={handleApprove}
                            >
                              Approve
                            </Button>
                          </Stack>
                        </Stack>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Stack>
              </>
            ))}
          </Box>
        ) : (
          <>
            <Stack
              sx={{
                width: "100%",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#DADADA",
                fontSize: "40px",
                fontWeight: "700",
              }}
            >
              <Stack>
                <Typography sx={{ fontSize: "40px", fontWeight: "700" }}>
                  All Done.{" "}
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Container>
    </>
  );
};

export default QAForm;
