import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getQAList } from "../slice/QASlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useLevel1Start = () => {
  const Axios = useAxiosPrivate();
  const current_task_id = localStorage.getItem("task_id");
  console.log(current_task_id,"fkjdkff")
  const getLevel1Start = async () => {
    const param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/start-level-1-process", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success(data?.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return getLevel1Start;
};

export default useLevel1Start;
