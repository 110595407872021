import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AxiosPrivate } from "../axios/axios";
import useRefreshToken from "../hooks/useRefreshToken";

const useAxiosPrivate = () => {
  console.log("7777")
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const refresh = useRefreshToken();
  useEffect(() => {
    const requestInterceptors = AxiosPrivate.interceptors.request.use(
      (config) => {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseInterceptors = AxiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const preRequest = error?.config;

        if (error?.response?.status === 403 || !preRequest) {
          preRequest.sent = true;
          const newAccessToken = await refresh();
          preRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return AxiosPrivate(preRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      AxiosPrivate.interceptors.request.eject(requestInterceptors);
      AxiosPrivate.interceptors.response.eject(responseInterceptors);
    };
  }, [accessToken, refresh]);

  return AxiosPrivate;
};

export default useAxiosPrivate;