import { useState } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useMovePrev = (
  checked,
  remarks,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl,
  selectedSegment
) => {
  const Axios = useAxiosPrivate();
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const selectedCardTypes = Object?.keys(checked).filter((key) => checked[key]);

  //Redux
  const allQAList = useSelector((state) => state.qa.qaList);
  const allCOMList = useSelector((state) => state.qa.comList);
  const urlLastStatusId = useSelector((state) => state.qa.urlLastStatusId);

  const movePrev = async (type) => {
    let available_cards = [];

    available_cards = selectedCardTypes;

    let param = {
      available_cards,
      f_cards_available: available_cards,
      url_id:
        type === "fCOM" ? allCOMList?.[0]?.url_id : allQAList?.[0]?.url_id,
      status_id: type === "fCOM" ? 3 : urlLastStatusId?.[0]?.status_id,
      remarks: remarks,
      task_id:
        type === "fCOM" ? allCOMList?.[0]?.task_id : allQAList?.[0]?.task_id,
      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
      segment: selectedSegment,
    };

    
    const response = await Axios.post("/move-to-prev", param);
    const data = await response.data;
    if (data.code === 200) {
      setIsRejectLoading(false);
      toast.success("Rejected Successfully", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return { movePrev, isRejectLoading, setIsRejectLoading };
};

export default useMovePrev;
