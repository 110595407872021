import React, { useEffect, useState } from "react";
import QAForm from "./QAL2Form";
import QAL2Header from "./QAL2Header";
import useRelList from "../../hooks/useRelList";

const RELPage = () => {
  const [queryURL, setQueryURL] = useState(null);
  const current_task_id = localStorage.getItem("task_id");
  const getAllRelList = useRelList();
  useEffect(() => {
    getAllRelList();
  }, [current_task_id]);
  return (
    <>
      <QAL2Header queryURL={queryURL} setQueryURL={setQueryURL}/>
      <QAForm setQueryURL={setQueryURL}/>
    </>
  );
};

export default RELPage;
