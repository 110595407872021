import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getAccessStatusList,
  getQAAccessStatusList,
} from "../slice/statusSlice";

const useQAAccessStatusList = () => {
  const location = useLocation();
  const current_task_id = localStorage.getItem("task_id");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getAllQAAccessStatusList = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/qa-access-status-list", param);
    const data = await response.data;
    dispatch(getQAAccessStatusList(data.QAStatusAccessList));
  };

  useEffect(() => {
    getAllQAAccessStatusList();
  }, []);
  return getAllQAAccessStatusList;
};

export default useQAAccessStatusList;
