import { useEffect, useState } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useQAReject = (
  checked,
  remarks,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl
) => {
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const selectedCardTypes = Object?.keys(checked).filter((key) => checked[key]);
  console.log(selectedCardTypes);
  //Redux
  const allQAList = useSelector((state) => state.qa.qaList);
  const allCOMList = useSelector((state) => state.qa.comList);
  const allRelList = useSelector((state) => state.qa.relList);
  const urlLastStatusId = useSelector((state) => state.qa.urlLastStatusId);

  const allLevel1QAList = useSelector((state) => state.qa.level1qaList);
  const allLevel1QAAltList = useSelector((state) => state.qa.level1qaaltList);
  console.log(urlLastStatusId, "uiiooi");
  const Axios = useAxiosPrivate();
  const qaReject = async (type) => {
    let available_cards = [];

    available_cards = selectedCardTypes;

    let param = {
      available_cards,
      // user_id:
      //   type === 2
      //     ? allQAList?.[0]?.user_id
      //     : type === 3
      //     ? allLevel1QAAltList?.[0]?.user_id
      //     : type === 4
      //     ? allRelList?.[0]?.user_id
      //     : type === 5
      //     ? allCOMList?.[0]?.user_id
      //     : allLevel1QAList?.[0]?.user_id,
      url_id:
        type === 2
          ? allQAList?.[0]?.url_id
          : type === 3
          ? allLevel1QAAltList?.[0]?.url_id
          : type === 4
          ? allRelList?.[0]?.url_id
          : type === 5
          ? allCOMList?.[0]?.url_id
          : allLevel1QAList?.[0]?.url_id,
      status_id:
        type === 1
          ? 3
          : type === 3
          ? 1
          : type === 4
          ? 3
          : type === 5
          ? 3
          : urlLastStatusId?.[0]?.status_id,
      remarks: remarks,
      all_url_status: "normal-true",
      task_id:
        type === 2
          ? allQAList[0]?.task_id
          : type === 3
          ? allLevel1QAAltList?.[0]?.task_id
          : type === 4
          ? allRelList?.[0]?.task_id
          : type === 5
          ? allCOMList?.[0]?.task_id
          : allLevel1QAList[0]?.task_id,
      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
    };
    console.log("param", param);

    const response = await Axios.post("/url-status-add", param);
    const data = await response.data;
    if (data.code === 200) {
      setIsRejectLoading(false);
      toast.success("Rejected Successfully", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return { qaReject, isRejectLoading, setIsRejectLoading };
};

export default useQAReject;
