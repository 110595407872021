import { createSlice } from "@reduxjs/toolkit";

const statusSlice = createSlice({
  name: "status",
  initialState: {
    accessStatusList: [],
    statusList: [],
    qaAccessStatusList: [],
  },
  reducers: {
    getStatusList: (state, action) => {
      state.statusList = action.payload;
    },
    getAccessStatusList: (state, action) => {
      state.accessStatusList = action.payload;
    },
    getQAAccessStatusList: (state, action) => {
      state.qaAccessStatusList = action.payload;
    },
  },
});

export default statusSlice.reducer;
export const { getAccessStatusList, getStatusList, getQAAccessStatusList } =
  statusSlice.actions;
