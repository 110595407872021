import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Backdrop, Fade, styled } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useSelector } from "react-redux";
import { showQaData } from "../../../slice/QASlice";
import useQAList from "../../../hooks/useQAList";
import useQAApprove from "../../../hooks/useQAApprove";
import useQAAccessStatusList from "../../../hooks/useQAAccessStatusList";
import useQANotAssignedUpdate from "../../../hooks/useQANotAssignedUpdate";
import useUrlLastStatusId from "../../../hooks/useUrlLastStatusId";
import useQAReject from "../../../hooks/useQAReject";
import QACorousel2 from "./QACorousel2";
import useLevel1QAAltList from "../../../hooks/useQALevel1AltList";
import useLevel1QAAltStatusUpdate from "../../../hooks/useLevel1QAAltStatusUpdate";
import useTotalLevel1QAAltStatusCount from "../../../hooks/useTotalLevel1QAAltStatusCount";
import LaunchIcon from "@mui/icons-material/Launch";
const QAAltForm = () => {
  const showData = useSelector(showQaData);
  const qaList = useSelector((state) => state.qa.level1qaaltList);
  const [remarks, setRemarks] = useState("");
  const [checked, setChecked] = useState({
    amex: false,
    mastercard: false,
    visa: false,
    paypal: false,
    wallet: false,
    discover: false,
  });

  const selectedCardData = useSelector((state) => state.qa.selectedCardData);
  const qaApprove = useQAApprove(checked, remarks, 3);
  const getAllQAList = useQAList(selectedCardData);
  const getAllQAAccessStatusList = useQAAccessStatusList();
  const qaAltLevel1StatusUpdate = useLevel1QAAltStatusUpdate();
  const qaNotAssignedUpdate = useQANotAssignedUpdate();
  const getUrlLastStatusId = useUrlLastStatusId();
  const qaReject = useQAReject(checked, remarks, 3);

  const getAllLevel1QAAltList = useLevel1QAAltList();
  const getAllTotalLevel1QAAltStatusCount = useTotalLevel1QAAltStatusCount();
  useEffect(() => {
    getAllLevel1QAAltList();
  }, []);
  useEffect(() => {
    console.log(JSON.stringify(showData.showQaData), "hi");
  }, [showData]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleURLClick = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    const newWindow=window.open(url, "_blank");
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Handle blocked pop-ups or failed window opening
      alert("Unable to open the URL in a new tab. Please disable your pop-up blocker or check the URL.");
    }
  };
  const ModalContent = styled("div")({
    backgroundColor: "gray",
    boxShadow: 24,
    padding: 3,
  });

  const handleApprove = async () => {
    try {
      setRemarks("");

      // Change the assignment status
      await qaNotAssignedUpdate();

      // Update the QA status
      await qaAltLevel1StatusUpdate();

      // Update the QA approval status
      await qaApprove();

      // Get the updated QA list
      await getAllLevel1QAAltList();
      await getAllTotalLevel1QAAltStatusCount();
      // await getAllQAAccessStatusList();

      // Assign the QA again
      // await qaAssignedUpdate();
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleReject = async () => {
    setRemarks("");
    await getUrlLastStatusId();

    await new Promise((resolve) => setTimeout(resolve, 500));
    await qaNotAssignedUpdate();
    // Update the QA status
    await qaAltLevel1StatusUpdate();
    // Update the QA approval status
    await qaReject();

    // Get the updated QA list
    await getAllLevel1QAAltList();
    await getAllTotalLevel1QAAltStatusCount();
    // await getAllQAAccessStatusList();

    // Assign the QA again
    // await qaAssignedUpdate();
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  return (
    <Container
      maxWidth="2xl"
      sx={{
        paddingTop: { xs: "2rem", sm: "1.2rem" },
        paddingBottom: "1.2rem",
        backgroundColor: "#F8F8F8",
      }}
    >
      {showData.showQaData ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "20px", sm: "20px" },
            // height:"100%"
          }}
        >
          {qaList?.map((qaData) => (
            <>
              <Stack sx={{ flex: "3", position: "relative" }}>
                {/* <Stack sx={{ position: "relative" }}> */}
                {/* <img src={qaData?.screenshot_url} alt="amex logo" style={{ height: "100%" }} /> */}
                <QACorousel2 qaData={qaData?.img_url} />
                <ZoomOutMapIcon
                  sx={{
                    position: "absolute",
                    right: "15px",
                    top: "16px",
                    fontSize: "2rem",
                    cursor: "pointer",
                    color: "gray",
                  }}
                  onClick={handleOpen}
                />
                {/* </Stack> */}

                <Modal
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                    sx: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                  }}
                >
                  <Fade in={open}>
                    <ModalContent>
                      <img
                        src={qaData?.screenshot_url}
                        alt="Full Screen Image"
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      />
                      <CloseIcon
                        sx={{
                          position: "absolute",
                          top: "0.6rem",
                          right: "0.6rem",
                          fontSize: "2.5rem",
                          cursor: "pointer",
                          color: "gray",
                        }}
                        onClick={handleClose}
                      />
                    </ModalContent>
                  </Fade>
                </Modal>
              </Stack>

              <Stack sx={{ alignItems: "center", height: "90%" }}>
                <Card
                  sx={{ flex: "1", width: { xs: "max-content", sm: "100%" } }}
                >
                  <CardContent
                    key={qaData?.url_id}
                    sx={{
                      lineHeight: "1.2rem",
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                        md: "column",
                      },
                      justifyContent: { xs: "", sm: "space-between" },
                    }}
                  >
                    <Stack sx={{ gap: "20px" }}>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          Name:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.name ?? "N/A"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          Address:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.address ?? "N/A"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          Town:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.town ?? "N/A"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          Area:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.area ?? "N/A"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          Postal Code:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.postal_code ?? "N/A"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          Country:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.country ?? "N/A"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          URL ID:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.url_id ?? "N/A"}
                        </Typography>
                        <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            S.No :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.sno ?? "N/A"}
                          </Typography>
                      </Stack>
                      <Stack sx={{ fontSize: "0.9rem" }}>
                        <Typography
                          sx={{ color: "#989898", fontWeight: "700" }}
                        >
                          URL
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#006FCF",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.2rem",
                          }}
                        >
                          {qaData?.url ?? "N/A"}
                          <LaunchIcon
                            onClick={() => handleURLClick(qaData?.url)}
                            sx={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </Stack>
                      <Stack sx={{ fontSize: "0.9rem" }}>
                        <Typography
                          sx={{ color: "#989898", fontWeight: "700" }}
                        >
                          Alternate URL
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#006FCF",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.2rem",
                          }}
                        >
                          {qaData?.alternate_url ?? "N/A"}
                          <LaunchIcon
                            onClick={() =>
                              handleURLClick(qaData?.alternate_url)
                            }
                            sx={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider
                      sx={{ backgroundColor: "#BBBBBB", marginY: "1rem" }}
                    />
                    <FormControl sx={{ display: "flex", gap: "16px" }}>
                      <Stack
                        direction="row"
                        sx={{
                          alignItems: "center",
                          background: "#FFF",
                        }}
                      >
                        <TextField
                          value={remarks}
                          onChange={handleRemarksChange}
                          multiline
                          placeholder="Add a Remark"
                          sx={{
                            width: "18rem",
                            borderRadius: "0",
                            ".MuiInputBase-root": {
                              borderRadius: "0",
                            },
                          }}
                        ></TextField>
                      </Stack>

                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "0.6rem",
                        }}
                      >
                        <Stack
                          sx={{
                            background: "#EA001B",
                            width: "8.5rem",
                          }}
                        >
                          <Button
                            sx={{ color: "#FFFFFF", textTransform: "none",background: "#9e3434", }}
                            onClick={handleReject}
                          >
                            Reject
                          </Button>
                        </Stack>
                        <Stack
                          sx={{
                            background: "#006FCF",
                            width: "8.5rem",
                            textTransform: "none",
                          }}
                        >
                          <Button
                            sx={{
                              color: "#FFFFFF",
                              textTransform: "none",
                              background: "#5f822f",
                              "&:hover": { backgroundColor: "#56752a" },
                            }}
                            onClick={handleApprove}
                          >
                            Approve
                          </Button>
                        </Stack>
                      </Stack>
                    </FormControl>
                  </CardContent>
                </Card>
              </Stack>
            </>
          ))}
        </Box>
      ) : (
        <Stack
          sx={{
            width: "100%",
            height: "70vh",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            textAlign: "center",
            color: "#DADADA",
            fontSize: "40px",
          }}
        >
          Click on “Start” to begin
        </Stack>
      )}
    </Container>
  );
};

export default QAAltForm;
