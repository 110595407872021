import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useComStatusUpdate = () => {
  //Redux
  const allCOMList = useSelector((state) => state.qa.comList);

  const Axios = useAxiosPrivate();
  const comStatusUpdate = async () => {
    let param = {
      url_id: allCOMList?.[0]?.url_id,
    };
    const response = await Axios.post("/url-com-status-update", param);
    const data = await response.data;
    // if (data.code === 200) {
    //   toast.success(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    //   toast.error(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  return comStatusUpdate;
};

export default useComStatusUpdate;
