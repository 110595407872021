import React, { useEffect } from "react";
import { Imageaxios } from "../axios/axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getQAFormData } from "../slice/QASlice";

const useQAImageUpload = () => {
  //   const ImageAxiosPrivate = useImageAxiosPrivate();
  const dispatch = useDispatch();
  const allQAList = useSelector((state) => state.qa.qaList);
  const getQAImageUpload = async (formData) => {
    // let param = {
    //   url_id: allQAList?.[0]?.url_id,
    //   task_id: allQAList?.[0]?.task_id,
    //   url_status_id: allQAList?.[0]?.url_status_id,
    // };

    const response = await Imageaxios.post("/screenshot_upload_qa", formData);
    const data = await response.data;
    console.log(data, "superCategoryImage");
    dispatch(getQAFormData(data));
  };
  return getQAImageUpload;
};

export default useQAImageUpload;
