import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAccessStatusList } from "../slice/statusSlice";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTotalQAStatusCount } from "../slice/QASlice";

const useTotalQAStatusCount = () => {
  const Axios = useAxiosPrivate();
  const location = useLocation();
  const role_id = useSelector((state) => state.auth.userData.role_id);
  
  let rowData = location?.state?.rowData;
  console.log(rowData)
  const dispatch = useDispatch();

  const getAllTotalQAStatusCount = async () => {
    let param = {
      task_id: rowData?.task_id,
    };
    const response = await Axios.post("/total-qa-status-count",param);
    const data = await response.data;
    dispatch(getTotalQAStatusCount(data.totalQAStatusCount));
  };

  // useEffect(() => {
  //   getAllTotalQAStatusCount();
  // }, []);
  return getAllTotalQAStatusCount;
};

export default useTotalQAStatusCount;
