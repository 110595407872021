import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getComList,
  getCurrentCount,
  getRELList,
  getRemarksList,
} from "../slice/QASlice";
import { useEffect } from "react";

const useRemarksList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_status_id = useSelector((state) => state.qa.selectedCardData);
  const userData = useSelector((state) => state.auth.userData);
  // const roleId = useSelector((state) => state.auth.userData.role_id);

  const getAllRemarksList = async () => {
    let param = {
      status_id: userData?.role_id === 4 ? 3 : userData?.role_id === 2 ? 21 : current_status_id,
    };

    const response = await Axios.post("/remarks-list", param);

    const data = await response.data;

    dispatch(getRemarksList(data?.remarksList));
  };

  useEffect(() => {
    getAllRemarksList();
  }, [current_status_id]);

  return getAllRemarksList;
};

export default useRemarksList;
