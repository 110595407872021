import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getQAList } from "../slice/QASlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useLevel1Stop = () => {
  const processId=useSelector((state)=>state.task.level1CheckData)
  const Axios = useAxiosPrivate();
  const getLevel1Stop = async () => {
    const param={
      process_id:processId?.level_one_process_id
    }
    const response = await Axios.post("/stop-level-1-process",param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success(data?.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return getLevel1Stop;
};

export default useLevel1Stop;
