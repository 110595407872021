import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getAccessStatusList,
  getQAAccessStatusList,
} from "../slice/statusSlice";
import { getQAAltAccessStatusList } from "../slice/QASlice";

const useQAAltAccessStatusList = () => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getAllQAAltAccessStatusList = async () => {
    
    const response = await Axios.post("/qa-access-alt-status-list");
    const data = await response.data;
    dispatch(getQAAltAccessStatusList(data.QAStatusAccessList));
  };

  useEffect(() => {
    getAllQAAltAccessStatusList();
  }, []);
  return getAllQAAltAccessStatusList;
};

export default useQAAltAccessStatusList;
