// src/components/Login.js
import React, { useEffect, useRef, useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import { UserLogin } from "../../actions/Login/Login";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, logout } from "../../slice/LoginSlice";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [Error, setError] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [userCaptchaValue, setUserCaptchaValue] = useState("");
  const captchaRef = useRef(null);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleCaptchaChange = (event) => {
    setUserCaptchaValue(event.target.value);
  };

  const handleChange = (e) => {
    
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserCaptchaValue("");
    if (validateCaptcha(userCaptchaValue) === false) {
      Swal.fire({
        text: "Captcha does not match!",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    try {
      let param = {
        email: formData.email,
        password: formData.password,
      };
      const data = await UserLogin(param);
      // Add your login logic here
      console.log("Login submitted:", data);

      if (data.code === 200) {
        dispatch(loginSuccess(data));
        let REFRESH_TOKEN = data.refresh_token;
        localStorage.setItem("refresh_token", REFRESH_TOKEN);
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("user_id", data?.user_data?.user_id);
        localStorage.setItem("task_id", data?.user_data?.task_id);
        localStorage.setItem("role_code", data?.user_data?.role_code);
        
        Swal.fire({
          title: "Success",
          text: "Logged In Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          if (data?.user_data?.role_code === "customer") {
            // Customer user, navigate to "/taskdetails"
            navigate("/");
          } else if (data?.user_data?.role_code === "admin") {
            // Admin user, navigate to "/"
            navigate("/");
          } else if (data?.user_data?.role_code === "executor") {
            navigate("/");
          } else if (data?.user_data?.role_code === "executor") {
            navigate("/");
          } else if (data?.user_data?.role_code === "executor2") {
            navigate("/l2S1temp");
          } else {
            // Non-admin user, navigate to "unauthorized"
            navigate("/unauthorized");
          }
        }, 1000);
      } else if (data.code === 401) {
        // Handle 401 status code specifically
        console.log("Unauthorized error:");
        Swal.fire({
          text: data?.message,
          title: "Unauthorized",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (data.code === 400) {
        Swal.fire({
          text: data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.log(error, "werree");

      // Handle 401 status code specifically
      console.log("Unauthorized error:", error.response.data.message);
      Swal.fire({
        text: "Email and Password does not match",
        title: "Unauthorized",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <form
        onSubmit={handleSubmit}
        sx={{
          width: "100%",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <TextField
          label="Email"
          fullWidth
          variant="outlined"
          type="email"
          name="email"
          InputLabelProps={{ shrink: true }}
          value={formData.email}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Password"
          fullWidth
          variant="outlined"
          type="password"
          name="password"
          InputLabelProps={{ shrink: true }}
          value={formData.password}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />
        <div ref={captchaRef} />
        <input
          type="text"
          id="user_captcha_input"
          value={userCaptchaValue}
          onChange={handleCaptchaChange}
          style={{ width: "98%", height: "2rem", fontSize: "1rem" }}
        />
        <LoadCanvasTemplate />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            userCaptchaValue && formData?.email && formData?.password
              ? false
              : true
          }
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Login
        </Button>
      </form>
    </Container>
  );
};

export default Login;
