// TaskUpload.js
import { useEffect } from "react";
import useAxiosPrivate from "../../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import {setshowCountryList} from "../../slice/CountryListSlice"
const useCountryList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getCountryList = async (param) => {
    try {
        const { data } = await Axios.post('getCountryList');
        dispatch(setshowCountryList(data));
        console.log(data, "countryList");
        return data;
      } catch (error) {
        console.log(error.message);
        return error?.response?.data;
      }
  };
  useEffect(() => {
    // Call the refresh function when the component mounts
    getCountryList();
  }, []);
  return getCountryList;
  
};

export default useCountryList;