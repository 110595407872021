import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useMoveNext = (
  checked,
  remarks,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl,
  selectedSegment,
  fillForm
) => {
  const selectedCardTypes = Object.keys(checked).filter((key) => checked[key]);

  const allL2S1List = useSelector((state) => state.qa.l2S1List);
  const allL2S2List = useSelector((state) => state.qa.l2S2List);
  const allfDWHList = useSelector((state) => state.qa.fDWHList);
  const allfNWList = useSelector((state) => state.qa.fNWList);
  const currentMoveToId = useSelector((state) => state.qa.moveToId);
  const remarkSelectedStatus = useSelector(
    (state) => state.qa.currentRemarkStatus
  );
  const Axios = useAxiosPrivate();
  const moveNext = async (type) => {
    let available_cards = [];
    if (Array.isArray(selectedCardTypes)) {
      // If selectedCardTypes is already an array, assign it to available_cards
      available_cards = selectedCardTypes;
    } else if (selectedCardTypes !== undefined && selectedCardTypes !== null) {
      // If selectedCardTypes is not an array, convert it to an array
      available_cards = [selectedCardTypes];
    }

    let param = {
      available_cards,
      f_cards_available: available_cards,
      url_id:
        type === "l2S1"
          ? allL2S1List?.[0]?.url_id
          : type === "fDWH"
          ? allfDWHList?.[0]?.url_id
          : type === "fDEX"
          ? allfNWList?.[0]?.url_id
          : allL2S2List?.[0]?.url_id,
      status_id:
        type === "l2S1"
          ? remarkSelectedStatus === true
            ? currentMoveToId
            : 3
          : type === "fDWH"
          ? remarkSelectedStatus === true
            ? currentMoveToId
            : 7
          : type === "fDEX"
          ? remarkSelectedStatus === true
            ? currentMoveToId
            : 19
          : remarkSelectedStatus === true
          ? currentMoveToId
          : 4,
      remarks: remarks,
      task_id:
        type === "l2S1"
          ? allL2S1List?.[0]?.task_id
          : type === "fDWH"
          ? allfDWHList?.[0]?.task_id
          : type === "fDEX"
          ? allfNWList?.[0]?.task_id
          : allL2S2List?.[0]?.task_id,
      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
      segment: selectedSegment,
      without_fill_form: fillForm
    };

    const response = await Axios.post("/move-to-next", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Moved Successfully", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data.message, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return moveNext;
};

export default useMoveNext;
