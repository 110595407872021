import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCount, getRELList } from "../slice/QASlice";

const useRelList = () => {
  const current_task_id = localStorage.getItem("task_id");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const allRELList = useSelector((state) => state.qa.relList?.relDetailsList);
  const getAllRelList = async (segment) => {
    let param = {
      task_id: current_task_id,
      url_id: allRELList?.length == 0 ? null : allRELList?.[0]?.url_id,
      segment: segment ? segment : null,
    };
    const response = await Axios.post("/get-rel-list", param);
    const data = await response.data;
    dispatch(getRELList(data));
    // dispatch(getCurrentCount(data?.filterCount));
  };

  return getAllRelList;
};

export default useRelList;
