import { useEffect } from "react";
import {
  getTaskDetailsList,
  getURLDetails,
  getURLDetailsStatus,
} from "../slice/taskSlice";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
const useURLDetailsStatus = (selectedCardData) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id=localStorage.getItem('task_id')
  const getAllURLDetailsStatus = async () => {
    let param = {
      status_id: selectedCardData?.status_id,
      task_id: current_task_id
    };
    const response = await Axios.post("/url-details-status", param);
    const data = await response.data;
    dispatch(getURLDetailsStatus(data.URLDetailsByStatus));
  };

  useEffect(() => {
    getAllURLDetailsStatus();
  }, [selectedCardData?.status_id,current_task_id]);
};

export default useURLDetailsStatus;
