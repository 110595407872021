import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getfDWHList } from "../slice/QASlice";

const useFDWHList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const allfDWHList = useSelector((state) => state.qa.fDWHList);
  const getAllFDWHList = async () => {
    let param = {
      task_id: current_task_id,
      url_id: allfDWHList?.length === 0 ? null : allfDWHList?.[0]?.url_id,
    };
    const response = await Axios.post("/get-fDWH-list", param);
    const data = await response.data;
    dispatch(getfDWHList(data.fDWHDetailsList));
  };
  useEffect(() => {
    getAllFDWHList();
  }, [current_task_id]);
  return getAllFDWHList;
};

export default useFDWHList;
