import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useTaskCheetSheetDetails from "../../hooks/useTaskCheetSheetDetails";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

function GenerateCheetSheet() {
  //Redux
  const data = useSelector((state) => state.task.taskCheetSheetDetails);
  //Api Calls
  const getAllTaskCheetSheetDetails = useTaskCheetSheetDetails();

  const formattedData = data?.map((item) => ({
    ...item,
    mobile: item?.mobile?.toString(),
    pincode: item?.pincode?.toString(),
  }));

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "output.xlsx");
  };

  return (
    <div>
      <Button
        sx={{ borderRadius: "0px", textTransform: "none" }}
        startIcon={<FileDownloadOutlinedIcon />}
        variant="outlined"
        onClick={exportToExcel}
      >
        Cheet Sheet
      </Button>
    </div>
  );
}

export default GenerateCheetSheet;
