import { toast } from "react-toastify";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
const useSplitUrl = () => {
  const Axios = useAxiosPrivate();
  const current_task_id = localStorage.getItem("task_id");
  const current_sno = localStorage.getItem("sno");
  const getSplitUrl = async (sno) => {
    console.log(sno,"getSplitUrl")
    let param={
      task_id: parseInt(current_task_id),
      sno: current_sno
    }
    const response = await Axios.post("/split-url",param);
    const data = await response.data;
    if(data.code===200){
      toast.success(data.message)
    }else{
      toast.error(data.message)
    }
  };

  return getSplitUrl
};

export default useSplitUrl;
