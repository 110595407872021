import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getComList,
  getCurrentCount,
  getFilterRemarksList,
  getRELList,
  getRemarksList,
} from "../slice/QASlice";
import { useEffect } from "react";

const useFilterRemarksList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_status_id = useSelector((state) => state.qa.selectedCardData);
  // const roleId = useSelector((state) => state.auth.userData.role_id);

  const getAllFilterRemarksList = async () => {
    let param = {
      status_id: 26,
    };

    const response = await Axios.post("/filter-remarks-list", param);

    const data = await response.data;

    dispatch(getFilterRemarksList(data?.remarksList));
  };

  useEffect(() => {
    getAllFilterRemarksList();
  }, []);

  return getAllFilterRemarksList;
};

export default useFilterRemarksList;
