import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  FormControl,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import amex from "../../../assests/amex.png";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Modal, Backdrop, Fade, styled } from "@mui/material";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useSelector, useDispatch } from "react-redux";
import { showQaData } from "../../../slice/QASlice";
import useQAList from "../../../hooks/useQAList";
import useLevel1QAList from "../../../hooks/useQALevel1List";
import ClearIcon from "@mui/icons-material/Clear";
import useQAApprove from "../../../hooks/useQAApprove";
import useQAAccessStatusList from "../../../hooks/useQAAccessStatusList";
import useQANotAssignedUpdate from "../../../hooks/useQANotAssignedUpdate";
import useQAStatusUpdate from "../../../hooks/useQAStatusUpdate";
import useQAAssignedUpdate from "../../../hooks/useQAAssignedUpdate";
import useUrlLastStatusId from "../../../hooks/useUrlLastStatusId";
import useQAReject from "../../../hooks/useQAReject";
import useTotalQAStatusCount from "../../../hooks/useTotalQAStatusCount";
import QACorousel1 from "./QACorousel";
import useTotalLevel1QAStatusCount from "../../../hooks/useTotalLevel1QAStatusCount";
import useLevel1QAStatusUpdate from "../../../hooks/useLevel1QAStatusUpdate";
import LaunchIcon from "@mui/icons-material/Launch";
const QAForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const showData = useSelector(showQaData);
  const qaList = useSelector((state) => state.qa.level1qaList);
  const [remarks, setRemarks] = useState("");
  const [checked, setChecked] = useState({
    amex: false,
    mastercard: false,
    visa: false,
    paypal: false,
    wallet: false,
    discover: false,
  });

  useEffect(() => {
    setChecked({
      amex: qaList?.[0]?.ai_cards_available?.includes("amex"),
      mastercard: qaList?.[0]?.ai_cards_available?.includes("mastercard"),
      visa: qaList?.[0]?.ai_cards_available?.includes("visa"),
      paypal: qaList?.[0]?.ai_cards_available?.includes("paypal"),
      wallet: qaList?.[0]?.ai_cards_available?.includes("wallet"),
      discover: qaList?.[0]?.ai_cards_available?.includes("discover"),
    });
  }, [qaList]);
  const selectedCardData = useSelector((state) => state.qa.selectedCardData);
  const getAllLevel1QAList = useLevel1QAList();
  const { qaApprove, isLoading, setIsLoading } = useQAApprove(
    checked,
    remarks
  );
  const getAllQAList = useQAList(selectedCardData);
  const getAllQAAccessStatusList = useQAAccessStatusList();
  const qaLevel1StatusUpdate = useLevel1QAStatusUpdate();
  const qaNotAssignedUpdate = useQANotAssignedUpdate();
  const qaStatusUpdate = useQAStatusUpdate();
  const qaAssignedUpdate = useQAAssignedUpdate();
  const getUrlLastStatusId = useUrlLastStatusId();
  const { qaReject, isRejectLoading, setIsRejectLoading } = useQAReject(
    checked,
    remarks
  );
  const getAllTotalLevel1QAStatusCount = useTotalLevel1QAStatusCount();

  useEffect(() => {
    getAllLevel1QAList();
  }, []);
  useEffect(() => {
    console.log(JSON.stringify(showData.showQaData), "hi");
  }, [showData]);

  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleDiscard = () => {
    setIsEdit(false);
  };
  const [open, setOpen] = useState(false);

  const handleURLClick = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    const newWindow=window.open(url, "_blank");
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Handle blocked pop-ups or failed window opening
      alert("Unable to open the URL in a new tab. Please disable your pop-up blocker or check the URL.");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ModalContent = styled("div")({
    backgroundColor: "gray", // Set the background color to white or any other bright color
    boxShadow: 24,
    padding: 3,
  });

  function getStatusIcon(value) {
    return value ? (
      <CheckIcon style={{ color: "#0EA101" }} />
    ) : (
      <ClearIcon style={{ color: "#FF0000" }} />
    );
  }

  const handleApprove = async () => {
    try {
      setIsLoading(true);
      setRemarks("");
      // // Introduce a delay (e.g., 1000 milliseconds = 1 second)
      // await new Promise((resolve) => setTimeout(resolve, 5000));

      // Change the assignment status
      await qaNotAssignedUpdate();

      // Update the QA status
      await qaLevel1StatusUpdate();

      // Update the QA approval status
      await qaApprove(1);

      // Get the updated QA list
      await getAllLevel1QAList();
      await getAllTotalLevel1QAStatusCount();
      await getAllQAAccessStatusList();

      // Assign the QA again
      // await qaAssignedUpdate();
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleReject = async () => {
    setIsRejectLoading(true);
    setRemarks("");
    await getUrlLastStatusId();

    await new Promise((resolve) => setTimeout(resolve, 500));
    await qaNotAssignedUpdate();
    // Update the QA status
    await qaLevel1StatusUpdate();
    // Update the QA approval status
    await qaReject(1);

    // Get the updated QA list
    await getAllLevel1QAList();
    await getAllTotalLevel1QAStatusCount();
    await getAllQAAccessStatusList();

    // Assign the QA again
    // await qaAssignedUpdate();
  };

  const handleUpdate = async () => {
    try {
      // // Introduce a delay (e.g., 1000 milliseconds = 1 second)
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      // Change the assignment status
      await qaNotAssignedUpdate();
      // Update the QA status
      await qaLevel1StatusUpdate();
      // Update the QA approval status
      await qaApprove();

      // Get the updated QA list
      await getAllQAList();
      await getAllQAAccessStatusList();

      // Assign the QA again
      // await qaAssignedUpdate();

      setIsEdit(false);
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleCheckbox = (e, value) => {
    const newValue = e.target.checked;
    setChecked((obj) => ({
      ...obj,
      [e.target.name]: newValue,
    }));
  };

  console.log(checked);

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  return isLoading === true || isRejectLoading === true ? (
    <div>
      <Box
        sx={{ display: "flex", position: "absolute", top: "50%", left: "50%" }}
      >
        <CircularProgress />
      </Box>
    </div>
  ) : (
    <Container
      maxWidth="2xl"
      sx={{
        paddingTop: { xs: "2rem", sm: "1.2rem" },
        paddingBottom: "1.2rem",
        backgroundColor: "#F8F8F8",
      }}
    >
      {showData.showQaData ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "20px", sm: "20px" },
            // height:"100%"
          }}
        >
          {qaList?.map((qaData) => (
            <>
              <Stack sx={{ flex: "3", position: "relative" }}>
                {/* <Stack sx={{ position: "relative" }}> */}
                {/* <img src={qaData?.screenshot_url} alt="amex logo" style={{ height: "100%" }} /> */}
                <QACorousel1 qaData={qaData?.img_url} />
                <ZoomOutMapIcon
                  sx={{
                    position: "absolute",
                    right: "15px",
                    top: "16px",
                    fontSize: "2rem",
                    cursor: "pointer",
                    color: "gray",
                    zIndex: "100",
                  }}
                  onClick={handleOpen}
                />
                {/* </Stack> */}

                <Modal
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                    sx: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                  }}
                >
                  <Fade in={open}>
                    <ModalContent>
                      <img
                        src={qaData?.img_url}
                        alt="Full Screen Image"
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      />
                      <CloseIcon
                        sx={{
                          position: "absolute",
                          top: "0.6rem",
                          right: "0.6rem",
                          fontSize: "2.5rem",
                          cursor: "pointer",
                          color: "gray",
                        }}
                        onClick={handleClose}
                      />
                    </ModalContent>
                  </Fade>
                </Modal>
              </Stack>

              <Stack sx={{ alignItems: "center", height: "90%" }}>
                <Card
                  sx={{ flex: "1", width: { xs: "max-content", sm: "100%" } }}
                >
                  <CardContent
                    key={qaData?.url_id}
                    sx={{
                      lineHeight: "1.2rem",
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                        md: "column",
                      },
                      justifyContent: { xs: "", sm: "space-between" },
                    }}
                  >
                    <Stack sx={{ gap: "20px" }}>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontSize: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#989898",
                            fontWeight: "700",
                          }}
                        >
                          URL ID:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          {qaData?.url_id ?? "N/A"}
                        </Typography>
                        <Typography
                            sx={{
                              color: "#989898",
                              fontWeight: "700",
                            }}
                          >
                            S.No :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "400",
                            }}
                          >
                            {qaData?.sno ?? "N/A"}
                          </Typography>
                      </Stack>
                      <Stack sx={{ fontSize: "0.9rem" }}>
                        <Typography
                          sx={{ color: "#989898", fontWeight: "700" }}
                        >
                          URL
                        </Typography>
                        {/* <Typography
                          sx={{ fontWeight: "400", color: "#006FCF" }}
                        >
                          {qaData?.url ?? "N/A"}
                        </Typography> */}

                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#006FCF",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.2rem",
                          }}
                        >
                          {qaData?.url ?? "N/A"}
                          <LaunchIcon
                            onClick={() => handleURLClick(qaData?.url)}
                            sx={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </Stack>
                      <Stack sx={{ fontSize: "0.9rem" }}>
                        <Typography
                          sx={{ color: "#989898", fontWeight: "700" }}
                        >
                          Alternate URL
                        </Typography>
                        {/* <Typography
                          sx={{ fontWeight: "400", color: "#006FCF" }}
                        >
                          {qaData?.alternate_url ?? "N/A"}
                        </Typography> */}

                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#006FCF",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.2rem",
                          }}
                        >
                          {qaData?.alternate_url ?? "N/A"}
                          <LaunchIcon
                            onClick={() =>
                              handleURLClick(qaData?.alternate_url)
                            }
                            sx={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </Stack>

                      <Stack sx={{ fontSize: "0.9rem", gap: "2px" }}>
                        <Typography
                          sx={{ color: "#989898", fontWeight: "700" }}
                        >
                          Executioner's Remark
                        </Typography>
                        <Typography
                          sx={{ color: "#000000", fontWeight: "400" }}
                        >
                          {qaData?.remarks ?? "N/A"}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider
                      sx={{ backgroundColor: "#BBBBBB", marginY: "1rem" }}
                    />
                    <FormControl sx={{ display: "flex", gap: "16px" }}>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.8rem",
                        }}
                      >
                        <Stack
                          sx={{
                            gap: "10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Stack
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontSize: "0.9rem",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "700", color: "#989898" }}
                            >
                              AI_POPs Found
                            </Typography>
                            {!isEdit && (
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {/* <BorderColorIcon
                                    sx={{
                                      color: "#006FCF",
                                      fontSize: "1rem",
                                      marginRight: "-0.7rem",
                                    }}
                                  />

                                  <Button
                                    sx={{
                                      color: "#006FCF",
                                      fontWeight: "700",
                                      textTransform: "none",
                                    }}
                                    onClick={handleEdit}
                                  >
                                    Edit
                                  </Button> */}
                              </Stack>
                            )}
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {getStatusIcon(
                                qaData?.ai_cards_available?.includes("amex")
                              )}
                            </Stack>

                            <Typography sx={{ color: "#000000" }}>
                              Amex
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {getStatusIcon(
                                qaData?.ai_cards_available?.includes(
                                  "mastercard"
                                )
                              )}
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              MasterCard
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {getStatusIcon(
                                qaData?.ai_cards_available?.includes("visa")
                              )}
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Visa
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {getStatusIcon(
                                qaData?.ai_cards_available?.includes("discover")
                              )}
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Discover
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {getStatusIcon(
                                qaData?.ai_cards_available?.includes("paypal")
                              )}
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Paypal
                            </Typography>
                          </Stack>
                          
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {getStatusIcon(
                                qaData?.ai_cards_available?.includes("wallet")
                              )}
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Wallets
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            gap: "10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Stack
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontSize: "0.9rem",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "700", color: "#989898" }}
                            >
                              F_POPs Found
                            </Typography>
                            {!isEdit && (
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {/* <BorderColorIcon
                                    sx={{
                                      color: "#006FCF",
                                      fontSize: "1rem",
                                      marginRight: "-0.7rem",
                                    }}
                                  /> */}

                                {/* <Button
                                    sx={{
                                      color: "#006FCF",
                                      fontWeight: "700",
                                      textTransform: "none",
                                    }}
                                    onClick={handleEdit}
                                  >
                                    Edit
                                  </Button> */}
                              </Stack>
                            )}
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  defaultChecked={qaData?.ai_cards_available?.includes(
                                    "amex"
                                  )}
                                  onChange={(e) => handleCheckbox(e)}
                                  name="amex"
                                />
                              }
                            </Stack>

                            <Typography sx={{ color: "#000000" }}>
                              Amex
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  defaultChecked={qaData?.ai_cards_available?.includes(
                                    "mastercard"
                                  )}
                                  onChange={(e) => handleCheckbox(e)}
                                  name="mastercard"
                                />
                              }
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              MasterCard
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  defaultChecked={qaData?.ai_cards_available?.includes(
                                    "visa"
                                  )}
                                  onChange={(e) => handleCheckbox(e)}
                                  name="visa"
                                />
                              }
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Visa
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  defaultChecked={qaData?.ai_cards_available?.includes(
                                    "discover"
                                  )}
                                  onChange={(e) => handleCheckbox(e)}
                                  name="discover"
                                />
                              }
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Discover
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  defaultChecked={qaData?.ai_cards_available?.includes(
                                    "paypal"
                                  )}
                                  onChange={(e) => handleCheckbox(e)}
                                  name="paypal"
                                />
                              }
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Paypal
                            </Typography>
                          </Stack>
                          
                          {/* <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Stack sx={{ width: "2.2rem" }}>
                              {
                                <Checkbox
                                  defaultChecked={qaData?.ai_cards_available?.includes(
                                    "wallet"
                                  )}
                                  onChange={(e) => handleCheckbox(e)}
                                  name="wallet"
                                />
                              }
                            </Stack>
                            <Typography sx={{ color: "#000000" }}>
                              Wallets
                            </Typography>
                          </Stack> */}
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        sx={{
                          alignItems: "center",
                          background: "#FFF",
                        }}
                      >
                        <TextField
                          value={remarks}
                          onChange={handleRemarksChange}
                          multiline
                          placeholder="Add a Remark"
                          sx={{
                            width: "18rem",
                            borderRadius: "0",
                            ".MuiInputBase-root": {
                              borderRadius: "0",
                            },
                          }}
                        ></TextField>
                      </Stack>
                      {!isEdit ? (
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "0.6rem",
                          }}
                        >
                          <Stack
                            sx={{
                              background: "#EA001B",
                              width: "8.5rem",
                            }}
                          >
                            <Button
                              sx={{ color: "#FFFFFF", textTransform: "none",background: "#9e3434", }}
                              onClick={handleReject}
                            >
                              Reject
                            </Button>
                          </Stack>
                          <Stack
                            sx={{
                              background: "#006FCF",
                              width: "8.5rem",
                              textTransform: "none",
                            }}
                          >
                            <Button
                              sx={{ color: "#FFFFFF", textTransform: "none" }}
                              onClick={handleApprove}
                            >
                              Approve
                            </Button>
                          </Stack>
                        </Stack>
                      ) : (
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "0.6rem",
                          }}
                        >
                          <Stack
                            sx={{
                              background: "#FFFFFF",
                              width: "8.5rem",
                              border: "0.02rem solid #006FCF",
                              textTransform: "none",
                            }}
                          >
                            <Button
                              sx={{ color: "#006FCF", textTransform: "none" }}
                              onClick={handleDiscard}
                            >
                              Discard
                            </Button>
                          </Stack>
                          <Stack
                            sx={{
                              background: "#006FCF",
                              width: "8.5rem",
                              textTransform: "none",
                            }}
                          >
                            <Button
                              sx={{ color: "#FFFFFF", textTransform: "none" }}
                              onClick={handleUpdate}
                            >
                              Update
                            </Button>
                          </Stack>
                        </Stack>
                      )}
                    </FormControl>
                  </CardContent>
                </Card>
              </Stack>
            </>
          ))}
        </Box>
      ) : (
        <Stack
          sx={{
            width: "100%",
            height: "70vh",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            textAlign: "center",
            color: "#DADADA",
            fontSize: "40px",
          }}
        >
          Click on “Start” to begin
        </Stack>
      )}
    </Container>
  );
};

export default QAForm;
