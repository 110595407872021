import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getLevel1QAList, getQAList } from "../slice/QASlice";

const useLevel1QAList = () => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  
  const getAllLevel1QAList = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/qa-level1-status-list", param);
    const data = await response.data;
    dispatch(getLevel1QAList(data.QALevel1StatusList));
  };
  return getAllLevel1QAList;
};

export default useLevel1QAList;
