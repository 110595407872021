import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slice/LoginSlice";
import qaReducer from "../slice/QASlice";
import countryReducer from "../slice/CountryListSlice";
import statusReducer from "../slice/statusSlice";
import taskReducer from "../slice/taskSlice";
const rootReducer = combineReducers({
  qa: qaReducer,
  auth: authReducer,
  country: countryReducer,
  status: statusReducer,
  task: taskReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
