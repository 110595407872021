import { Authaxios } from '../../axios/axios';


export const UserLogin = async(param)=>{
    try {
        const {data} = await Authaxios.post('adminLogin',param);
        return data
   } catch (error) {
       console.log(error.message);
       return error?.response?.data
   }
}
