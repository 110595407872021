import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getStatusList } from "../slice/statusSlice";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useReleaseTaskStatus = () => {
  const location = useLocation();
  const role_id = useSelector((state) => state.auth.userData.role_id);
  console.log(role_id);
  let rowData = location?.state?.rowData;
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getReleaseTaskStatus = async (taskId) => {
    let param = {
      task_id: taskId,
    };
    const response = await Axios.post("/release-task-status", param);
    const data = await response.data;
    console.log(data,"111119999")
    if (data.code === 200) {
      toast.success(data?.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data?.message, {
        position: "bottom-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // useEffect(() => {
  //   getReleaseTaskStatus();
  // }, []);

  return getReleaseTaskStatus;
};

export default useReleaseTaskStatus;
