import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAccessStatusList } from "../slice/statusSlice";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTotalLevel1QAAltStatusCount, getTotalLevel1QAStatusCount, getTotalQAStatusCount } from "../slice/QASlice";

const useTotalLevel1QAAltStatusCount = () => {
  const Axios = useAxiosPrivate();
  const location = useLocation();
  const role_id = useSelector((state) => state.auth.userData.role_id);
  
  let rowData = location?.state?.rowData;
  console.log(rowData)
  const current_task_id = localStorage.getItem("task_id");
  const dispatch = useDispatch();

  const getAllTotalLevel1QAAltStatusCount = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/total-level1-qa-alt-status-count",param);
    const data = await response.data;
    dispatch(getTotalLevel1QAAltStatusCount(data.totalQAAltStatusCount));
  };

  // useEffect(() => {
  //   getAllTotalLevel1QAAltStatusCount();
  // }, []);
  return getAllTotalLevel1QAAltStatusCount;
};

export default useTotalLevel1QAAltStatusCount;
