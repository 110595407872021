import React, { useEffect, useState } from "react";
import QAForm from "./QAL2Form";
// import Navbar from "../../Navbar/Navbar";
import QAL2Header from "./QAL2Header";

const QAL2S1TempPage = () => {
  const [timeLeft, setTimeLeft] = useState(120);
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const updateTime = (newTime) => {
    setTimeLeft(newTime);
  };
  return (
    <>
      <QAL2Header timeLeft={timeLeft} />
      <QAForm updateTime={updateTime} timeLeft={timeLeft} />
    </>
  );
};

export default QAL2S1TempPage;
