import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useQAImageUpdate = (imageUrl) => {

  console.log(imageUrl,"updatedQAImage1111")
  //Redux
  const allQAList = useSelector((state) => state.qa.qaList);
  const allCOMList = useSelector((state) => state.qa.comList);
  const allL2S1List = useSelector((state) => state.qa.l2S1List);
  const allL2S2List = useSelector((state) => state.qa.l2S2List);
  const allRelList = useSelector((state) => state.qa.relList);
  console.log(allQAList[0]?.url_id, "qwertrty00");
  const Axios = useAxiosPrivate();
  const qaImageUpdate = async (type) => {
    let param = {
      url_id: type === 2
      ? allQAList?.[0]?.url_id
      : type === 4
      ? allRelList?.[0]?.url_id
      : type === 5
      ? allCOMList?.[0]?.url_id
      : type === 6
      ? allL2S1List?.[0]?.url_id
      : type === 7
      ? allL2S2List?.[0]?.url_id
      : null,
      img_url: imageUrl,
      level: 2,
    };
    const response = await Axios.post("/url-image-update", param);
    const data = await response.data;
    // if (data.code === 200) {
    //   toast.success(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    //   toast.error(data.message, {
    //     position: "bottom-right",
    //     autoClose: 3000, // Close the toast after 3 seconds
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  return qaImageUpdate;
};

export default useQAImageUpdate;
