import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getComList, getCurrentCount, getFilterCount, getRELList } from "../slice/QASlice";
import { useEffect } from "react";

const useComList = (selectedCardData) => {
  const current_task_id = localStorage.getItem("task_id");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const allCOMList = useSelector((state) => state.qa.comList);
  const getAllComList = async (segment) => {
    let param = {
      task_id: current_task_id,
      url_id: allCOMList?.[0]?.url_id ?? null,
      segment: segment ? segment : null,
    };

    const response = await Axios.post("/get-com-list", param);

    const data = await response.data;

    dispatch(getComList(data?.comDetailsList));
    dispatch(getFilterCount(data?.filterCount))
  };

  // useEffect(() => {
  //   getAllComList();
  // }, [selectedCardData]);

  return getAllComList;
};

export default useComList;
