import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getFilterCount, getQAList } from "../slice/QASlice";

const useQAList = (selectedCardData) => {
  console.log(selectedCardData, "qwertrty00");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const allQAList = useSelector((state) => state.qa.qaList);
  // const segment = useSelector((state) => state.qa.filterSelectedRemark);
  const getAllQAList = async (segment) => {
    let param = {
      status_id: selectedCardData,
      task_id: current_task_id,
      url_id: allQAList.length === 0 ? null : allQAList[0]?.url_id,
      segment: segment ? segment : null,
    };
    const response = await Axios.post("/qa-status-list", param);
    const data = await response.data;
    dispatch(getQAList(data.QAStatusList));
    dispatch(getFilterCount(data?.filterCount))
  };
  // useEffect(() => {
  //   getAllQAList();
  // }, [segment]);
  return getAllQAList;
};

export default useQAList;
