import React from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getLevel1QAAltList, getLevel1QAList, getQAList } from "../slice/QASlice";

const useLevel1QAAltList = () => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  
  const getAllLevel1QAAltList = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/qa-level1-alt-status-list", param);
    const data = await response.data;
    dispatch(getLevel1QAAltList(data.QALevel1AltStatusList));
  };
  return getAllLevel1QAAltList;
};

export default useLevel1QAAltList;
