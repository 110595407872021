import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getQAList, getRELCount, getRELList } from "../slice/QASlice";

const useRELCount = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const getAllRELCount = async () => {
    let param = {
      task_id: current_task_id,
    };
    const response = await Axios.post("/get-rel-count", param);
    const data = await response.data;
    dispatch(getRELCount(data?.totalRel));
  };

  useEffect(()=>{
    getAllRELCount()
  },[current_task_id])
  // return getAllRELCount;
};

export default useRELCount;
