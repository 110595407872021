import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const data = [
  [
    "Index",
    "URL",
    "crm_nm",
    "crm_ad_line_1_tx",
    "crm_ad_post_town_nm",
    "crm_ad_rgn_area_nm",
    "crm_ad_pstl_cd",
  ],
  [
    1,
    "www.example.com",
    "example_name",
    "example_address",
    "example_town",
    "example_area",
    "example_postal_code",
  ],
];
const GenerateTemplate = () => {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  saveAs(blob, "data.xlsx");
};

export default GenerateTemplate;
