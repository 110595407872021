import { createSlice } from "@reduxjs/toolkit";

const QAslice = createSlice({
  name: "qa",
  initialState: {
    showQaData: false,
    qaList: [],
    urlLastStatusId: null,
    selectedCardData: null,
    selectedCardCount: null,
    totalQAStatusCount: null,
    level1qaList: [],
    totalLevel1QAStatusCount: null,
    selectedQAAltCardData: null,
    level1qaaltList: [],
    totalLevel1QAAltStatusCount: null,
    qaAltAccessStatusList: null,
    relList: null,
    relCount: null,
    currentCount: 0,
    qaFormData: null,
    comList: null,
    relDetails: null,
    remarksList: null,
    timer: 120,
    l2S1List: null,
    l2S2List: null,
    refreshImage: null,
    l2S1ListTemp: null,
    fNWList: null,
    fDWHList: null,
    moveToId: null,
    searchList: null,
    currentRemarkStatus: false,
    searchUrlId: null,
    duplicateList: null,
    duplicateDetails: null,
    currentSelecteddId: null,
    filterRemarksList: null,
    filterSelectedRemark: null,
    filterCount: null,
  },
  reducers: {
    setshowQaData: (state, action) => {
      state.showQaData = action.payload;
    },
    getQAList: (state, action) => {
      state.qaList = action.payload;
    },
    getDuplicateList: (state, action) => {
      state.duplicateList = action.payload;
    },
    getDuplicateDetails: (state, action) => {
      state.duplicateDetails = action.payload;
    },
    getRefreshImage: (state, action) => {
      state.refreshImage = action.payload;
    },
    getRELList: (state, action) => {
      state.relList = action.payload;
    },
    getSearchList: (state, action) => {
      state.searchList = action.payload;
    },
    getrelList: (state, action) => {
      state.relDetails = action.payload;
    },
    getComList: (state, action) => {
      state.comList = action.payload;
    },
    getRemarksList: (state, action) => {
      state.remarksList = action.payload;
    },
    getFilterRemarksList: (state, action) => {
      state.filterRemarksList = action.payload;
    },
    getFilterSelectedRemark: (state, action) => {
      state.filterSelectedRemark = action.payload;
    },
    getFilterCount: (state, action) => {
      state.filterCount = action.payload;
    },
    getRELCount: (state, action) => {
      state.relCount = action.payload;
    },
    getLevel1QAList: (state, action) => {
      state.level1qaList = action.payload;
    },
    getLevel1QAAltList: (state, action) => {
      state.level1qaaltList = action.payload;
    },
    getTotalQAStatusCount: (state, action) => {
      state.totalQAStatusCount = action.payload;
    },
    getTotalLevel1QAStatusCount: (state, action) => {
      state.totalLevel1QAStatusCount = action.payload;
    },
    getTotalLevel1QAAltStatusCount: (state, action) => {
      state.totalLevel1QAAltStatusCount = action.payload;
    },
    getQAUrlLastStatusId: (state, action) => {
      state.urlLastStatusId = action.payload;
    },
    getQAAltAccessStatusList: (state, action) => {
      state.qaAltAccessStatusList = action.payload;
    },
    getSelectedCardData: (state, action) => {
      state.selectedCardData = action.payload;
    },
    getSelectedCardCount: (state, action) => {
      state.selectedCardCount = action.payload;
    },
    getSelectedQAAltCardData: (state, action) => {
      state.selectedQAAltCardData = action.payload;
    },
    getCurrentCount: (state, action) => {
      state.currentCount = action.payload;
    },
    getQAFormData: (state, action) => {
      state.qaFormData = action.payload;
    },
    getQATimer: (state, action) => {
      state.timer = action.payload;
    },
    getSearchUrlId: (state, action) => {
      state.searchUrlId = action.payload;
    },
    getL2S1List: (state, action) => {
      state.l2S1List = action.payload;
    },
    getL2S1ListTemp: (state, action) => {
      state.l2S1ListTemp = action.payload;
    },
    getL2S2List: (state, action) => {
      state.l2S2List = action.payload;
    },
    getfNWList: (state, action) => {
      state.fNWList = action.payload;
    },
    getfDWHList: (state, action) => {
      state.fDWHList = action.payload;
    },
    getSelectedMoveToId: (state, action) => {
      state.moveToId = action.payload;
    },
    getSelectedRemarkStatus: (state, action) => {
      state.currentRemarkStatus = action.payload;
    },
    getCurrentSelecteddId: (state, action) => {
      state.currentSelecteddId = action.payload;
    },
  },
});

export const {
  setshowQaData,
  getQAList,
  getQAUrlLastStatusId,
  getSelectedCardData,
  getSelectedCardCount,
  getTotalQAStatusCount,
  getLevel1QAList,
  getTotalLevel1QAStatusCount,
  getSelectedQAAltCardData,
  getLevel1QAAltList,
  getTotalLevel1QAAltStatusCount,
  getQAAltAccessStatusList,
  getRELList,
  getRELCount,
  getCurrentCount,
  getQAFormData,
  getComList,
  getrelList,
  getRemarksList,
  getQATimer,
  getL2S1List,
  getL2S2List,
  getRefreshImage,
  getL2S1ListTemp,
  getfNWList,
  getfDWHList,
  getSelectedMoveToId,
  getSearchList,
  getSelectedRemarkStatus,
  getSearchUrlId,
  getDuplicateList,
  getDuplicateDetails,
  getCurrentSelecteddId,
  getFilterRemarksList,
  getFilterSelectedRemark,
  getFilterCount,
} = QAslice.actions;
export const showQaData = (state) => state.qa;

export default QAslice.reducer;
