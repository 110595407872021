import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getExecutorDashboardReport } from "../slice/taskSlice";
import Swal from "sweetalert2";

const useExecutorDashboardReport = (setLoader) => {
 
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  
  const getAllExecutorDashboardReport = async (from, to) => {
    let param = { from: from, to: to };

    try {
      setLoader(true)
      const response = await Axios.post("/executor_dashboard_report", param);
      const data = await response.data;
      if(data.code===200){
        setLoader(false)
        dispatch(getExecutorDashboardReport(data?.users));
        return data?.users ;
      }
    } catch (error) {
      console.log(error.response.data.code, "error.response.data.code")
      if(error.response.data.code===400){
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: true
        });
      }
    }
  };

  // useEffect(() => {
  //   getAllExecutorDashboardReport();
  // }, []);

  return getAllExecutorDashboardReport;
};

export default useExecutorDashboardReport;
