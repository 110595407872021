import React, { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getL2S1List, getL2S2List, getQAList } from "../slice/QASlice";

const useL2S2List = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const current_task_id = localStorage.getItem("task_id");
  const allL2S2List = useSelector((state) => state.qa.l2S2List);
  const getAllL2S2List = async () => {
    let param = {
      task_id: current_task_id,
      url_id: allL2S2List?.length === 0 ? null : allL2S2List?.[0]?.url_id,
    };
    const response = await Axios.post("/get-l2S2-list", param);
    const data = await response.data;
    dispatch(getL2S2List(data.l2S2DetailsList));
  };
  useEffect(() => {
    getAllL2S2List();
  }, []);
  return getAllL2S2List;
};

export default useL2S2List;
