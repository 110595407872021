import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useExecutorDashboardReport from "../../hooks/useExecutorDashboardReport";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const ExecutorAdminTable = ({rowData, executorReport}) => {
 
  const formatData = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });

    const formattedDate = `${day} ${month}`;
    return formattedDate;
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
        marginLeft: { xs: "16px", md: "32px" },
        marginRight: { xs: "16px", md: "32px" },
        paddingBottom: "32px",
      }}
    >
      <TableContainer sx={{ maxHeight: "600px", marginTop:"15px" }} component={Paper}>
        <Table>
          <TableHead
            sx={{
              color: "#9D9D9D",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  // backgroundColor: "#FFF",
                  backgroundColor:"#F0FAFB",
                  padding:"8px !important",
                  zIndex: 11,
                 
                }}
              >
                Date
              </TableCell>
              {rowData?.map((row, index) =>
                row === "Total" ? (
                  <TableCell
                    key={index}
                    sx={{
                      position: "sticky",
                      right: 0,
                      // backgroundColor: "#FFF",
                      padding:"8px !important",
                      backgroundColor: "#F1F5F7",
                      zIndex: 11,
                      
                    }}
                  >
                    {row}
                  </TableCell>
                ) : (
                  <TableCell key={index} sx={{backgroundColor:"#E6E9EB", padding:"8px !important"}}>{row}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {executorReport?.daily_data?.map((data, index) => (
              <TableRow key={index} >
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    // backgroundColor: "#FFF",
                    padding:"8px !important",
                    backgroundColor:"#F0FAFB",
                    zIndex: 11,
                  }}
                >
                  {formatData(data?.date)}
                </TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s1_s2}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s1_dwh}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s1_dex}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s1_qa}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s2_dwh}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s2_dex}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.s2_qa}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.dwh_dex}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.dwh_qa}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.dex_dwh}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.dex_qa}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.dup_qa}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.qa_com}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.com_rel}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.qa_qa}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.com_com}</TableCell>
                <TableCell sx={{padding:"8px !important"}}>{data?.rel_rel}</TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    right: 0,
                    // backgroundColor: "#FFF",
                    padding:"8px !important",
                    backgroundColor: "#F1F5F7",
                    zIndex: 11,
                  }}
                >
                  {data?.Total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExecutorAdminTable;












