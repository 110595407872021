import { useEffect } from "react";
import useAxiosPrivate from "../utility/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useRELStatusUpdate = (
  remarks,
  type,
  pageType,
  alternateUrl,
  ecommerce,
  pageUrl,
  checked,
  imageUrl,
  selectedSegment
) => {
  console.log(checked, "applejfjf");
  //Redux
  const allRELList = useSelector((state) => state.qa.relList?.relDetailsList);
  const adminRELList = useSelector((state) => state.qa.relDetails);
  const selectedCardTypes = Object.keys(checked).filter((key) => checked[key]);
  const Axios = useAxiosPrivate();
  const relStatusUpdate = async () => {
    let available_cards = [];
    if (Array.isArray(selectedCardTypes)) {
      // If selectedCardTypes is already an array, assign it to available_cards
      available_cards = selectedCardTypes;
    } else if (selectedCardTypes !== undefined && selectedCardTypes !== null) {
      // If selectedCardTypes is not an array, convert it to an array
      available_cards = [selectedCardTypes];
    }

    
    let param = {
      f_cards_available: type === 1 ? checked : available_cards,
      url_id: type === 1 ? allRELList?.[0]?.url_id : adminRELList?.[0]?.url_id,
      page_type: pageType,
      alternate_url: alternateUrl,
      ecommerce: ecommerce,
      page_url: pageUrl,
      remarks: remarks,
      img_url: imageUrl,
      level: 2,
      segment:selectedSegment
    };

    const response = await Axios.post("/url-rel-status-update", param);

    const data = await response.data;
    if (data.code === 200) {
      toast.success("Updated Successfully", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Not able to Update!", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return relStatusUpdate;
};

export default useRELStatusUpdate;
